/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React, { PropsWithChildren, useEffect, useState } from 'react';
import { IntlProvider, createIntl } from 'react-intl';
import Helmet from 'react-helmet';

import { useLocale, useHasUserFeature } from 'utils/hooks/globalState';
import { getSubdomain } from 'utils/browser';
import { TooLazyToType } from 'types/TooLazyToType';

import customMessagesOutcomesMeasures from '../../translations/custom-translations/en.outcomes-mesaures.json';

type I18NMessages = TooLazyToType;

const LanguageProvider = ({
  messages,
  children,
}: PropsWithChildren<{ messages: I18NMessages }>) => {
  const selectedLocale = useLocale();
  const defaultMessages = messages[selectedLocale];

  const [customMessages, setCustomMessages] = useState<I18NMessages>({});

  const hasCustomTerminology = useHasUserFeature('custom_terminology');

  // Load customer messages if the feature flag is enabled and the subdomain is in the list of custom terminology subdomains
  useEffect(() => {
    const customTerminologySubdomains = ['bayer-demo', 'bayer-ag', 'hooli'];
    const subdomain = getSubdomain();

    if (
      !hasCustomTerminology ||
      !customTerminologySubdomains.includes(subdomain)
    ) {
      return;
    }

    const loadMessages = () => {
      try {
        // Try to load customer-specific messages
        if (selectedLocale === 'en') {
          setCustomMessages(customMessagesOutcomesMeasures);
        } else {
          setCustomMessages({});
        }
      } catch (error) {
        setCustomMessages({});
      }
    };

    loadMessages();
  }, [selectedLocale, hasCustomTerminology]);

  const intlMessages = {
    ...defaultMessages,
    ...customMessages,
  };

  // This is exposed as a service in app/utils/intlService.js
  // @ts-ignore ts isn't happy about this global assign
  window.intlService = createIntl({
    locale: selectedLocale,
    messages: intlMessages,
  });

  return (
    <>
      <Helmet>
        <html lang={selectedLocale} />
      </Helmet>
      <IntlProvider
        locale={selectedLocale}
        key={selectedLocale}
        messages={intlMessages}
      >
        {React.Children.only(children)}
      </IntlProvider>
    </>
  );
};

export default LanguageProvider;
