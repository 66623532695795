import React from 'react';

import { Avatar } from '../../../Avatar';
import { Text } from '../../../Text';
import { MultiselectMenuItem } from '../../Multiselect/components/MultiselectMenuItem';
import { Entity } from '../types';
import { EntitySymbol } from '../../../EntitySymbol';

type Props = {
  item: Entity;
  idx: number;
  getItemProps;
};

const MenuItemRenderer = ({ item, getItemProps, idx }: Props) => {
  switch (item.entityType) {
    case 'default':
      return (
        // to prevent disabled items from being selected: https://github.com/downshift-js/downshift/issues/269#issuecomment-348142348
        <span {...(!item.disabled ? getItemProps({ item, index: idx }) : {})}>
          <MultiselectMenuItem label={item.title} disabled={item.disabled} />
        </span>
      );
    case 'group':
      return (
        <MultiselectMenuItem
          label={item.title}
          fontWeight="bold"
          clickable={false}
        />
      );
    case 'suffix':
      return (
        // to prevent disabled items from being selected: https://github.com/downshift-js/downshift/issues/269#issuecomment-348142348
        <span {...(!item.disabled ? getItemProps({ item, index: idx }) : {})}>
          <MultiselectMenuItem
            label={item.title}
            suffixWidth="fit-content"
            suffix={
              <Text display="inline" mr={1} color="grey-dark">
                {item.suffix}
              </Text>
            }
            disabled={item.disabled}
          />
        </span>
      );
    case 'person':
      return (
        <span {...(!item.disabled ? getItemProps({ item, index: idx }) : {})}>
          <MultiselectMenuItem
            avatar={
              <Avatar
                fallbackText={item.title}
                image={item.avatar.url}
                type={item.entityType}
                size="xs"
              />
            }
            label={item.title}
            disabled={item.disabled}
          />
        </span>
      );
    case 'currency':
      return (
        <span {...(!item.disabled ? getItemProps({ item, index: idx }) : {})}>
          <MultiselectMenuItem
            label={item.title}
            suffix={
              <Text display="inline" as="span" mr={1} color="grey-dark">
                {item.suffix}
              </Text>
            }
            disabled={item.disabled}
          />
        </span>
      );
    case 'team':
      return (
        <span {...(!item.disabled ? getItemProps({ item, index: idx }) : {})}>
          <MultiselectMenuItem
            avatar={
              <Avatar
                fallbackText={item.title}
                type={item.entityType}
                size="xs"
              />
            }
            label={item.title}
            disabled={item.disabled}
          />
        </span>
      );
    case 'businessUnit':
      return (
        <span {...(!item.disabled ? getItemProps({ item, index: idx }) : {})}>
          <MultiselectMenuItem
            avatar={<EntitySymbol variant="businessUnit" size="s" />}
            label={item.title}
            disabled={item.disabled}
          />
        </span>
      );
    default: {
      // this is to make a missing Renderer a type error. see
      // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#exhaustiveness-checking
      // for how this works.
      const _exhaustiveCheck: never = item;
      return _exhaustiveCheck;
    }
  }
};

export { MenuItemRenderer };
