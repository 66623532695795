/*
 * This is a HOC that calls `hasUnsavedChanges` on the wrapped component
 * when leaving the page and asks for confirmation.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { injectIntl } from 'react-intl';

import getWrappedDisplayName from './getWrappedDisplayName';
import messages from './messages';

const withLeavePageConfirmation = WrappedComponent => {
  class WithLeavePageConfirmation extends React.Component {
    componentDidMount() {
      this.props.router.setRouteLeaveHook(
        this.props.route,
        this.unsavedChangesRouteLeaveHook
      );
      window.addEventListener('beforeunload', this.unsavedChangesUnloadHook);

      this.backListener = browserHistory.listen((loc, action) => {
        if (action === 'POP') {
          this.unsavedChangesUnloadHook();
        }
      });
    }

    componentWillUnmount() {
      this.props.router.setRouteLeaveHook(this.props.route, undefined);
      window.removeEventListener('beforeunload', this.unsavedChangesUnloadHook);
      this.backListener();
    }

    unsavedChangesRouteLeaveHook = () => {
      if (this.wrappedElem.hasUnsavedChanges()) {
        return (
          this.props.customMessage ||
          this.props.intl.formatMessage(
            messages.withLeavePageConfirmationUnsavedChangesWarning
          )
        );
      }

      return true;
    };

    unsavedChangesUnloadHook = event => {
      if (this.wrappedElem.hasUnsavedChanges()) {
        const confirmationMessage =
          this.props.customMessage ||
          this.props.intl.formatMessage(
            messages.withLeavePageConfirmationUnsavedChangesWarning
          );

        // eslint-disable-next-line no-param-reassign
        (event || window.event).returnValue = confirmationMessage; // Gecko + IE
        return confirmationMessage; // Webkit, Safari, Chrome
      }

      return null;
    };

    render() {
      return (
        <WrappedComponent
          ref={ref => {
            this.wrappedElem = ref;
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
        />
      );
    }
  }

  WithLeavePageConfirmation.propTypes = {
    router: PropTypes.shape({
      setRouteLeaveHook: PropTypes.func.isRequired,
    }).isRequired,
    route: PropTypes.object.isRequired,

    intl: PropTypes.shape({
      formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    customMessage: PropTypes.string,
  };

  WithLeavePageConfirmation.displayName = getWrappedDisplayName(
    WrappedComponent,
    'WithLeavePageConfirmation'
  );
  return injectIntl(WithLeavePageConfirmation);
};

export { withLeavePageConfirmation };
