import { css } from 'styled-components';

import { getBaseUnit } from '../styles/themeGetters';

export type MarginProps = {
  m?: number;
  mx?: number;
  my?: number;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
};

export type PaddingProps = {
  p?: number;
  px?: number;
  py?: number;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
};

const marginMixin = ({
  m,
  ml,
  mr,
  mt,
  mb,
  mx,
  my,
}: Partial<MarginProps>) => css`
  ${m !== undefined &&
    css`
      margin: ${getBaseUnit(m)};
    `}
  ${(ml !== undefined || mx !== undefined) &&
    css`
      margin-left: ${getBaseUnit(ml ?? mx ?? 0)};
    `}
  ${(mr !== undefined || mx !== undefined) &&
    css`
      margin-right: ${getBaseUnit(mr ?? mx ?? 0)};
    `}
  ${(mt !== undefined || my !== undefined) &&
    css`
      margin-top: ${getBaseUnit(mt ?? my ?? 0)};
    `}
  ${(mb !== undefined || my !== undefined) &&
    css`
      margin-bottom: ${getBaseUnit(mb ?? my ?? 0)};
    `}
`;
const paddingMixin = ({
  p,
  pl,
  pr,
  pt,
  pb,
  px,
  py,
}: Partial<PaddingProps>) => css`
  ${p !== undefined &&
    css`
      padding: ${getBaseUnit(p)};
    `}
  ${(pl !== undefined || px !== undefined) &&
    css`
      padding-left: ${getBaseUnit(pl ?? px ?? 0)};
    `}
  ${(pr !== undefined || px !== undefined) &&
    css`
      padding-right: ${getBaseUnit(pr ?? px ?? 0)};
    `}
  ${(pt !== undefined || py !== undefined) &&
    css`
      padding-top: ${getBaseUnit(pt ?? py ?? 0)};
    `}
  ${(pb !== undefined || py !== undefined) &&
    css`
      padding-bottom: ${getBaseUnit(pb ?? py ?? 0)};
    `}
`;

export { marginMixin, paddingMixin };
