import { createGlobalStyle } from 'styled-components';

import { Theme } from 'baukasten';

import { colors, shadows } from 'utils/style';

const GlobalStyle = createGlobalStyle`
  /* DayPicker styles */

  .DayPicker {
    display: inline-block;
  }

  .DayPicker-wrapper {
    position: relative;
    user-select: none;
    padding-bottom: 1rem;
    flex-direction: row;
    outline: none;
    min-width: 285px;
  }

  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .DayPicker-Month {
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    user-select: none;
    margin: 0 1rem;
    margin-top: 1rem;
  }

  .DayPicker-NavButton {
    position: absolute;
    cursor: pointer;
    top: 1rem;
    right: 1.5rem;
    margin-top: 2px;
    color: ${colors.lightText};
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
  }

  .DayPicker-NavButton:hover {
    opacity: 0.8;
  }

  .DayPicker-NavButton--prev {
    margin-right: 1.5rem;

    &::after {
      font-family: "Font Awesome 5 Pro"; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
      content: "\f104";
      position: absolute;
      width: 1rem;
      text-align: center;
    }
  }

  .DayPicker-NavButton--next {
    &::after {
      font-family: "Font Awesome 5 Pro"; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
      content: "\f105";
      position: absolute;
      width: 1rem;
      text-align: center;
    }
  }

  .DayPicker-NavButton--interactionDisabled {
    display: none;
  }

  .DayPicker-Caption {
    padding: 0 0.5rem;
    display: table-caption;
    text-align: left;
    margin-bottom: 0.5rem;
    color: ${colors.darkerText};
  }

  .DayPicker-Caption > div {
    font-size: 1.15rem;
    font-weight: 500;
  }

  .DayPicker-Weekdays {
    margin-top: 1rem;
    display: table-header-group;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    display: table-cell;
    padding: 0.5rem;
    font-size: 0.875em;
    text-align: center;
    color: ${colors.lighterText};
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-Day {
    display: table-cell;
    padding: 0.5rem;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
  }

  .DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5rem;
    text-align: right;
    vertical-align: middle;
    min-width: 1rem;
    font-size: 0.75em;
    cursor: pointer;
    color: ${colors.lightText};
    border-right: 1px solid #eaecec;
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }

  .DayPicker-Footer {
    padding-top: 0.5rem;
  }

  .DayPicker-TodayButton {
    border: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
    color: #4a90e2;
    font-size: 0.875em;
  }

  .DayPicker-Day--today {
    color: ${Theme.getColor('blue')};
  }

  .DayPicker-Day--outside {
    cursor: default;
    color: ${colors.lightText};
  }

  .DayPicker-Day--disabled {
    color: #dce0e0;
    cursor: default;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    color: ${colors.lightestText};
    background-color: ${Theme.getColor('blue')};
    border-radius: 100%;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    opacity: 0.8;
  }

  .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: ${colors.focusBackground};
    border-radius: 50%;
  }

  .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):active {
    background-color: ${colors.activeBackground};
    border-radius: 50%;
  }

  /* DayPickerInput */

  .DayPickerInput {
    display: inline;
  }

  .DayPickerInput-OverlayWrapper {
    position: relative;
  }

  .DayPickerInput-Overlay {
    left: 0;
    z-index: 1;
    position: absolute;
    top: 4px;
    background: ${colors.whiteBg};
    box-shadow: ${shadows.menuBox};
    border-radius: 4px;
  }
`;

export default GlobalStyle;
