/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';

type TabContextInterface = {
  selectedTab?: number;
  setSelectedTab: (tab: number) => void;
  focusedTab?: number;
  setFocusedTab: (tab: number) => void;
};

const TabContext = createContext<TabContextInterface>({
  setSelectedTab: () => {},
  setFocusedTab: () => {},
});

export { TabContext };
