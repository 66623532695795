import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import { FeatureOverrides } from 'features/featureFeature/types';
import { CurrentUser } from 'types/CurrentUser';
import { allUserFeatureFlags } from 'types/UserFeatureFlags';
import { CurrentAccount } from 'types/CurrentAccount';

type UseFeatureOverrideStorage = {
  overrides: FeatureOverrides;
  hasOverrides: boolean;
  setOverrides: (state: FeatureOverrides) => void;
  resetOverrides: () => void;
  overrideUser: (user: CurrentUser) => CurrentUser;
  overrideAccount: (account: CurrentAccount) => CurrentAccount;
};

export const useFeatureOverrideStorage = (): UseFeatureOverrideStorage => {
  const initialState = {
    userFeatureFlags: {},
    accountFeatureFlags: {},
  };
  const [overrides, setOverrides] = useLocalStorage<FeatureOverrides>(
    'featureOverrides',
    initialState
  );

  const resetOverrides = () => {
    setOverrides(initialState);
  };

  const hasOverrides =
    Object.keys(overrides?.userFeatureFlags ?? {}).length > 0 ||
    Object.keys(overrides?.accountFeatureFlags ?? {}).length > 0;

  const overrideUserFeatures = (
    features: CurrentUser['features']
  ): CurrentUser['features'] => {
    const featuresExploded = features.reduce(
      (acc, f) => ({
        ...acc,
        [f]: true,
      }),
      {}
    );

    return allUserFeatureFlags.filter(flagName =>
      typeof overrides.userFeatureFlags[flagName] !== 'undefined'
        ? overrides.userFeatureFlags[flagName]
        : featuresExploded[flagName]
    );
  };

  const overrideUser = (user: CurrentUser) => {
    return {
      ...user,
      features: overrideUserFeatures(user.features),
    };
  };

  const overrideAccount = (account: CurrentAccount) => {
    return {
      ...account,
      features: {
        ...account.features,
        ...overrides.accountFeatureFlags,
      },
    };
  };

  return {
    overrides,
    hasOverrides,
    setOverrides,
    resetOverrides,
    overrideUser,
    overrideAccount,
  };
};
