import React from 'react';
import { useIntl } from 'react-intl';

import { Box } from '../../../Box';
import { Avatar } from '../../../Avatar';
import { EntityRendererProps } from '../RendererProps';
import { Skeleton } from '../../../Skeleton';
import { OwnerData, OwnerTeamData, OwnerUnassignedData } from '../../types';
import { LinkRenderer } from '../../common/LinkRenderer';
import { TD } from '../../../Table';
import messages from './messages';
import { Icons } from '../../../Icons';

type OwnerAvatarProps = {
  owner: OwnerData | OwnerTeamData | OwnerUnassignedData;
};

const OwnerAvatar = ({ owner }: OwnerAvatarProps) => {
  const { formatMessage } = useIntl();

  switch (owner.entityType) {
    case 'owner': {
      const user = owner;
      const fullName = `${user.firstname} ${user.lastname}`;
      return (
        <LinkRenderer url={user.url}>
          <Avatar
            fallbackText={fullName}
            image={user.archived ? undefined : user.avatarUrl}
            size="s"
            type="person"
            status={user.archived ? 'archived' : undefined}
          />
        </LinkRenderer>
      );
    }
    case 'owner_team': {
      const team = owner;
      return (
        <LinkRenderer url={team.url}>
          <Avatar
            fallbackText={team.name}
            size="s"
            type="team"
            status={team.archived ? 'archived' : undefined}
          />
        </LinkRenderer>
      );
    }

    case 'owner_unassigned':
      return (
        <Avatar
          fallbackText={formatMessage(messages.unassigned)}
          type="person"
          size="s"
        >
          <Icons.User color="navy" />
        </Avatar>
      );
  }
};

const OwnerRenderer = (
  props: EntityRendererProps<
    'owner',
    OwnerData | OwnerTeamData | OwnerUnassignedData
  >
) => {
  if (
    props.loadingState === 'loading' ||
    // @ts-ignore  PL-2222
    props.cell?.entityType === 'owner_loading_cell'
  ) {
    return (
      <TD pl={16} pr={16} width={28}>
        <Skeleton borderRadius="50%" width="28px" height="28px" />
      </TD>
    );
  }

  return (
    <TD pl={16} pr={16} width={28}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        aria-label="cell owner"
      >
        <OwnerAvatar owner={props.cell} />
      </Box>
    </TD>
  );
};

export { OwnerRenderer, OwnerAvatar };
