import React from 'react';
import styled from 'styled-components';

import { Box } from 'baukasten';

import { PublicAccount } from 'types/Account';
import ToastNotificationContainer from 'containers/ToastNotificationContainer';

import { PrimaryContent } from './components/PrimaryContent';
import { SecondaryContent } from './components/SecondaryContent';
import { Footer } from './components/Footer';

const PrimaryContentContainer = styled(Box)<{ hideSecondaryContent?: boolean }>`
  // full-size primary content on mobile devices
  @media only screen and (max-width: 940px) {
    width: 100%;
  }

  width: ${({ hideSecondaryContent }) =>
    hideSecondaryContent ? '100%' : '60%'};
`;

const SecondaryContentContainer = styled(Box)`
  overflow: hidden;

  // hide secondary content on mobile devices
  @media only screen and (max-width: 940px) {
    display: none;
  }
`;

type NonAuthenticatedAppLayoutProps = {
  header?: React.ReactNode;
  children?: React.ReactNode;
  publicAccountData?: PublicAccount;
  hideSecondaryContent?: boolean;
};

export const NonAuthenticatedAppLayout = ({
  header,
  children,
  publicAccountData,
  hideSecondaryContent = false,
}: NonAuthenticatedAppLayoutProps) => (
  <Box height="100%">
    <ToastNotificationContainer />

    {header}

    <Box display="flex" alignItems="stretch" height="100vh">
      {!hideSecondaryContent && (
        <SecondaryContentContainer
          width="40%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          backgroundColor="navy"
        >
          <SecondaryContent />
        </SecondaryContentContainer>
      )}

      <PrimaryContentContainer
        hideSecondaryContent={hideSecondaryContent}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        pl={8}
        pr={8}
      >
        <PrimaryContent publicAccountData={publicAccountData}>
          {children}
        </PrimaryContent>

        <Footer publicAccountData={publicAccountData} />
      </PrimaryContentContainer>
    </Box>
  </Box>
);
