import { MouseEvent } from 'react';

/**
 * Wrap a passed in click handler in a function that calls
 * `.stopPropagation()` on any received click event.
 * This is so that the click doesn't bubble up `onClick`,
 * so that the drawer doesn't open on menu interactions.
 *
 * @param onClick
 */
export const doNotPropagate =
  (onClick: () => void) => (e: MouseEvent<HTMLElement>) => {
    onClick();
    e.stopPropagation();
  };
