import { createContext } from 'react';

import { DataGridI18NOverrides } from './types';

type DataGridContextValues = {
  navigateTo?: (url: string) => void;
  toggleDefaultRows?: () => void;
  // has to be optional because of react typings,
  // but in reality this will always be set.
  i18nOverrides?: DataGridI18NOverrides;
};

const initialState: DataGridContextValues = {};

export const DataGridContext = createContext<DataGridContextValues>(
  initialState
);
