import {
  deleteRequestV2,
  getRequestV2,
  patchRequestV2,
  postRequestV2,
} from '../../methods';
import { getSearchKpisEndpoint } from './utils';
// v2/kpis?kind=org|team&cycle_id
//
const getKpis = (type, teamId, cycleId) =>
  getRequestV2(
    `kpis?kind=${type}&${type === 'team' && `team_id=${teamId}`}${
      cycleId && `&cycle_id=${cycleId}`
    }`
  );

const getBusinessUnitKpis = businessUnitId =>
  getRequestV2(`bu/${businessUnitId}/kpis`);

const getKpi = kpiId => getRequestV2(`kpis/${kpiId}`);

const getKpiValues = kpiId => getRequestV2(`kpis/${kpiId}/values`);

const createKpi = payload => postRequestV2(`kpis`, payload);

const editKpi = (kpiId, payload) => patchRequestV2(`kpis/${kpiId}`, payload);

const deleteKpi = kpiId => deleteRequestV2(`kpis/${kpiId}`);

// used to search all KPIs based on a query and/or team
const getSearchKpis = (kind, teamId, query) =>
  getRequestV2(getSearchKpisEndpoint(kind, teamId, query));

const upsertKpiValues = (kpiId, payload) =>
  postRequestV2(`kpis/${kpiId}/values`, payload);

const getKpiStatusLabels = () => getRequestV2(`kpi/status_labels`);

const kpis = {
  getKpi,
  getKpiValues,
  getKpis,
  createKpi,
  editKpi,
  deleteKpi,
  getSearchKpis,
  getBusinessUnitKpis,
  upsertKpiValues,
  getKpiStatusLabels,
};

export default kpis;
