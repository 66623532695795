import React from 'react';
import styled from 'styled-components';

import { getColor, getBaseUnit, getElevations } from '../styles/themeGetters';
import { ColorKeys, ElevationKeys } from '../styles/themes';
import {
  marginMixin,
  MarginProps,
  paddingMixin,
  PaddingProps,
} from '../utils/mixins';

const getBorderRadius = (round, borderRadius) => {
  if (round) return getBaseUnit(1);

  return getBaseUnit(borderRadius);
};

const Box = styled.div<BoxProps>`
  box-sizing: border-box;

  /* Margin */
  ${marginMixin}
  /* Padding */
  ${paddingMixin}

  position: ${({ position }) => position};

  /* Style */
  background-color: ${({ backgroundColor }) =>
    backgroundColor && getColor(backgroundColor)};
  border-top: ${({ borderPosition }) =>
    borderPosition && borderPosition.includes('top') && '1px solid'};
  border-bottom: ${({ borderPosition }) =>
    borderPosition && borderPosition.includes('bottom') && '1px solid'};
  border-left: ${({ borderPosition }) =>
    borderPosition && borderPosition.includes('left') && '1px solid'};
  border-right: ${({ borderPosition }) =>
    borderPosition && borderPosition.includes('right') && '1px solid'};
  border: ${({ borderPosition }) =>
    borderPosition && borderPosition.includes('all') && '1px solid'};

  border-color: ${({ borderPosition, borderColor }) =>
    borderColor && borderPosition && getColor(borderColor)};

  border-radius: ${({ borderRadius, round }) =>
    getBorderRadius(round, borderRadius)};

  /* Dimensions */
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};

  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};

  /* max-width: 100%; */

  /* Flex Layout  */
  display: ${({ display }) => display};
  flex-flow: ${({ flexDirection }) => flexDirection}
    ${({ flexWrap }) => flexWrap};
  place-content: ${({ flexAlignContent }) => flexAlignContent}
    ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ flexGap }) => flexGap};
  /* stylelint-disable declaration-block-no-shorthand-property-overrides --
     as these are optional, we need to declare them separately */
  flex-basis: ${({ flexBasis }) => flexBasis};
  flex: ${({ flex }) => flex};
  /* stylelint-enable declaration-block-no-shorthand-property-overrides */
  align-self: ${({ alignSelf }) => alignSelf};

  /* Multiline Behavior */

  /* Elevations */
  box-shadow: ${({ elevation }) =>
    elevation !== undefined && getElevations(elevation)};

  /* Grid */
  grid-area: ${({ gridArea }) => gridArea};

  /* Cursor */
  cursor: ${({ cursor }) => cursor};

  /* Overflow */
  overflow: ${({ overflow }) => overflow};
`;
type BorderPos = 'top' | 'right' | 'left' | 'bottom' | 'all';
type BoxProps = Partial<
  MarginProps &
    PaddingProps & {
      alignItems: string;
      alignSelf: string;
      as: string;
      borderColor: ColorKeys;
      borderRadius: number;
      children: React.ReactNode;
      backgroundColor: ColorKeys;
      display: string;
      elevation: ElevationKeys;
      flex: string;
      flexAlignContent: string;
      flexDirection: string;
      flexWrap: string;
      flexGap: string;
      flexBasis: string;
      height: string;
      minHeight: string;
      justifyContent: string;
      round: boolean;
      width: string;
      minWidth: string;
      gridArea: string;
      borderPosition: BorderPos[];
      cursor: string;
      overflow?: string;
      position?: string;
    }
>;

Box.defaultProps = {
  borderRadius: 0,
  overflow: 'visible',
  position: 'initial',
};

export { Box };
