import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';

import { ProgressIndicator } from '../../../ProgressIndicator';
import { Box } from '../../../Box';
import { Tooltip } from '../../../Tooltip';
import { EntityRendererProps } from '../RendererProps';
import {
  GoalProgressData,
  DraftGoalProgressData,
  KeyResultProgressData,
  DraftKeyResultProgressData,
} from '../../types';
import { Skeleton } from '../../../Skeleton';
import { HoverBox } from '../../common/HoverBox';
import { TD } from '../../../Table';
import messages from '../../common/messages';

export const ProgressIndicatorRenderer = (
  props: EntityRendererProps<
    'progress',
    | GoalProgressData
    | DraftGoalProgressData
    | KeyResultProgressData
    | DraftKeyResultProgressData
  >
) => {
  const { formatMessage } = useIntl();
  if (
    props.loadingState === 'loading' ||
    // @ts-ignore PL-2222
    props.cell?.entityType === 'progress_loading_cell'
  )
    return (
      <TD width={130} pl={16}>
        <Skeleton />
      </TD>
    );

  const { value, disabled, permissions, entityType } = props.cell;
  const applyHoverEffect = entityType === 'key_result_progress';
  // we want the tooltip to be rendered for Key Results when they can be clicked (when the user has the right permission)
  // and for Goals regardless of the users' permissions
  const shouldRenderTooltip =
    (entityType === 'key_result_progress' && permissions.canEdit) ||
    entityType === 'goal_progress';

  // if value is undefined we return null
  if (typeof value === 'undefined') {
    return null;
  }

  switch (entityType) {
    case 'draft_goal_progress':
    case 'draft_key_result_progress':
      return (
        <TD width="130" pl={16} pr={16} aria-label="cell progress">
          <Box height="56px" display="flex" alignItems="center">
            <ProgressIndicator value={0} disabled />
          </Box>
        </TD>
      );

    case 'goal_progress':
    case 'key_result_progress': {
      // following is ugly code that we should clean up.
      // we needed to introduce a separate tooltip to wrap the progressIndicator
      // explaining how the progress is calculated.
      // Feel free to clean this up!
      // eslint-disable-next-line react/display-name
      const Content = forwardRef<HTMLDivElement>((props1, ref) => {
        // eslint-disable-next-line react/prop-types
        return permissions.canEdit ? (
          <HoverBox
            applyHoverEffect={applyHoverEffect}
            display="flex"
            alignItems="center"
            pl={4}
            pr={4}
            cursor={applyHoverEffect ? 'pointer' : undefined}
            // eslint-disable-next-line react/prop-types
            onClick={() =>
              props.config.onClickCell?.(props.cell, props.address)
            }
            aria-label="update"
            ref={ref}
          >
            <ProgressIndicator
              value={value}
              fontSize={14}
              disabled={disabled}
            />
          </HoverBox>
        ) : (
          <Box ref={ref}>
            <ProgressIndicator
              value={value}
              fontSize={14}
              disabled={disabled}
            />
          </Box>
        );
      });

      return (
        <TD
          width="130"
          pl={permissions.canEdit ? 0 : 16}
          pr={permissions.canEdit ? 0 : 16}
          aria-label="cell progress"
        >
          <Box height="56px" display="flex" alignItems="center">
            <Tooltip
              show={shouldRenderTooltip ? undefined : false}
              tooltipContent={
                applyHoverEffect
                  ? formatMessage(messages.clickToUpdate)
                  : formatMessage(messages.aggregatedProgressAndCL)
              }
            >
              <Content />
            </Tooltip>
          </Box>
        </TD>
      );
    }
  }
};
