import { useState } from 'react';

import { Row } from '../types';
import { FilterFunctionProps, FilterIdentifier } from './filters/types';
import { makeFilterRows } from './filters/filterRows';

export type RowFilterInternals = {
  filterRows: (rows: Row[]) => Row[];
};

type RowFilterHook = {
  selectedFilter: FilterIdentifier;
  setSelectedFilter: (filterIdentifier: FilterIdentifier) => void;
  getInternals: () => RowFilterInternals;
};

export const useRowFilter: (
  initialFilter: FilterIdentifier,
  props: FilterFunctionProps
) => RowFilterHook = (initialFilter, props) => {
  const [selectedFilter, setSelectedFilter] = useState<FilterIdentifier>(
    initialFilter
  );
  const filterRows = makeFilterRows(selectedFilter, props);

  return {
    selectedFilter,
    setSelectedFilter,
    getInternals: () => ({ filterRows }),
  };
};
