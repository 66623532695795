import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button, Theme } from 'baukasten';

import { useLocale } from 'utils/hooks/globalState';

import messages from './messages';
import { appLocales } from '../../i18n';
import { changeLocale } from '../App/actions';

const LocaleOption = styled.li`
  margin-left: 0.5rem;
  float: left;

  &:first-child {
    margin-left: 0;
  }
`;

const LocaleOptions = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

type I18NKey = keyof typeof messages;

export const LocaleToggle = () => {
  const locale = useLocale();

  const dispatch = useDispatch();

  const onLocaleSelect = (selectedLocale: I18NKey) => {
    dispatch(changeLocale(selectedLocale));
  };

  const locales = appLocales.map(l => {
    return (
      <LocaleOption key={l}>
        <Button
          variant="text"
          onClick={() => onLocaleSelect(l as I18NKey)}
          style={
            {
              fontWeight: locale === l ? 'bold' : 'normal',
              color: Theme.getColor('navy'),
              fontSize: '16px',
            } as const
          }
        >
          <FormattedMessage {...messages[l]} />
        </Button>
      </LocaleOption>
    );
  });

  return <LocaleOptions>{locales}</LocaleOptions>;
};

export default LocaleToggle;
