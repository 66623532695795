import styled from 'styled-components';

import { getFontSize, getFontWeight } from '../../styles/themeGetters';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: ${getFontSize(14)};
  font-weight: ${getFontWeight('semibold')};
`;
