import React from 'react';

import { InputControl } from '../components/InputControl';
import { CommonProps } from '../types';
import { InputOption, CheckOption } from '../components/InputOption';

type Option = {
  value: string;
  label: string;
  helpText?: string;
};

type Props = {
  options: Option[];
} & CommonProps;

const CheckboxInput = (props: Props) => {
  const {
    name,
    error,
    disabled,
    helpText,
    label,
    options,
    validations = {},
    formHooks: { register, watch },
  } = props;

  const value = watch?.(name);

  return (
    <InputControl label={label} error={error} helpText={helpText}>
      {options.map((option, key) => {
        const isChecked = value ? value.includes(option.value) : false;
        return (
          <InputOption
            disabled={disabled}
            label={option.label}
            helpText={option.helpText}
            key={key}
          >
            <CheckOption
              disabled={disabled}
              hasError={Boolean(error)}
              isSelected={isChecked}
              value={option.value}
              register={register}
              name={name}
              validations={validations}
            />
          </InputOption>
        );
      })}
    </InputControl>
  );
};

export { CheckboxInput };
