import React from 'react';

import { CommonProps } from '../types';
import { InputControl } from '../components/InputControl';
import { RawMentionsTextArea } from './RawMentionsTextArea';
import { SuggestionsClient } from './types';

type MentionsTextAreaProps = {
  loadingMessage: string;
  noMatchesMessage: string;
  suggestionsClient: SuggestionsClient;
} & CommonProps;

const createSyntheticEvent = (value: string, name: string) => ({
  target: {
    value,
    name,
  },
});

export const MentionsTextArea = ({
  name,
  label,
  helpText,
  error,
  condensed,
  suggestionsClient,
  loadingMessage,
  noMatchesMessage,
  validations = {},
  formHooks: { register, watch },
}: MentionsTextAreaProps) => {
  const content = watch && watch(name);
  const { onChange } = register(name, validations);

  return (
    <InputControl
      condensed={condensed}
      error={error}
      helpText={helpText}
      label={label}
    >
      <RawMentionsTextArea
        content={content}
        onUpdate={value => {
          onChange(createSyntheticEvent(value, name));
        }}
        loadingMessage={loadingMessage}
        noMatchesMessage={noMatchesMessage}
        suggestionsClient={suggestionsClient}
      />
    </InputControl>
  );
};
