import React, { PropsWithChildren, useEffect } from 'react';
import { Column, useExpanded, useTable } from 'react-table';

import { DataGridContext } from './DataGridContext';
import { useExpandRowsByDefault } from './useExpandRowsByDefault';
import { Table, THead, TBody, TR, TD } from '../Table';
import {
  DynamicCellRenderer,
  DynamicHeaderRenderer,
  DynamicLoadingRenderer,
} from './renderers';
import { ColumnConfig, DataGridI18NOverrides, Row } from './types';
import { useRowHighlight, useRowFilter } from './hooks';
import { RowFilterInternals } from './hooks/rowFilter';
import { RowHighlightInternals } from './hooks/rowHighlight';

type DataGridProps = {
  rows: Row[];
  columns: ColumnConfig[];
  i18nOverrides?: DataGridI18NOverrides;
  loadingState: 'loading' | 'success' | 'error';
  loadingSkeletonRowCount?: number;
  errorComponent: React.ReactNode;
  emptyComponent: React.ReactNode;
  expandedRowsByDefault?: string[];
  variant?: 'regular' | 'light';
  navigateTo?: (url: string) => void;
  collapseAll?: boolean;
  rowHighlight?: RowHighlightInternals;
  rowFilter?: RowFilterInternals;
};

const TableContentPlaceHolder = ({
  colSpan,
  children,
}: PropsWithChildren<{ colSpan: number }>) => (
  <TR disableHover>
    <TD colSpan={colSpan}>{children}</TD>
  </TR>
);

const DataGrid = ({
  i18nOverrides,
  loadingSkeletonRowCount = 9,
  loadingState,
  expandedRowsByDefault = [],
  variant = 'regular',
  navigateTo,
  collapseAll = false,
  ...props
}: DataGridProps) => {
  const memoizedColumns = React.useMemo(
    () => {
      // @ts-ignore TODO fix this type error - maybe by switching to react-table v8
      const columnData: Column<Row>[] = props.columns.map(column => {
        return {
          id: column.key,
          Header: DynamicHeaderRenderer,
          accessor: (row: Row) => {
            if (column.key in row.data) {
              return row.data[column.key];
            }
          },
          Cell: innerProps => (
            <DynamicCellRenderer
              {...innerProps}
              columnConfig={column}
              columnConfigs={props.columns}
              loading={loadingState}
            />
          ),
        };
      });
      return columnData;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- [RFC 032]
    [props.columns]
  );

  const memoizedRows = React.useMemo(
    () => {
      if (loadingState === 'success') {
        if (props.rowFilter) {
          return props.rowFilter.filterRows(props.rows);
        }
        return props.rows;
      }
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- [RFC 032]
    [loadingState, props.rows, props.rowFilter?.filterRows]
  );

  const tableInstance = useTable<Row>(
    {
      columns: memoizedColumns,
      data: memoizedRows,
      autoResetExpanded: false, // Prevents <DataGrid /> from collapsing when memoizedRows changes.
    },
    useExpanded
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const toggleDefaultRows = useExpandRowsByDefault({
    tableInstance,
    expandedRowsByDefault,
    collapseAll,
  });

  const showTableHeader = variant === 'regular';

  const noShadow = variant === 'light';

  const onHighlightChange = () => {
    if (props.rowHighlight) {
      const { highlightedRowIndex, setHighlightedRow } = props.rowHighlight;

      if (highlightedRowIndex >= 0) {
        setHighlightedRow(rows[highlightedRowIndex].original);
      } else {
        setHighlightedRow(null);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps -- [RFC 032]
  useEffect(onHighlightChange, [props.rowHighlight?.highlightedRowIndex]);

  const onExpansionStateChange = () => {
    if (props.rowHighlight) {
      const { setHighlightedRowIndex, setRows } = props.rowHighlight;

      setRows(
        rows.map(r => ({
          id: r.original.id,
          entityType: r.original.entityType,
        }))
      );

      const selectedIndex = rows
        .map(row => row.original)
        .indexOf(props.rowHighlight?.highlightedRow);

      setHighlightedRowIndex(selectedIndex);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps -- [RFC 032]
  useEffect(onExpansionStateChange, [rows.length]);

  return (
    <DataGridContext.Provider
      value={{ navigateTo, toggleDefaultRows, i18nOverrides }}
    >
      <Table {...getTableProps()} aria-label="datagrid">
        {showTableHeader && (
          <THead>
            {headerGroups.map((headerGroup, i) => (
              <TR key={i} {...headerGroup.getHeaderGroupProps()} disableHover>
                {headerGroup.headers.map((column, j) => (
                  <React.Fragment key={j}>
                    {column.render('Header')}
                  </React.Fragment>
                ))}
              </TR>
            ))}
          </THead>
        )}
        <TBody {...getTableBodyProps()} noShadow={noShadow}>
          {loadingState === 'loading' &&
            Array.from(Array(loadingSkeletonRowCount).keys()).map(key => {
              return (
                <TR key={key}>
                  {props.columns.map((col, i) => {
                    return <DynamicLoadingRenderer key={i} col={col} />;
                  })}
                </TR>
              );
            })}
          {loadingState === 'success' &&
            (rows.length === 0 ? (
              <TableContentPlaceHolder colSpan={props.columns.length}>
                {props.emptyComponent}
              </TableContentPlaceHolder>
            ) : (
              rows.map((row, i) => {
                prepareRow(row);
                const isRowSelected =
                  props.rowHighlight?.highlightedRowIndex === i;
                return (
                  <TR isSelected={isRowSelected} key={i} {...row.getRowProps()}>
                    {row.cells.map((cell, j) => {
                      return (
                        <React.Fragment key={j}>
                          {cell.render('Cell')}
                        </React.Fragment>
                      );
                    })}
                  </TR>
                );
              })
            ))}
          {loadingState === 'error' && (
            <TableContentPlaceHolder colSpan={props.columns.length}>
              {props.errorComponent}
            </TableContentPlaceHolder>
          )}
        </TBody>
      </Table>
    </DataGridContext.Provider>
  );
};

const DataGridHooks = {
  useRowHighlight,
  useRowFilter,
};

export { DataGrid, DataGridHooks };
