import { createGlobalStyle, css } from 'styled-components';

/**
 * A styled-components GlobalStyle component that adds outlines to all elements when
 * the URL contains 'debug-outlines' query parameter.
 *
 * This is useful for debugging layout and visualizing element boundaries.
 * To enable, add ?debug-outlines to the URL.
 *
 * @example
 * // Add to app root to enable outline debugging
 * <DebugOutlines />
 */
export const DebugOutlines = createGlobalStyle`
  ${() => {
    const isDevelopment = process.env.NODE_ENV === 'development';

    const showOutlines =
      isDevelopment && window.location.search.includes('debug-outlines');

    return showOutlines
      ? css`
          * {
            outline: 1px #eee solid;
          }
        `
      : '';
  }}
`;
