import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Editor, NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import { Node } from 'prosemirror-model';
import { MessageDescriptor, useIntl } from 'react-intl';

import { GoalTagType } from 'types/GoalTag';

import { Box } from '../../../Box';
import { Icons } from '../../../Icons';
import { Tooltip } from '../../../Tooltip';
import { getColor } from '../../../styles/themeGetters';
import { ColorKeys } from '../../../styles/themes';
import messages from '../messages';
import { ChangeTypeMenu } from './ChangeTypeMenu';

const IconWrapper = styled(Box)`
  padding-top: 5px;
  padding-bottom: 5px;

  /* Make sure that Icon is aligned with the tag */
  transform: translateY(2px);
`;

const colorMap: Record<GoalTagType, ColorKeys> = {
  customer: 'green-light',
  future_state: 'blue-light',
  value: 'yellow-light',
};

const getBackgroundColor = (tagSelected: boolean, type: GoalTagType) =>
  tagSelected ? getColor('navy') : getColor(colorMap[type]);

const StyledNodeContentWrapper = styled(Box)<{
  selected: boolean;
  type: GoalTagType;
  isSelectable: boolean;
}>`
  background-color: ${({ selected, type }) =>
    getBackgroundColor(selected, type)};
  color: ${({ selected }) => getColor(selected ? 'white' : 'black')};
  cursor: ${({ isSelectable }) => (isSelectable ? 'pointer' : 'default')};
`;

const ActionIcon = ({
  tagSelected,
  handleRemoveTag,
}: {
  tagSelected: boolean;
  handleRemoveTag: (e: React.MouseEvent) => void;
}) => (
  <IconWrapper
    contentEditable={false}
    cursor="pointer"
    display="inline-flex"
    pl={2}
    pr={3}
    onClick={tagSelected ? undefined : handleRemoveTag}
    data-testid={tagSelected ? 'collapse-menu-icon' : 'remove-tag-icon'}
  >
    {tagSelected ? (
      <Icons.SolidChevronUp size="16px" color="white" noPadding />
    ) : (
      <Icons.Close size="16px" noPadding />
    )}
  </IconWrapper>
);

const tooltipMessageMap: Record<GoalTagType, MessageDescriptor> = {
  customer: messages.customer,
  future_state: messages.futureState,
  value: messages.value,
};

type Props = {
  editor: Editor;
  getPos: () => number;
  node: Node & {
    attrs: {
      type: GoalTagType;
    };
  };
  updateAttributes: (attrs: Record<string, unknown>) => void;
};

// Renders a colored goal tag inside an editor for each goal tag present in the editor
export const GoalTagNodeView = ({
  editor,
  getPos,
  node,
  updateAttributes,
}: Props) => {
  const [tagSelected, setTagSelected] = useState(false);
  const nodeRef = useRef(null);
  const { formatMessage } = useIntl();

  const handleTagClick = (e: React.MouseEvent) => {
    if (!editor.isEditable) {
      return;
    }

    e.stopPropagation();

    setTagSelected(!tagSelected);
  };

  const handleRemoveTag = (e: React.MouseEvent) => {
    if (!editor.isEditable) {
      return;
    }

    e.stopPropagation();

    const from = getPos();

    editor.commands.removeGoalTag({ from, node });
  };

  const handleChangeTagType = (newType: GoalTagType) => {
    if (!editor.isEditable) {
      return;
    }

    updateAttributes({ type: newType });
    setTagSelected(false);
  };

  return (
    <Tooltip
      tooltipContent={formatMessage(tooltipMessageMap[node.attrs.type])}
      addInlineMiddleware
      minWidth="50px"
      delay={{ open: 300, close: 100 }}
    >
      <NodeViewWrapper as="span">
        <StyledNodeContentWrapper
          pl={3}
          pt={1}
          pb={1}
          pr={editor.isEditable ? 0 : 3}
          display="inline"
          borderRadius={4}
          type={node.attrs.type}
          selected={tagSelected}
          isSelectable={editor.isEditable}
          onClick={handleTagClick}
          ref={nodeRef}
          aria-label={`${node.attrs.type} goal tag wrapper`}
        >
          {/* Renders the text of the goal tag */}
          <NodeViewContent as="span" className="content" />

          {/* Renders the icon that allows the user to remove the goal tag or collapse the menu */}
          {editor.isEditable && (
            <ActionIcon
              tagSelected={tagSelected}
              handleRemoveTag={handleRemoveTag}
            />
          )}
        </StyledNodeContentWrapper>

        {/* Renders the menu that allows the user to replace the goal tag */}
        <ChangeTypeMenu
          selectedType={node.attrs.type}
          open={tagSelected}
          anchorEl={nodeRef}
          handleSelectType={handleChangeTagType}
          onClose={() => setTagSelected(false)}
        />
      </NodeViewWrapper>
    </Tooltip>
  );
};
