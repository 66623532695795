import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
  getFileRequest,
  postRequestV2,
  deleteRequestV2,
} from '../../methods';

// Account Settings
const settingsGetAccount = () => getRequest('settings/account');

// Users
const settingsGetUser = userId => getRequest(`settings/users/${userId}`);
const settingsUpdateUser = (userId, payload) =>
  patchRequest(`settings/users/${userId}`, payload);
const settingsSearchUsers = query =>
  getRequest(`settings/users/search?${query}`);
const settingsMakeUserFullUser = userId =>
  patchRequest(`settings/users/${userId}/make_full_user`, {});
const settingsMakeUserViewer = userId =>
  patchRequest(`settings/users/${userId}/make_viewer`, {});
const settingsMakeUserSuperAdmin = userId =>
  patchRequest(`settings/users/${userId}/make_super_admin`, {});
const settingsRevokeUserSuperAdmin = userId =>
  patchRequest(`settings/users/${userId}/revoke_super_admin`, {});
const settingsDeactivateUser = userId =>
  patchRequest(`settings/users/${userId}/deactivate`, {});
const settingsReactivateUser = userId =>
  patchRequest(`settings/users/${userId}/reactivate`, {});
const settingsMakeUserCoach = userId =>
  patchRequest(`settings/users/${userId}/make_coach`, {});
const settingsRevokeUserCoach = userId =>
  patchRequest(`settings/users/${userId}/revoke_coach`, {});
const settingsMakeUserProgramLead = userId =>
  patchRequest(`settings/users/${userId}/make_program_lead`, {});
const settingsRevokeUserProgramLead = userId =>
  patchRequest(`settings/users/${userId}/revoke_program_lead`, {});
const settingsDeleteUser = userId => deleteRequest(`settings/users/${userId}`);

// Labels
const settingsGetLabels = () => getRequest('settings/labels');
const settingsCreateLabel = payload => postRequest('settings/labels', payload);
const settingsUpdateLabel = (labelId, payload) =>
  patchRequest(`settings/labels/${labelId}`, payload);
const settingsDeleteLabel = labelId =>
  deleteRequest(`settings/labels/${labelId}`);

// Invites
const settingsCreateInvite = payload =>
  postRequest('settings/users/invite', payload);
const settingsResendInvitation = userId =>
  patchRequest(`settings/users/${userId}/resend_invitation`, {});
const settingsCancelInvitation = userId =>
  deleteRequest(`settings/users/${userId}/cancel_invitation`, {});

// Custom Goal Filters
const settingsGetCustomGoalFilters = () =>
  getRequest('settings/custom_goal_filters');
const settingsCreateCustomGoalFilter = payload =>
  postRequest('settings/custom_goal_filters', payload);
const settingsGetCustomGoalFilter = customGoalFilterId =>
  getRequest(`settings/custom_goal_filters/${customGoalFilterId}`);
const settingsUpdateCustomGoalFilter = (customGoalFilterId, payload) =>
  patchRequest(`settings/custom_goal_filters/${customGoalFilterId}`, payload);
const settingsDeleteCustomGoalFilter = customGoalFilterId =>
  deleteRequest(`settings/custom_goal_filters/${customGoalFilterId}`);

// Integrations
const settingsUpdatePersonio = payload =>
  patchRequest('settings/integrations/personio', payload);
const settingsUpdateSlack = payload =>
  patchRequest('settings/integrations/slack', payload);
const settingsTestSlack = () =>
  postRequest('settings/integrations/slack/test', {});
const settingsUpdatePlanner = payload =>
  patchRequest('settings/integrations/planner', payload);
// Jira integrations
const settingsGetJiraIntegrations = () =>
  getRequest('settings/integrations/jiras');
const settingsGetJiraIntegration = integrationId =>
  getRequest(`settings/integrations/jiras/${integrationId}`);
const settingsCreateJiraIntegration = payload =>
  postRequest('settings/integrations/jiras', payload);
const settingsUpdateJiraIntegration = (integrationId, payload) =>
  patchRequest(`settings/integrations/jiras/${integrationId}`, payload);
const settingsDeleteJiraIntegration = integrationId =>
  deleteRequest(`settings/integrations/jiras/${integrationId}`);
// Azure integrations
const settingsGetAzureIntegration = () =>
  getRequest('settings/integrations/azure');
const settingsCreateAzureIntegration = payload =>
  postRequest('settings/integrations/azure', payload);
const settingsUpdateAzureIntegration = (id, payload) =>
  patchRequest(`settings/integrations/azure/${id}`, payload);
const settingsDeleteAzureIntegration = id =>
  deleteRequest(`settings/integrations/azure/${id}`);
const settingsGetAzureIntegrationDetails = id =>
  getRequest(`settings/integrations/azure/${id}`);

// SCIM
const settingsIntegrationGetScimApplications = () =>
  getRequest(`settings/integrations/scim`);
const settingsIntegrationCreateScimApplication = payload =>
  postRequest(`settings/integrations/scim`, payload);
const settingsIntegrationDeleteScimApplication = id =>
  deleteRequest(`settings/integrations/scim/${id}`);

// SAML
const settingsIntegrationGetSamlConfiguration = () =>
  getRequest(`settings/integrations/saml`);
const settingsIntegrationUpdateSamlConfiguration = payload =>
  patchRequest(`settings/integrations/saml`, payload);

// Api Clients
const settingsGetApiClients = () => getRequest('settings/api_clients');
const settingsGetApiClient = apiClientId =>
  getRequest(`settings/api_clients/${apiClientId}`);
const settingsCreateApiClient = payload =>
  postRequest('settings/api_clients', payload);
const settingsUpdateApiClient = (apiClientId, payload) =>
  patchRequest(`settings/api_clients/${apiClientId}`, payload);
const settingsDeleteApiClient = apiClientId =>
  deleteRequest(`settings/api_clients/${apiClientId}`);

// Export
const settingsCheckExportTeams = () =>
  getRequest('settings/teams/check_export');
const settingsRequestExportTeams = () =>
  getFileRequest('settings/teams/export');
const downloadExportTeams = key =>
  getRequest(`settings/teams/download_export?key=${key}`);

const settingsCheckExportUsers = () =>
  getRequest('settings/users/check_export');
const settingsRequestExportUsers = () =>
  getFileRequest('settings/users/export');
const downloadExportUsers = key =>
  getRequest(`settings/users/download_export?key=${key}`);

// Cycles
const settingsGetCycles = () => getRequest('settings/cycles');
const settingsGetCycle = cycleId => getRequest(`settings/cycles/${cycleId}`);
const settingsCreateCycle = payload => postRequest('settings/cycles', payload);
const settingsUpdateCycle = (cycleId, payload) =>
  patchRequest(`settings/cycles/${cycleId}`, payload);
const settingsDeleteCycle = cycleId =>
  deleteRequest(`settings/cycles/${cycleId}`);
const settingsActivateCycle = cycleId =>
  patchRequest(`settings/cycles/${cycleId}/activate`, {});

const settingsArchiveTeam = teamId =>
  patchRequest(`settings/teams/${teamId}/archive`, {});
const settingsGetTeams = () => getRequest('settings/teams');
const settingsGetTeam = teamId => getRequest(`settings/teams/${teamId}`);
const settingsRestoreTeam = teamId =>
  patchRequest(`settings/teams/${teamId}/restore`, {});

const settingsSearchTeams = query =>
  getRequest(`settings/teams/search?query=${query}`);

const settingsSearchPotentialParents = (teamId, query) =>
  getRequest(`settings/teams/${teamId}/parents/potential?query=${query}`);

const makeUserAnalyst = userId =>
  postRequestV2(`users/${userId}/roles/analyst`, {});

const revokeUserAnalyst = userId =>
  deleteRequestV2(`users/${userId}/roles/analyst`);

const settingsGetDraftingGuidelines = () =>
  getRequest('settings/drafting_guidelines_tip_texts');

const settings = {
  settingsGetAccount,

  settingsGetUser,
  settingsUpdateUser,
  settingsSearchUsers,
  settingsMakeUserFullUser,
  settingsMakeUserViewer,
  settingsMakeUserSuperAdmin,
  settingsRevokeUserSuperAdmin,
  settingsDeactivateUser,
  settingsReactivateUser,
  settingsMakeUserCoach,
  settingsRevokeUserCoach,
  settingsMakeUserProgramLead,
  settingsRevokeUserProgramLead,
  settingsDeleteUser,

  settingsGetLabels,
  settingsCreateLabel,
  settingsUpdateLabel,
  settingsDeleteLabel,

  settingsCreateInvite,
  settingsResendInvitation,
  settingsCancelInvitation,

  settingsGetCustomGoalFilters,
  settingsCreateCustomGoalFilter,
  settingsGetCustomGoalFilter,
  settingsUpdateCustomGoalFilter,
  settingsDeleteCustomGoalFilter,

  settingsUpdatePersonio,
  settingsUpdateSlack,
  settingsTestSlack,
  settingsUpdatePlanner,

  settingsGetJiraIntegrations,
  settingsGetJiraIntegration,
  settingsCreateJiraIntegration,
  settingsUpdateJiraIntegration,
  settingsDeleteJiraIntegration,

  settingsGetAzureIntegration,
  settingsCreateAzureIntegration,
  settingsUpdateAzureIntegration,
  settingsDeleteAzureIntegration,
  settingsGetAzureIntegrationDetails,

  settingsIntegrationGetScimApplications,
  settingsIntegrationCreateScimApplication,
  settingsIntegrationDeleteScimApplication,

  settingsIntegrationGetSamlConfiguration,
  settingsIntegrationUpdateSamlConfiguration,

  settingsGetApiClients,
  settingsGetApiClient,
  settingsCreateApiClient,
  settingsUpdateApiClient,
  settingsDeleteApiClient,

  settingsRequestExportTeams,
  settingsCheckExportTeams,
  downloadExportTeams,

  settingsRequestExportUsers,
  settingsCheckExportUsers,
  downloadExportUsers,

  settingsGetCycles,
  settingsGetCycle,
  settingsCreateCycle,
  settingsUpdateCycle,
  settingsDeleteCycle,
  settingsActivateCycle,

  settingsGetTeams,
  settingsGetTeam,
  settingsArchiveTeam,
  settingsRestoreTeam,

  settingsSearchTeams,
  settingsSearchPotentialParents,

  makeUserAnalyst,
  revokeUserAnalyst,

  settingsGetDraftingGuidelines,
};

export default settings;
