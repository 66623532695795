import React from 'react';

import { FontSizeKeys } from '../styles/themes';
import { Text } from '../Text';
import { getTextColor } from './utils/getTextColor';

type ConfidenceLevelProps = {
  value: number;
  fontSize?: FontSizeKeys;
  textAlign?: 'left' | 'center' | 'right';
};

const ConfidenceLevelIndicator = ({
  value,
  fontSize = 14,
  textAlign = 'left',
}: ConfidenceLevelProps) => {
  return (
    <Text
      fontSize={fontSize}
      color={getTextColor(value)}
      textAlign={textAlign}
      fontWeight="bold"
    >
      {value}
    </Text>
  );
};

export { ConfidenceLevelIndicator };
