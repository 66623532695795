import React from 'react';
import { FieldError } from 'react-hook-form';

import { InputControl } from '../components/InputControl';
import { RawSelect } from './RawSelect';
import { Items, Item } from './types';

type Props = {
  placeholder?: string;
  label?: string;
  helpText?: string;
  error?: FieldError;
  disabled?: boolean;
  onChange: (value: Item['value'] | null) => void;
  items: Items;
  initialValue: Item['value'];
  name?: string;
  maxHeight?: string;
  maxTextWidth?: string;
  hideClearButton?: boolean;
  value?: Item['value'];
  condensed?: boolean;
};

function Select({
  placeholder,
  label,
  helpText,
  error,
  onChange,
  disabled,
  items,
  initialValue,
  name,
  maxHeight,
  maxTextWidth,
  hideClearButton = false,
  value,
  condensed,
}: Props) {
  return (
    <InputControl
      condensed={condensed}
      error={error}
      helpText={helpText}
      label={label}
    >
      <RawSelect
        placeholder={placeholder}
        hasError={Boolean(error)}
        onChange={onChange}
        disabled={disabled}
        items={items}
        initialValue={initialValue}
        name={name}
        maxHeight={maxHeight}
        maxTextWidth={maxTextWidth}
        hideClearButton={hideClearButton}
        value={value}
      />
    </InputControl>
  );
}

export { Select };
