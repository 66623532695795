import React, { useState } from 'react';
import { useCombobox, useMultipleSelection } from 'downshift';
import styled from 'styled-components';

import { flattenItems, getFilteredItems } from './utils/utils';
import { InputField } from '../components/InputField';
import { Box } from '../../Box';
import { SelectedItem } from './renderers/SelectedItemRenderer';
import { MultiselectFieldBody } from './components/MultiselectFieldBody';
import { InputFieldClearButton } from '../components/InputFieldClearButton';
import { ToggleButton } from '../components/ToggleButton';
import { MultiselectMenu } from './components/MultiselectMenu';
import { MultiselectProps } from './types';

const Boxed = styled(Box)`
  flex: 1;
  flex-flow: wrap;
`;

function RawMultiSelect({
  items,
  initialSelectedItems,
  onChange,
  error,
  disabled,
  placeholder,
  lazyItemProps = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    userEvent: () => {},
    lazyItemState: {
      status: 'success',
      items: [],
    },
  },
}: MultiselectProps) {
  const [inputValue, setInputValue] = useState('');
  const anchorEl = React.useRef(null);
  const { lazyItemState, userEvent } = lazyItemProps;
  const [selectedItems, setSelectedItems] = React.useState(
    initialSelectedItems ?? []
  );
  const filteredItems = React.useMemo(
    () =>
      getFilteredItems(
        flattenItems(
          lazyItemState.items.length > 0 ? lazyItemState?.items : items
        ),
        inputValue
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- [RFC 032]
    [selectedItems, inputValue, items, lazyItemState]
  );

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    setSelectedItems: downshiftSetSelectedItems, // using an alias here because the name setSelectedItems is already taken
  } = useMultipleSelection({
    selectedItems,
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          setSelectedItems(newSelectedItems ?? []);

          break;
        case useMultipleSelection.stateChangeTypes.FunctionAddSelectedItem:
          // reset input when we selected item
          setInputValue('');
          break;
        default:
          break;
      }
    },
    onSelectedItemsChange: ({ selectedItems }) => {
      onChange(selectedItems);
    },
  });
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    openMenu,
  } = useCombobox({
    items: filteredItems,
    itemToString(item) {
      return item && item.entityType !== 'separator' ? item.title : '';
    },
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    stateReducer(_, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            ...(changes.selectedItem && {
              isOpen: true,
              highlightedIndex: 0,
            }),
          };
        default:
          return changes;
      }
    },
    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (
            newSelectedItem?.entityType !== 'group' &&
            selectedItems &&
            newSelectedItem
          ) {
            setSelectedItems([...selectedItems, newSelectedItem]);
            addSelectedItem(newSelectedItem);
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          if (newInputValue) {
            setInputValue(newInputValue);
            if (lazyItemProps) {
              userEvent({ type: 'filterChanged', filterValue: newInputValue });
            }
          }
          break;

        case useCombobox.stateChangeTypes.FunctionOpenMenu:
          if (lazyItemProps) {
            userEvent({
              type: 'menuOpened',
            });
          }
          break;
        default:
          break;
      }
    },
  });

  return (
    <div {...getMenuProps()}>
      <InputField
        hasError={Boolean(error)}
        disabled={disabled}
        ref={anchorEl}
        paddingRight="6px"
      >
        <Boxed display="flex">
          {selectedItems.map((selectedItemForRender, index) => (
            <SelectedItem
              key={selectedItemForRender.id}
              getSelectedItemProps={getSelectedItemProps}
              selectedItem={selectedItemForRender}
              idx={index}
              removeSelectedItem={removeSelectedItem}
            />
          ))}

          <MultiselectFieldBody
            getComboboxProps={getComboboxProps}
            getInputProps={getInputProps}
            getDropdownProps={getDropdownProps}
            openMenu={openMenu}
            isOpen={isOpen}
            placeholder={
              selectedItems.length === 0 && placeholder ? placeholder : ''
            }
            disabled={disabled}
          />
        </Boxed>
        <Box pt={1} display="flex">
          {selectedItems.length > 0 ? (
            <InputFieldClearButton
              onClick={() => {
                setSelectedItems([]);
                // it's necessary to tell downshift that a state change occurred
                // TODO: remove setSelectedItems completely and replace with the library's state setter
                downshiftSetSelectedItems([]);
              }}
              disabled={disabled}
            />
          ) : null}

          <ToggleButton
            getToggleButtonProps={getToggleButtonProps}
            isOpen={isOpen}
            disabled={disabled}
          />
        </Box>
      </InputField>
      {isOpen && (
        <MultiselectMenu
          filteredItems={filteredItems}
          selectedItems={selectedItems}
          getItemProps={getItemProps}
          isOpen={isOpen}
          anchorEl={anchorEl}
          inputValue={inputValue}
          lazyItemState={lazyItemState}
        />
      )}
    </div>
  );
}
export { RawMultiSelect };
