import styled from 'styled-components';

import { Text } from '../Text';
import {
  getColor,
  getFontSize,
  getElevations,
  getBaseUnit,
} from '../styles/themeGetters';

const StyledBreadcrumbWrapper = styled.ul`
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const StyledBreadcrumbItem = styled.li<{ isLast?: boolean }>`
  align-items: center;
  display: flex;
  font-size: ${getFontSize(14)};
  height: ${getBaseUnit(5)};
  margin-right: ${({ isLast }) => !isLast && getBaseUnit(3)};
`;

const StyledBreadcrumbText = styled(Text)<{ onClick?: () => void }>`
  cursor: ${({ onClick }) => onClick && 'pointer'};

  &:hover {
    color: ${({ onClick }) => onClick && getColor('grey-dark')};
  }

  & > a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: ${getColor('grey-dark')};
    }
  }
`;

// Dropdown
const StyledDropdownItem = styled.li`
  margin: ${getBaseUnit(3)} 0 0 0;
  border-bottom: 1px solid ${getColor('grey-light-100')};
  padding: ${getBaseUnit(2)} ${getBaseUnit(4)};

  &:last-child {
    border: none;
  }
`;

const StyledIconCaret = styled.div`
  margin-left: ${getBaseUnit(1)};
  width: ${getBaseUnit(4)};
  height: ${getBaseUnit(4)};
  align-items: center;
  justify-content: center;
  display: inline-flex;
  transition: box-shadow 240ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 160ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 4px;
  cursor: pointer;
`;

const StyledDropdownPositioner = styled.ul`
  z-index: 1;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  padding-top: ${getBaseUnit(1)};
  list-style: none;
  white-space: nowrap;
  transform: scale(0);
  opacity: 0;
  transition: transform 160ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 240ms cubic-bezier(0.4, 0, 0.2, 1);

  /* This value is hardcoded to align it to the icon and not based on the grid */
  left: 4px;
`;

const StyledDropdown = styled.div`
  background: white;
  border-radius: 4px;
  border: 1px solid ${getColor('grey-light-100')};
  transform-origin: top;
  transition: box-shadow 240ms cubic-bezier(0.4, 0, 0.2, 1);
`;

const StyledDropdownWrapper = styled.div`
  position: relative;

  &:hover {
    ${StyledDropdown} {
      box-shadow: ${getElevations(2)};
    }

    ${StyledDropdownPositioner} {
      transform: scale(1);
      opacity: 1;
    }

    ${StyledIconCaret} {
      background-color: ${getColor('grey-light-100')};
      transform: scale(1.2);
      box-shadow: ${getElevations(1)};
    }
  }
`;

export {
  StyledBreadcrumbWrapper,
  StyledBreadcrumbItem,
  StyledBreadcrumbText,
  StyledDropdownPositioner,
  StyledDropdownItem,
  StyledIconCaret,
  StyledDropdownWrapper,
  StyledDropdown,
};
