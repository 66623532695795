import React from 'react';
import styled from 'styled-components';
import { UseComboboxPropGetters } from 'downshift';

import { Text } from '../../../../Text';
import { Menu } from '../../../../Menu';
import { MenuItemRenderer } from '../../renderers/MenuItemRenderer';
import { Entity } from '../../types';

const EmptyState = styled(Text)`
  padding: 6px 16px;
`;

export type SelectMenuProps = {
  isOpen: boolean;
  anchorEl: React.RefObject<HTMLSpanElement>;
  initialValue: string;
  maxHeight?: string;
  items: Entity[];
  getItemProps: UseComboboxPropGetters<Entity>['getItemProps'];
};

export const SelectMenu = ({
  isOpen,
  anchorEl,
  initialValue,
  maxHeight,
  items,
  getItemProps,
}: SelectMenuProps) => {
  if (isOpen) {
    return (
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        menuOffset={4}
        fullWidth
        maxHeight={maxHeight}
      >
        {items.map((item, index) => (
          <MenuItemRenderer
            item={item}
            getItemProps={getItemProps}
            key={item.id}
            idx={index}
          />
        ))}
        {!items.length && (
          <EmptyState>
            No results found for{' '}
            <Text fontWeight="bold" as="strong">
              {initialValue}
            </Text>
          </EmptyState>
        )}
      </Menu>
    );
  }

  return <></>;
};
