import React from 'react';

import getWrappedDisplayName from './getWrappedDisplayName';

const withDefaultProps = defaultProps => WrappedComponent => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  const WithProps = props => <WrappedComponent {...defaultProps} {...props} />;

  WithProps.displayName = getWrappedDisplayName(
    WrappedComponent,
    'WithDefaultProps'
  );
  return WithProps;
};

export default withDefaultProps;
