import React, { KeyboardEvent } from 'react';
import styled, { css } from 'styled-components';

import {
  getFontFamily,
  getFontSize,
  getLineHeight,
  getColor,
} from '../../../styles/themeGetters';

type StyledInputProps = {
  type: 'text' | 'number' | 'time' | 'date';
  placeholder?: string;
  disabled?: boolean;
  as: 'textarea' | 'input';
};

const disabledStyles = css`
  color: ${getColor('grey-light-100')};
  cursor: not-allowed;
  background: #fff;
`;

const textareaStyles = height => css`
  resize: none;
  height: ${height ?? '172px'};
  padding: 8px 0;
`;

const defaultStyles = css`
  height: 34px;
  padding: 0;
`;

const StyledInput = styled.input<StyledInputProps>`
  font-family: ${() => getFontFamily('lato')};
  font-size: ${getFontSize(16)};
  line-height: ${getLineHeight(20)};
  border: none;
  width: 100%;

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: ${getColor('grey-dark')};
    opacity: 1;
  }

  ${props => (props.disabled ? disabledStyles : null)};
  ${props =>
    props.as === 'textarea' ? textareaStyles(props.height) : defaultStyles};
`;

type Props = {
  name: string;
  register: (name, validation) => { ref };
  placeholder?: string;
  disabled?: boolean;
  onKeyDown?: (
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  autoFocus?: boolean;
  value?: string;
  validations?: {
    required?: string;
    maxLength?:
      | {
          value: number;
          message: string;
        }
      | number;
    validate?: (value: string) => string | boolean;
  };
  type?: 'text' | 'number' | 'time' | 'date';
  as?: 'input' | 'textarea';
  min?: string;
  max?: string;
  step?: string;
  height?: string;
};

const InputFieldBody = ({
  name,
  register,
  placeholder,
  disabled,
  onKeyDown,
  autoFocus,
  validations,
  type = 'text',
  as = 'input',
  min,
  max,
  step,
  height,
}: Props) => (
  <StyledInput
    {...register(name, validations)}
    placeholder={placeholder}
    disabled={disabled}
    onKeyDown={onKeyDown}
    autoFocus={autoFocus}
    type={type}
    as={as}
    id={name}
    min={min}
    max={max}
    step={step}
    height={height}
  />
);

export { InputFieldBody };
