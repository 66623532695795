import React from 'react';
import styled from 'styled-components';

import { ColorKeys } from '../../styles/themes';
import { Box } from '../../Box';
import { getBaseUnit } from '../../styles/themeGetters';
import { Text } from '../../Text';

const defaultColors: ColorKeys[] = [
  'navy-dark',
  'navy',
  'blue-dark',
  'blue',
  'grey-light-100',
];

type LegendProps = {
  data: {
    label: string;
    value: string;
  }[];
  colors?: ColorKeys[];
};

const Circle = styled(Box)`
  width: ${getBaseUnit(2)};
  height: ${getBaseUnit(2)};
  border-radius: 100%;
  background-color: ${({ color }) => color};
`;

const Wrapper = styled(Box)`
  gap: ${getBaseUnit(2)} ${getBaseUnit(4)};
`;

const LabelText = styled(Text)`
  white-space: nowrap;
`;

const Legend = ({ data, colors = defaultColors }: LegendProps) => {
  return (
    <Wrapper display="flex" flexWrap="wrap">
      {data.map((el, index) => (
        <Box key={el.label} display="flex" alignItems="center">
          <Circle backgroundColor={colors[index]} mr={1} />
          <LabelText fontSize={12}>{el.label}</LabelText>
          <Text ml={1} fontSize={12} fontWeight="bold">
            {el.value}
          </Text>
        </Box>
      ))}
    </Wrapper>
  );
};

export { Legend };
