import { useCallback, useRef, useState } from 'react';

import { Row } from '../types';

type RowID = Pick<Row, 'entityType'> & { id: number };

export type RowHighlightInternals = {
  highlightedRowIndex: number;
  setHighlightedRow: (row: Row | null) => void;
  setRows: (rows: RowID[]) => void;
  highlightedRow: Row | null;
  setHighlightedRowIndex: (idx: number) => void;
};

type RowHighlightHook = {
  highlightedRow: Row | null;
  highlightNextRow: () => void;
  highlightPrevRow: () => void;
  hasNextRow: boolean;
  hasPrevRow: boolean;
  setHighlightedRowElement: (rowId: RowID | null) => void;
  getInternals: () => RowHighlightInternals;
};

export const useRowHighlight: () => RowHighlightHook = () => {
  const [highlightedRowIndex, setHighlightedRowIndex] = useState<number>(-1);

  const [highlightedRow, setHighlightedRow] = useState<Row | null>(null);

  const highlightNextRow = () =>
    setHighlightedRowIndex(highlightedRowIndex + 1);
  const highlightPrevRow = () =>
    setHighlightedRowIndex(highlightedRowIndex - 1);

  const rowsRef = useRef<RowID[]>([]);

  const setHighlightedRowElement = useCallback(rowId => {
    if (rowId === null) {
      setHighlightedRowIndex(-1);
    } else {
      const idx = rowsRef.current.findIndex(
        r => rowId.id === r.id && rowId.entityType === r.entityType
      );
      setHighlightedRowIndex(idx);
    }
  }, []);

  return {
    highlightedRow,
    setHighlightedRowElement,
    highlightNextRow,
    highlightPrevRow,
    hasNextRow: highlightedRowIndex < rowsRef.current.length - 1,
    hasPrevRow: highlightedRowIndex > 0,
    getInternals: () => ({
      highlightedRowIndex,
      setHighlightedRow,
      setRows: rows => (rowsRef.current = rows),
      highlightedRow,
      setHighlightedRowIndex,
    }),
  };
};
