import { camelizeKeys } from 'humps';

import body from 'utils/api/lib/body';

import { getRequest, postRequestV2 } from '../../methods';

const getMetrics = () => getRequest('metrics');

const environment = process.env.NODE_ENV || 'production';

// in dev environment use custom auth token to get an analytics / tableau token
// to test this in dev, use a token from demo.workpath.com
const DEMO_TOKEN = 'saX8UDmJkA99crHoionEKHH6';

const createAnalyticsToken = payload => {
  if (environment === 'development') {
    return fetch('https://api.workpath.com/v2/analytics/tokens', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Token token="${DEMO_TOKEN}"`,
        'x-account': 'demo',
      },
      body: body(payload || {}),
    })
      .then(res => res.json())
      .then(camelizeKeys);
  }

  return postRequestV2('analytics/tokens', payload || {});
};

const metrics = {
  getMetrics,
  createAnalyticsToken,
};

export default metrics;
