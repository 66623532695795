import React from 'react';
import styled from 'styled-components';

import { Icons } from '../../../Icons';
import { getColor } from '../../../styles/themeGetters';
import { Tooltip } from '../../../Tooltip';

type ClearButtonProps = {
  $align?: string;
};

const ClearButton = styled.button<ClearButtonProps>`
  border: none;
  border-radius: 4px;
  background: none;
  padding: 0;
  align-self: ${props => props.$align || 'baseline'};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: ${getColor('grey-light-50')};
  }
`;

type Props = {
  onClick: () => void;
  disabled?: boolean;
  align?: string;
  tooltipContent?: string;
};

const InputFieldClearButton = ({
  onClick,
  disabled,
  align,
  tooltipContent = 'Clear all',
}: Props) => (
  <Tooltip tooltipContent={tooltipContent}>
    <ClearButton
      onClick={onClick}
      disabled={disabled}
      type="button"
      $align={align}
    >
      <Icons.Close />
    </ClearButton>
  </Tooltip>
);

export { InputFieldClearButton };
