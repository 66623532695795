import React from 'react';

import { FontSizeKeys } from '../styles/themes';
import { ProgressBar } from '../ProgressBar';
import { Box } from '../Box';
import { Text } from '../Text';

type ProgressIndicator = {
  value?: number;
  fontSize?: FontSizeKeys;
  disabled?: boolean;
};

// TODO: replace the ${Math.round(value * 100)} with locale-dependent value formatting
const ProgressIndicator = ({
  value = 0,
  fontSize = 12,
  disabled,
}: ProgressIndicator) => (
  <Box display="inline-flex" alignItems="center">
    <ProgressBar value={disabled ? 0 : value} />
    <Box flex="0 0 auto">
      <Text fontSize={fontSize} color="grey-dark" ml={2}>
        {disabled ? '-' : `${Math.round(value * 100)}%`}
      </Text>
    </Box>
  </Box>
);

export { ProgressIndicator };
