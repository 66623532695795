// Docs: https://github.com/WorkpathHQ/workpath_web/wiki/Container-Saga-Docs
import { call, put, all, takeLatest } from 'redux-saga/effects';

import api from 'utils/api';

import { GET_GOAL_PROGRESS_OVER_TIME_REQUEST } from './constants';
import {
  getGoalProgressOverTimeSuccess,
  getGoalProgressOverTimeError,
} from './actions';

export function* getGoalProgressOverTime({ payload }) {
  try {
    const response = yield call(api.getGoalProgress, payload.goalId);

    yield put(getGoalProgressOverTimeSuccess(response));
  } catch (err) {
    yield put(getGoalProgressOverTimeError(err));
  }
}

export function* watcher() {
  yield all([
    takeLatest(GET_GOAL_PROGRESS_OVER_TIME_REQUEST, getGoalProgressOverTime),
  ]);
}

export default [watcher];
