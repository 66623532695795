import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import styled, { css } from 'styled-components';

import { marginMixin, MarginProps } from '../utils/mixins';
import {
  getFontSize,
  getLineHeight,
  getFontFamily,
  getColor,
  getFontWeight,
} from '../styles/themeGetters';
import { ColorKeys, FontSizeKeys, FontWeightKeys } from '../styles/themes';

type Color = ColorKeys | 'inherit';
type FontSize = FontSizeKeys | 'inherit';
type HTMLTags = keyof JSX.IntrinsicElements;
type TextProps = MarginProps & {
  as?: HTMLTags;
  children: React.ReactNode;
  color?: Color;
  display?: string;
  fontSize?: FontSize;
  fontWeight?: FontWeightKeys;
  maxWidth?: string;
  minWidth?: string;
  textAlign?: 'left' | 'center' | 'right';
  truncated?: boolean;
  role?: string;
};

type StyledTextProps = TextProps & {
  $color?: Color;
  $display?: string;
  $fontSize?: FontSize;
  $fontWeight?: FontWeightKeys;
};

const truncatedCss = css`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledText = styled.p<StyledTextProps>`
  color: ${({ $color }) =>
    $color === 'inherit' ? 'inherit' : $color && getColor($color)};
  display: ${({ $display }) => $display};
  line-height: ${({ $fontSize }) =>
    $fontSize === 'inherit'
      ? 'inherit'
      : $fontSize !== undefined && getLineHeight($fontSize)};
  font-family: ${getFontFamily('lato')};
  font-size: ${({ $fontSize }) =>
    $fontSize === 'inherit'
      ? 'inherit'
      : $fontSize !== undefined && getFontSize($fontSize)};
  font-weight: ${({ $fontWeight }) =>
    $fontWeight !== undefined && getFontWeight($fontWeight)};

  /* Margins */
  ${marginMixin}

  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  text-align: ${({ textAlign }) => textAlign};

  ${({ truncated }) => truncated && truncatedCss};
  /* Debug style */

  /* outline: 1px solid red; */
`;

const InnerText: ForwardRefRenderFunction<HTMLParagraphElement, TextProps> = (
  {
    as = 'p',
    children,
    color = 'black',
    display = '',
    fontSize = 16,
    fontWeight = 'regular',
    maxWidth = '',
    minWidth = '',
    textAlign = 'left',
    truncated = false,
    mb = 0,
    ml = 0,
    mr = 0,
    mt = 0,
    ...rest
  },
  ref
) => (
  <StyledText
    $color={color}
    $fontSize={fontSize}
    $fontWeight={fontWeight}
    textAlign={textAlign}
    as={as}
    $display={display}
    maxWidth={maxWidth}
    minWidth={minWidth}
    truncated={truncated}
    mb={mb}
    ml={ml}
    mr={mr}
    mt={mt}
    {...rest}
    ref={ref}
  >
    {children}
  </StyledText>
);

const Text = forwardRef(InnerText);

export { Text };
