import React, { useRef } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { GoalTagType } from 'types/GoalTag';
import { doNotPropagate } from 'utils/doNotPropagate';

import { Box } from '../../../Box';
import { MenuBody, MenuBodyContent, Menu } from '../../../Menu';
import { Text } from '../../../Text';
import { Tooltip } from '../../../Tooltip';
import messages from '../messages';

const options: {
  label: MessageDescriptor;
  tooltipContent: MessageDescriptor;
  value: GoalTagType;
}[] = [
  {
    value: 'customer',
    label: messages.customer,
    tooltipContent: messages.customerTooltip,
  },
  {
    value: 'value',
    label: messages.value,
    tooltipContent: messages.valueTooltip,
  },
  {
    value: 'future_state',
    label: messages.futureState,
    tooltipContent: messages.futureStateTooltip,
  },
];

type Props = {
  selectedType?: GoalTagType;
  handleSelectType: (type: GoalTagType) => void;
};

// Renders a list of all goal tag types to select the type of either a new or an existing goal tag
export const SelectTypeMenuBody = ({
  selectedType,
  handleSelectType,
}: Props) => {
  const { formatMessage } = useIntl();

  const menuBodyRef = useRef(null);

  return (
    <MenuBody position="absolute" left={-30} ref={menuBodyRef}>
      <MenuBodyContent>
        <Box minWidth="216px">
          <Box pt={2} pb={2} pl={4} pr={4} height="36px">
            <Text fontSize={16} fontWeight="bold">
              {formatMessage(messages.tagSelectionAs)}
            </Text>
          </Box>

          {options.map(option => (
            <Tooltip
              key={option.value}
              tooltipContent={formatMessage(option.tooltipContent)}
              tooltipContentTextAlign="left"
              placement="right"
              minWidth="180px"
              maxWidth="300px"
            >
              <Menu.Item
                onSelect={doNotPropagate(() => handleSelectType(option.value))}
                aria-label={`${option.value} menu item`}
                disabled={selectedType === option.value}
                isSelected={selectedType === option.value}
              >
                {formatMessage(option.label)}
              </Menu.Item>
            </Tooltip>
          ))}
        </Box>
      </MenuBodyContent>
    </MenuBody>
  );
};
