import {
  AlignedGoal,
  Goal,
  GoalGroup,
  Initiative,
  KeyResult,
  Row,
} from '../../types';
import { FilterFunctionProps, Matcher } from './types';

function hasSubRows(
  row: GoalGroup | Goal | KeyResult | Initiative | AlignedGoal
): row is GoalGroup | Goal | KeyResult {
  return 'subRows' in row;
}

export const makeFilter: (
  matcher: Matcher,
  props: FilterFunctionProps
) => (rows: Row[]) => Row[] = (matcher, props) => {
  const recursiveMatcher: Matcher = (row, props) => {
    if (hasSubRows(row)) {
      if (matcher(row, props)) {
        return true;
      }
      return row.subRows.some(r => recursiveMatcher(r, props));
    }
    return matcher(row, props);
  };

  const filterDepthFirst = (rows: Row[]) => {
    return rows.reduce((prev, row) => {
      if (hasSubRows(row)) {
        if (recursiveMatcher(row, props)) {
          return [...prev, { ...row, subRows: filterDepthFirst(row.subRows) }];
        }

        return prev;
      }

      return matcher(row, props) ? [...prev, row] : prev;
    }, [] as Row[]);
  };
  return filterDepthFirst;
};
