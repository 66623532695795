import React from 'react';

import { Box } from '../Box';
import { Text } from '../Text';
import { Icons } from '../Icons';

type InitiativeStatusIndicator = {
  value: 'in_progress' | 'not_started' | 'in_danger' | 'done' | 'failed';
  showLabel: boolean;
  label: string;
};

const icons = {
  not_started: <Icons.InProgress color="grey" noPadding size="16px" />,
  in_progress: <Icons.InProgress color="black" noPadding size="16px" />,
  in_danger: <Icons.Danger color="orange" noPadding size="16px" />,
  done: <Icons.Done color="green" noPadding size="16px" />,
  failed: <Icons.Failed color="red" noPadding size="16px" />,
};

const InitiativeStatusIndicator = ({
  value,
  showLabel = false,
  label,
}: InitiativeStatusIndicator) => (
  <Box display="inline-flex" alignItems="center">
    {icons[value]}
    <Text fontSize={14} color="black" ml={2}>
      {showLabel && label}
    </Text>
  </Box>
);

export { InitiativeStatusIndicator };
