import React, { forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import {
  getBaseUnit,
  getColor,
  getFontFamily,
  getFontSize,
  getLineHeight,
} from '../../styles/themeGetters';

type CommonProps = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'disabled' | 'onChange' | 'name' | 'accept'
>;

const StyledInput = styled.input`
  font-family: ${getFontFamily('lato')};
  font-size: ${getFontSize(16)};
  color: ${getColor('navy')};
  line-height: ${getLineHeight(20)};

  ::file-selector-button {
    font-family: ${getFontFamily('lato')};
    font-size: ${getFontSize(16)};
    color: ${getColor('navy')};
    line-height: ${getLineHeight(20)};
    border: 1px solid ${getColor('grey-light-100')};
    background-color: ${getColor('white')};
    padding: ${getBaseUnit(1.5)} ${getBaseUnit(4)};
    margin-right: ${getBaseUnit(4)};

    :hover {
      background-color: ${getColor('grey-light-100')};
      cursor: pointer;
    }
  }
`;

type InputFileProps = CommonProps;

export const InputFileField = forwardRef<HTMLInputElement, InputFileProps>(
  // eslint-disable-next-line react/prop-types -- eslint can't handle the forwardRef here
  ({ disabled, onChange, name, accept }, ref) => {
    return (
      <StyledInput
        id={name}
        disabled={disabled}
        onChange={onChange}
        name={name}
        accept={accept}
        type="file"
        ref={ref}
      />
    );
  }
);
