import React, { ReactNode, useContext } from 'react';
import styled from 'styled-components';

import { getBaseUnit } from '../../styles/themeGetters';
import { TabContext } from '../context/TabContext';

type TabListProps = {
  children: ReactNode;
  mb?: number;
};

type StyledTabListProps = {
  mb?: number;
};

const StyledTabList = styled.div<StyledTabListProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: ${getBaseUnit(6)};
  margin-bottom: ${props => props.mb !== undefined && getBaseUnit(props.mb)};
`;

const TabList = ({ mb, children }: TabListProps) => {
  const { focusedTab, setFocusedTab } = useContext(TabContext);
  const length = React.Children.count(children);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    // Jump to next tab. If we reach the end jump to first tab.
    if (event.key === 'ArrowRight' && focusedTab !== undefined) {
      const nextFocus = focusedTab + 1;

      if (nextFocus >= length) {
        setFocusedTab(0);
      } else {
        setFocusedTab(nextFocus);
      }
    }

    // Jump to prev tab. If we reach the beginning jump to last tab.
    if (event.key === 'ArrowLeft' && focusedTab !== undefined) {
      const nextFocus = focusedTab - 1;

      if (nextFocus < 0) {
        setFocusedTab(length - 1);
      } else {
        setFocusedTab(nextFocus);
      }
    }
  };

  return (
    <StyledTabList
      role="tablist"
      aria-label="Sample Tabs"
      onKeyDown={handleKeyPress}
      mb={mb}
    >
      {React.Children.map<ReactNode, ReactNode>(
        children,
        (child, currentIndex) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              currentIndex,
            } as React.HTMLAttributes<HTMLElement>);
          }
        }
      )}
    </StyledTabList>
  );
};

TabList.defaultProps = {
  mb: 8,
};

export { TabList };
