import React from 'react';

import { Avatar } from '../../../Avatar';
import { RendererProps } from '../RendererProps';
import { Skeleton } from '../../../Skeleton';
import { LinkRenderer } from '../../common/LinkRenderer';
import { TD } from '../../../Table';

export const TeamRenderer = (props: RendererProps<'team'>) => {
  if (
    props.loadingState === 'loading' ||
    // @ts-ignore  PL-2222
    props.cell?.entityType === 'team_loading_cell'
  )
    return (
      <TD width={28} pl={16} pr={16}>
        <Skeleton borderRadius="10px" width="28px" height="28px" />
      </TD>
    );
  const team = props.cell;
  return (
    <TD width={28} pl={16} pr={16} aria-label="cell team">
      <LinkRenderer url={team.url}>
        <Avatar fallbackText={team.name} size="s" type="team" />
      </LinkRenderer>
    </TD>
  );
};
