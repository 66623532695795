import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import styled from 'styled-components';

import * as Theme from '../../styles/themeGetters';
import { Avatar } from '../../Avatar';
import { Suggestion } from './types';

const DropdownMenu = styled.div`
  background: ${Theme.getColor('white')};
  border: 1px solid ${Theme.getColor('grey-light-100')};
  border-radius: 4px;
  box-shadow: 0 0 0 0 rgb(16 16 16 / 4%), 0 8px 32px 0 rgb(32 32 32 / 8%);
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 8px 0;
  position: relative;
  min-width: 216px;
`;

const DropdownMenuButton = styled.button<{ isSelected: boolean }>`
  align-items: center;
  background-color: ${({ isSelected }) =>
    isSelected ? Theme.getColor('grey-light-50') : 'transparent'};
  display: flex;
  text-align: left;
  width: 100%;
  border: none;
  color: ${Theme.getColor('black')};
  font-size: 16px;
  padding: 6px 12px 6px 16px;
  cursor: pointer;
  gap: ${Theme.getBaseUnit(2)};

  &:hover {
    background-color: ${Theme.getColor('grey-light-50')};
  }
`;

const StatusMessage = styled.span`
  color: ${Theme.getColor('black')};
  font-size: 16px;
  padding: 6px 12px 6px 16px;
`;

type MentionListProps = {
  query: string;
  items: Suggestion[];
  isLoading: boolean;
  loadingMessage: string;
  noMatchesMessage: string;
  command: (value: { id: number; label: string }) => void;
};

type MentionListElement = {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean;
};

export const MentionList = forwardRef<MentionListElement, MentionListProps>(
  (
    {
      items,
      command: selectSuggestion,
      loadingMessage,
      isLoading,
      noMatchesMessage,
      query,
    },
    ref
  ) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const selectItem = (index: number) => {
      const item = items[index];
      if (item) {
        selectSuggestion({ id: item.id, label: item.fullName });
      }
    };

    // select first mention from the list
    useEffect(() => {
      if (!items.length) return;
      setSelectedIndex(0);
    }, [items.length]);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        if (event.key === 'ArrowUp') {
          setSelectedIndex((selectedIndex + items.length - 1) % items.length);
          return true;
        }

        if (event.key === 'ArrowDown') {
          setSelectedIndex((selectedIndex + 1) % items.length);
          return true;
        }

        if (event.key === 'Enter') {
          selectItem(selectedIndex);
          return true;
        }

        return false;
      },
    }));

    if (!query) {
      return null;
    }

    return (
      <DropdownMenu>
        {isLoading ? (
          <StatusMessage>{loadingMessage}</StatusMessage>
        ) : items.length === 0 ? (
          <StatusMessage>{noMatchesMessage}</StatusMessage>
        ) : (
          items.map((item, index) => (
            <DropdownMenuButton
              key={index}
              isSelected={index === selectedIndex}
              onClick={() => selectItem(index)}
            >
              <Avatar
                type="person"
                size="s"
                image={item.avatarUrl}
                fallbackText={item.fullName}
              />
              {item.fullName}
            </DropdownMenuButton>
          ))
        )}
      </DropdownMenu>
    );
  }
);
