// These are the pages you can go to.
// They are all wrapped in the App component, which should contain the navbar etc
// See http://blog.mxstbr.com/2016/01/react-apps-with-pages for more information
// about the code splitting business
import React from 'react';

import { handleAppUpdate } from 'utils/handleAppUpdate';
import { getAsyncInjectors } from 'utils/asyncInjectors';
import auth from 'utils/auth';
import { ErrorState } from 'containers/App';
import appSagas from 'containers/App/sagas';
import confirmationReminderContainerSagas from 'containers/ConfirmationReminderContainer/sagas';
import profileSearchSagas from 'containers/ProfileInput/sagas';
import navBarSagas from 'containers/NavBarContainer/sagas';
import teamListSagas from 'containers/NestedTeamList/sagas';
import goalProgressOverTimeModalSagas from 'containers/GoalProgressOverTimeModal/sagas';
import keyResultProgressOverTimeModalSagas from 'containers/KeyResultProgressOverTimeModal/sagas';

const errorLoading = cb => err => {
  console.error('Dynamic page loading failed', err); // eslint-disable-line no-console
  cb(null, () => <ErrorState javascriptError={err} />);
};

const loadModule = cb => componentModule => {
  cb(null, componentModule.default);
};

export default function createRoutes(store) {
  // Create reusable async injectors using getAsyncInjectors factory
  const { injectReducer, injectSagas } = getAsyncInjectors(store); // eslint-disable-line no-unused-vars

  injectSagas(appSagas);
  injectSagas(confirmationReminderContainerSagas);
  injectSagas(profileSearchSagas);
  injectSagas(navBarSagas);
  injectSagas(teamListSagas);
  injectSagas(goalProgressOverTimeModalSagas);
  injectSagas(keyResultProgressOverTimeModalSagas);

  return [
    {
      path: '/',
      onEnter: (nextState, replace) => {
        if (auth.isLoggedIn()) {
          replace('/goals');
        } else {
          replace('/login');
        }
      },
    },
    {
      path: '/styleguide',
      getComponent(location, cb) {
        handleAppUpdate(() => import('pages/StyleguidePage'))
          .then(loadModule(cb))
          .catch(errorLoading(cb));
      },
    },
    {
      path: '/register',
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/RegisterPage'))
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },
    {
      path: '/login',
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/LoginPage'))
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },
    {
      path: '/forgot',
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/ForgotPasswordPage'))
          .then(component => renderRoute(component))
          .catch(errorLoading(cb));
      },
    },
    {
      path: '/reset/:token',
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/ResetPasswordPage'))
          .then(component => renderRoute(component))
          .catch(errorLoading(cb));
      },
    },
    {
      path: '/invite/:token',
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/AcceptInvitationPage'))
          .then(component => renderRoute(component))
          .catch(errorLoading(cb));
      },
    },
    {
      path: '/integrations/planner/sign_in',
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/PlannerAuthenticationRedirectPage'))
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },
    {
      path: '/goals',
      indexRoute: {
        onEnter: (nextState, replace) => replace('/goals/overview/me'),
      },
      childRoutes: [
        {
          path: '/goals/overview/me',
          onEnter(nextState, replace, cb) {
            auth.requireAuth(nextState, replace);

            const importModules = Promise.all([
              handleAppUpdate(() => import('containers/GoalLayout/sagas')),
            ]);

            importModules.then(importedSagas => {
              if (this.loadedSagas) {
                cb();
                return;
              }

              this.loadedSagas = importedSagas.reduce(
                (loadedSagas, importedSaga) =>
                  loadedSagas.concat(injectSagas(importedSaga.default)),
                []
              );
              cb();
            });

            importModules.catch(errorLoading(cb));
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/GoalOverviewMePage')),
              handleAppUpdate(() => import('containers/GoalLayout/reducer')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component, goalLayoutReducer]) => {
              injectReducer('goalLayout', goalLayoutReducer.default);
              renderRoute(component);
            });

            importModules.catch(errorLoading);
          },
        },
        {
          path: '/goals/overview/me_v2',
          onEnter(nextState, replace, cb) {
            auth.requireAuth(nextState, replace);

            const importModules = Promise.all([
              handleAppUpdate(() => import('containers/GoalLayout/sagas')),
            ]);

            importModules.then(importedSagas => {
              if (this.loadedSagas) {
                cb();
                return;
              }

              this.loadedSagas = importedSagas.reduce(
                (loadedSagas, importedSaga) =>
                  loadedSagas.concat(injectSagas(importedSaga.default)),
                []
              );
              cb();
            });

            importModules.catch(errorLoading(cb));
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/GoalOverviewMeNewPage')),
              handleAppUpdate(() => import('containers/GoalLayout/reducer')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component, goalLayoutReducer]) => {
              injectReducer('goalLayout', goalLayoutReducer.default);
              renderRoute(component);
            });

            importModules.catch(errorLoading);
          },
        },
        {
          path: '/goals/overview/teams/:teamId',
          onEnter(nextState, replace, cb) {
            auth.requireAuth(nextState, replace);

            const importModules = Promise.all([
              handleAppUpdate(() => import('containers/GoalLayout/sagas')),
            ]);

            importModules.then(importedSagas => {
              if (this.loadedSagas) {
                cb();
                return;
              }

              this.loadedSagas = importedSagas.reduce(
                (loadedSagas, importedSaga) =>
                  loadedSagas.concat(injectSagas(importedSaga.default)),
                []
              );
              cb();
            });

            importModules.catch(errorLoading(cb));
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/GoalsOverviewTeamPage')),
              handleAppUpdate(() => import('containers/GoalLayout/reducer')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component, goalLayoutReducer]) => {
              injectReducer('goalLayout', goalLayoutReducer.default);
              renderRoute(component);
            });

            importModules.catch(errorLoading(cb));
          },
        },
        {
          path: '/goals/overview/business_units/:businessUnitId',
          onEnter(nextState, replace, cb) {
            auth.requireAuth(nextState, replace);

            const importModules = Promise.all([
              handleAppUpdate(() => import('containers/GoalLayout/sagas')),
            ]);

            importModules.then(importedSagas => {
              if (this.loadedSagas) {
                cb();
                return;
              }

              this.loadedSagas = importedSagas.reduce(
                (loadedSagas, importedSaga) =>
                  loadedSagas.concat(injectSagas(importedSaga.default)),
                []
              );
              cb();
            });

            importModules.catch(errorLoading(cb));
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() =>
                import('pages/GoalsOverviewBusinessUnitPage')
              ),
              handleAppUpdate(() => import('containers/GoalLayout/reducer')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component, goalLayoutReducer]) => {
              injectReducer('goalLayout', goalLayoutReducer.default);
              renderRoute(component);
            });

            importModules.catch(errorLoading(cb));
          },
        },
        {
          path: '/goals/overview/organization',
          onEnter(nextState, replace, cb) {
            auth.requireAuth(nextState, replace);

            const importModules = Promise.all([
              handleAppUpdate(() => import('containers/GoalLayout/sagas')),
            ]);

            importModules.then(importedSagas => {
              if (this.loadedSagas) {
                cb();
                return;
              }

              this.loadedSagas = importedSagas.reduce(
                (loadedSagas, importedSaga) =>
                  loadedSagas.concat(injectSagas(importedSaga.default)),
                []
              );
              cb();
            });

            importModules.catch(errorLoading(cb));
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() =>
                import('pages/GoalsOverviewOrganizationPage')
              ),
              handleAppUpdate(() => import('containers/GoalLayout/reducer')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component, goalLayoutReducer]) => {
              injectReducer('goalLayout', goalLayoutReducer.default);
              renderRoute(component);
            });

            importModules.catch(errorLoading(cb));
          },
        },
        {
          path: '/goals/overview/filter/:customGoalFilterId',
          onEnter(nextState, replace, cb) {
            auth.requireAuth(nextState, replace);

            const importModules = Promise.all([
              handleAppUpdate(() => import('containers/GoalLayout/sagas')),
            ]);

            importModules.then(importedSagas => {
              if (this.loadedSagas) {
                cb();
                return;
              }

              this.loadedSagas = importedSagas.reduce(
                (loadedSagas, importedSaga) =>
                  loadedSagas.concat(injectSagas(importedSaga.default)),
                []
              );
              cb();
            });

            importModules.catch(errorLoading(cb));
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/GoalsOverviewFilterPage')),
              handleAppUpdate(() => import('containers/GoalLayout/reducer')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component, goalLayoutReducer]) => {
              injectReducer('goalLayout', goalLayoutReducer.default);
              renderRoute(component);
            });

            importModules.catch(errorLoading(cb));
          },
        },
        {
          path: '/goals/explorer',
          onEnter(nextState, replace, cb) {
            auth.requireAuth(nextState, replace);

            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/GoalExplorerPage/sagas')),
              handleAppUpdate(() => import('containers/GoalLayout/sagas')),
            ]);

            importModules.then(importedSagas => {
              if (this.loadedSagas) {
                cb();
                return;
              }

              this.loadedSagas = importedSagas.reduce(
                (loadedSagas, importedSaga) =>
                  loadedSagas.concat(injectSagas(importedSaga.default)),
                []
              );
              cb();
            });

            importModules.catch(errorLoading(cb));
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/GoalExplorerPage')),
              handleAppUpdate(() => import('pages/GoalExplorerPage/reducer')),
              handleAppUpdate(() => import('containers/GoalLayout/reducer')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component, reducer, goalLayoutReducer]) => {
              injectReducer('goalExplorerPage', reducer.default);
              injectReducer('goalLayout', goalLayoutReducer.default);
              renderRoute(component);
            });

            importModules.catch(errorLoading(cb));
          },
        },
        {
          path: '/goals/download_export',
          onEnter(nextState, replace, cb) {
            auth.requireAuth(nextState, replace);

            const importModules = Promise.all([
              handleAppUpdate(() => import('containers/GoalLayout/sagas')),
            ]);

            importModules.then(importedSagas => {
              if (this.loadedSagas) {
                cb();
                return;
              }

              this.loadedSagas = importedSagas.reduce(
                (loadedSagas, importedSaga) =>
                  loadedSagas.concat(injectSagas(importedSaga.default)),
                []
              );
              cb();
            });

            importModules.catch(errorLoading);
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/DownloadExportPage')),
              handleAppUpdate(() => import('containers/GoalLayout/reducer')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component, goalLayoutReducer]) => {
              injectReducer('goalLayout', goalLayoutReducer.default);
              renderRoute(component);
            });

            importModules.catch(errorLoading);
          },
        },
        {
          path: '/goals/graph',
          onEnter(nextState, replace) {
            auth.requireAuth(nextState, replace);
          },
          getComponent(nextState, cb) {
            handleAppUpdate(() => import('pages/GoalGraphOverlay'))
              .then(loadModule(cb))
              .catch(errorLoading);
          },
        },
        {
          path: '/goals/drafting-module',
          onEnter: auth.requireAuth,
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() => import('pages/OKRDraftingPage'))
              .then(component => renderRoute(component))
              .catch(errorLoading);
          },
        },
        {
          path: '/goals/:goalId',
          onEnter(nextState, replace, cb) {
            auth.requireAuth(nextState, replace);

            const importModules = Promise.all([
              handleAppUpdate(() => import('containers/GoalLayout/sagas')),
            ]);

            importModules.then(importedSagas => {
              if (this.loadedSagas) {
                cb();
                return;
              }

              this.loadedSagas = importedSagas.reduce(
                (loadedSagas, importedSaga) =>
                  loadedSagas.concat(injectSagas(importedSaga.default)),
                []
              );
              cb();
            });

            importModules.catch(errorLoading(cb));
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/GoalPage')),
              handleAppUpdate(() => import('containers/GoalLayout/reducer')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component, goalLayoutReducer]) => {
              injectReducer('goalLayout', goalLayoutReducer.default);
              renderRoute(component);
            });

            importModules.catch(errorLoading(cb));
          },
        },
        {
          path: '/goals/:goalId/key_results/new',
          onEnter(nextState, replace) {
            auth.requireAuth(nextState, replace);
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/CreateKeyResultOverlay')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component]) => {
              renderRoute(component);
            });

            importModules.catch(errorLoading(cb));
          },
        },
        {
          path: '/goals/:goalId/initiatives/new',
          onEnter: auth.requireAuth,
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() => import('pages/CreateInitiativeOverlay/'))
              .then(component => renderRoute(component))
              .catch(errorLoading);
          },
        },
        {
          path: '/goals/:goalId/initiatives/:initiativeId/edit',
          onEnter(nextState, replace) {
            auth.requireAuth(nextState, replace);
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/EditInitiativeOverlay')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component]) => {
              renderRoute(component);
            });

            importModules.catch(errorLoading(cb));
          },
        },
        {
          path: '/goals/:goalId/initiatives/:initiativeId/status_updates/new',
          onEnter(nextState, replace) {
            auth.requireAuth(nextState, replace);
          },
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() =>
              import('pages/CreateInitiativeStatusUpdateOverlay')
            )
              .then(component => renderRoute(component))
              .catch(errorLoading(cb));
          },
        },
        {
          path: '/goals/:goalId/relationships',
          onEnter(nextState, replace) {
            auth.requireAuth(nextState, replace);
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/EditRelationshipsOverlay')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component]) => {
              renderRoute(component);
            });

            importModules.catch(errorLoading(cb));
          },
        },
        {
          // TODO: Deprecated check_in route; remove when not used by users any more
          path: '/goals/:goalId/check_in',
          onEnter: (nextState, replace) => {
            auth.requireAuth(nextState, replace);

            const { goalId } = nextState.params;
            replace(`/goals/${goalId}/status_updates/new`);
          },
        },
        {
          path: '/goals/:goalId/status_updates/new',
          onEnter(nextState, replace) {
            auth.requireAuth(nextState, replace);
          },
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() => import('pages/CreateStatusUpdateOverlay'))
              .then(component => renderRoute(component))
              .catch(errorLoading(cb));
          },
        },
        {
          path: '/goals/:goalId/key_results/:keyResultId/edit',
          onEnter(nextState, replace) {
            auth.requireAuth(nextState, replace);
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/EditKeyResultOverlay')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component]) => {
              renderRoute(component);
            });

            importModules.catch(errorLoading(cb));
          },
        },
      ],
    },
    {
      path: '/check-ins',
      indexRoute: {
        onEnter: (nextState, replace) => replace('/check-ins/upcoming'),
      },
      childRoutes: [
        {
          path: '/check-ins/:filter',
          onEnter(nextState, replace, cb) {
            auth.requireAuth(nextState, replace);

            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/CheckInsOverviewPage/sagas')),
              handleAppUpdate(() => import('containers/CheckInsLayout/sagas')),
            ]);

            importModules.then(importedSagas => {
              if (this.loadedSagas) {
                cb();
                return;
              }

              this.loadedSagas = importedSagas.reduce(
                (loadedSagas, importedSaga) =>
                  loadedSagas.concat(injectSagas(importedSaga.default)),
                []
              );

              cb();
            });

            importModules.catch(errorLoading(cb));
          },
          onChange(nextState, replace, cb) {
            if (!this.loadedSagas) {
              const importModules = Promise.all([
                handleAppUpdate(() =>
                  import('pages/CheckInsOverviewPage/sagas')
                ),
                handleAppUpdate(() =>
                  import('containers/CheckInsLayout/sagas')
                ),
              ]);

              importModules.then(importedSagas => {
                if (this.loadedSagas) {
                  cb();
                  return;
                }

                this.loadedSagas = importedSagas.reduce(
                  (loadedSagas, importedSaga) =>
                    loadedSagas.concat(injectSagas(importedSaga.default)),
                  []
                );

                cb();
              });

              importModules.catch(errorLoading(cb));
            }
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/CheckInsOverviewPage')),
              handleAppUpdate(() =>
                import('pages/CheckInsOverviewPage/reducer')
              ),
              handleAppUpdate(() =>
                import('containers/CheckInsLayout/reducer')
              ),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(
              ([
                checkInsOverviewPage,
                checkInsOverviewPageReducer,
                checkInsLayoutReducer,
              ]) => {
                injectReducer(
                  'checkInsOverviewPage',
                  checkInsOverviewPageReducer.default
                );
                injectReducer('checkInsLayout', checkInsLayoutReducer.default);
                renderRoute(checkInsOverviewPage);
              }
            );

            importModules.catch(errorLoading(cb));
          },
        },
        {
          path: '/check-ins/:checkInId/tasks-and-priorities',
          onEnter(nextState, replace, cb) {
            auth.requireAuth(nextState, replace);

            const importModules = Promise.all([
              handleAppUpdate(() => import('containers/CheckInsLayout/sagas')),
            ]);

            importModules.then(importedSagas => {
              if (this.loadedSagas) {
                cb();
                return;
              }

              this.loadedSagas = importedSagas.reduce(
                (loadedSagas, importedSaga) =>
                  loadedSagas.concat(injectSagas(importedSaga.default)),
                []
              );

              cb();
            });

            importModules.catch(errorLoading(cb));
          },
          onChange(nextState, replace, cb) {
            if (!this.loadedSagas) {
              const importModules = Promise.all([
                handleAppUpdate(() =>
                  import('containers/CheckInsLayout/sagas')
                ),
              ]);

              importModules.then(importedSagas => {
                if (this.loadedSagas) {
                  cb();
                  return;
                }

                this.loadedSagas = importedSagas.reduce(
                  (loadedSagas, importedSaga) =>
                    loadedSagas.concat(injectSagas(importedSaga.default)),
                  []
                );

                cb();
              });

              importModules.catch(errorLoading(cb));
            }
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() =>
                import('containers/CheckInsLayout/reducer')
              ),
            ]);

            const renderRoute = loadModule(cb);

            import('pages/CheckInTeamMemberReportsPage')
              .then(component => renderRoute(component))
              .catch(errorLoading);

            importModules.then(([checkInsLayoutReducer]) => {
              injectReducer('checkInsLayout', checkInsLayoutReducer.default);
            });

            importModules.catch(errorLoading(cb));
          },
        },
        {
          path: '/check-ins/:checkInId/tasks-and-priorities/me/edit',
          onEnter(nextState, replace, cb) {
            auth.requireAuth(nextState, replace);

            const importModules = Promise.all([
              handleAppUpdate(() =>
                import('pages/CheckInTeamMemberReportEditPage/sagas')
              ),
              handleAppUpdate(() => import('containers/CheckInsLayout/sagas')),
            ]);

            importModules.then(importedSagas => {
              if (this.loadedSagas) {
                cb();
                return;
              }

              this.loadedSagas = importedSagas.reduce(
                (loadedSagas, importedSaga) =>
                  loadedSagas.concat(injectSagas(importedSaga.default)),
                []
              );

              cb();
            });

            importModules.catch(errorLoading(cb));
          },
          onChange(nextState, replace, cb) {
            if (!this.loadedSagas) {
              const importModules = Promise.all([
                handleAppUpdate(() =>
                  import('pages/CheckInTeamMemberReportEditPage/sagas')
                ),
                handleAppUpdate(() =>
                  import('containers/CheckInsLayout/sagas')
                ),
              ]);

              importModules.then(importedSagas => {
                if (this.loadedSagas) {
                  cb();
                  return;
                }

                this.loadedSagas = importedSagas.reduce(
                  (loadedSagas, importedSaga) =>
                    loadedSagas.concat(injectSagas(importedSaga.default)),
                  []
                );

                cb();
              });

              importModules.catch(errorLoading(cb));
            }
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() =>
                import('pages/CheckInTeamMemberReportEditPage')
              ),
              handleAppUpdate(() =>
                import('pages/CheckInTeamMemberReportEditPage/reducer')
              ),
              handleAppUpdate(() =>
                import('containers/CheckInsLayout/reducer')
              ),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(
              ([
                checkInTeamMemberReportEditPage,
                checkInTeamMemberReportEditPageReducer,
                checkInsLayoutReducer,
              ]) => {
                injectReducer(
                  'checkInTeamMemberReportEditPage',
                  checkInTeamMemberReportEditPageReducer.default
                );
                injectReducer('checkInsLayout', checkInsLayoutReducer.default);
                renderRoute(checkInTeamMemberReportEditPage);
              }
            );

            importModules.catch(errorLoading(cb));
          },
        },
        {
          path: '/check-ins/:checkInId/team-member-reports/:teamMemberReportId/status-updates',
          onEnter(nextState, replace, cb) {
            auth.requireAuth(nextState, replace);

            const importModules = Promise.all([
              handleAppUpdate(() => import('containers/CheckInsLayout/sagas')),
            ]);

            importModules.then(importedSagas => {
              if (this.loadedSagas) {
                cb();
                return;
              }

              this.loadedSagas = importedSagas.reduce(
                (loadedSagas, importedSaga) =>
                  loadedSagas.concat(injectSagas(importedSaga.default)),
                []
              );
              cb();
            });

            importModules.catch(errorLoading(cb));
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() =>
                import('pages/CheckInTeamMemberReportStatusUpdatesPage')
              ),
              handleAppUpdate(() =>
                import('containers/CheckInsLayout/reducer')
              ),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component, checkInsLayoutReducer]) => {
              injectReducer('checkInsLayout', checkInsLayoutReducer.default);
              renderRoute(component);
            });

            importModules.catch(errorLoading(cb));
          },
        },
      ],
    },
    {
      path: '/users/:id',
      onEnter: auth.requireAuth,
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/ProfilePage'))
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },
    {
      path: '/profile',
      onEnter: auth.requireAuth,
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/ProfilePage'))
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },
    {
      path: '/confirmation',
      getComponent(location, cb) {
        handleAppUpdate(() => import('pages/ConfirmationRoute'))
          .then(loadModule(cb))
          .catch(errorLoading(cb));
      },
    },

    {
      path: '/settings/profile',
      onEnter: auth.requireAuth,
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/SettingsProfilePage'))
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },
    {
      path: '/settings/account',
      onEnter: auth.requireAuth,
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/SettingsAccountPage'))
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },
    {
      path: '/settings/users',
      indexRoute: {
        onEnter: auth.requireAuth,
        getComponent(nextState, cb) {
          const renderRoute = loadModule(cb);

          handleAppUpdate(() => import('pages/SettingsUsersPage'))
            .then(component => renderRoute(component))
            .catch(errorLoading);
        },
      },
      childRoutes: [
        {
          path: '/settings/users/download_export',
          onEnter(nextState, replace, cb) {
            auth.requireAuth(nextState, replace);

            const importModules = Promise.all([
              handleAppUpdate(() => import('containers/GoalLayout/sagas')),
            ]);

            importModules.then(importedSagas => {
              if (this.loadedSagas) {
                cb();
                return;
              }

              this.loadedSagas = importedSagas.reduce(
                (loadedSagas, importedSaga) =>
                  loadedSagas.concat(injectSagas(importedSaga.default)),
                []
              );
              cb();
            });

            importModules.catch(errorLoading);
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/DownloadExportPage')),
              handleAppUpdate(() => import('containers/GoalLayout/reducer')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component, goalLayoutReducer]) => {
              injectReducer('goalLayout', goalLayoutReducer.default);
              renderRoute(component);
            });

            importModules.catch(errorLoading);
          },
        },
        {
          path: '/settings/users/:userId',
          onEnter(nextState, replace, cb) {
            auth.requireAuth(nextState, replace);

            const importModules = handleAppUpdate(() =>
              import('pages/SettingsUserPage/sagas')
            );

            importModules.then(sagas => {
              if (this.loadedSagas) {
                cb();
                return;
              }

              this.loadedSagas = injectSagas(sagas.default);
              cb();
            });

            importModules.catch(errorLoading(cb));
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/SettingsUserPage')),
              handleAppUpdate(() => import('pages/SettingsUserPage/reducer')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component, reducer]) => {
              injectReducer('settingsUserPage', reducer.default);
              renderRoute(component);
            });

            importModules.catch(errorLoading(cb));
          },
        },
      ],
    },
    {
      path: '/settings/business_units/new',
      onEnter: auth.requireAuth,
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/SettingsBusinessUnitCreatePage'))
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },
    {
      path: '/settings/business_units/:businessUnitId',
      onEnter: auth.requireAuth,
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/SettingsBusinessUnitPage'))
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },
    {
      path: '/settings/business_units/:businessUnitId/edit',
      onEnter: auth.requireAuth,
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/SettingsBusinessUnitEditPage'))
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },
    {
      path: '/settings/business_units/:businessUnitId/modify_members',
      onEnter: auth.requireAuth,
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() =>
          import('pages/SettingsBusinessUnitModifyMembersPage')
        )
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },
    {
      path: '/settings/teams',
      indexRoute: {
        onEnter(nextState, replace) {
          auth.requireAuth(nextState, replace);
        },
        getComponent(nextState, cb) {
          const importModules = Promise.all([
            handleAppUpdate(() => import('pages/SettingsTeamsPage')),
          ]);

          const renderRoute = loadModule(cb);

          importModules.then(([component]) => {
            renderRoute(component);
          });

          importModules.catch(errorLoading(cb));
        },
      },
      childRoutes: [
        {
          path: '/settings/teams/download_export',
          onEnter(nextState, replace, cb) {
            auth.requireAuth(nextState, replace);

            const importModules = Promise.all([
              handleAppUpdate(() => import('containers/GoalLayout/sagas')),
            ]);

            importModules.then(importedSagas => {
              if (this.loadedSagas) {
                cb();
                return;
              }

              this.loadedSagas = importedSagas.reduce(
                (loadedSagas, importedSaga) =>
                  loadedSagas.concat(injectSagas(importedSaga.default)),
                []
              );
              cb();
            });

            importModules.catch(errorLoading);
          },
          onLeave() {
            if (this.loadedSagas) {
              this.loadedSagas.forEach(saga => saga.cancel());
              delete this.loadedSagas;
            }
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/DownloadExportPage')),
              handleAppUpdate(() => import('containers/GoalLayout/reducer')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component, goalLayoutReducer]) => {
              injectReducer('goalLayout', goalLayoutReducer.default);
              renderRoute(component);
            });

            importModules.catch(errorLoading);
          },
        },
        {
          path: '/settings/teams/new',
          onEnter: auth.requireAuth,
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() => import('pages/SettingsTeamCreatePage'))
              .then(component => renderRoute(component))
              .catch(errorLoading);
          },
        },
        {
          path: '/settings/teams/:teamId',
          onEnter: auth.requireAuth,
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() => import('pages/SettingsTeamPage'))
              .then(component => renderRoute(component))
              .catch(errorLoading);
          },
        },
        {
          path: '/settings/teams/:teamId/edit',
          onEnter: auth.requireAuth,
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() => import('pages/SettingsTeamEditPage'))
              .then(component => renderRoute(component))
              .catch(errorLoading);
          },
        },
      ],
    },
    {
      path: '/settings/cycles',
      indexRoute: {
        onEnter(nextState, replace) {
          auth.requireAuth(nextState, replace);
        },
        getComponent(nextState, cb) {
          const importModules = Promise.all([
            handleAppUpdate(() => import('pages/SettingsCyclesPage')),
          ]);

          const renderRoute = loadModule(cb);

          importModules.then(([component]) => {
            renderRoute(component);
          });

          importModules.catch(errorLoading(cb));
        },
      },
      childRoutes: [
        {
          path: '/settings/cycles/new',
          onEnter(nextState, replace) {
            auth.requireAuth(nextState, replace);
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/SettingsCycleCreatePage')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component]) => {
              renderRoute(component);
            });

            importModules.catch(errorLoading);
          },
        },
        {
          path: '/settings/cycles/:cycleId/edit',
          onEnter(nextState, replace) {
            auth.requireAuth(nextState, replace);
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/SettingsCycleEditPage')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component]) => {
              renderRoute(component);
            });

            importModules.catch(errorLoading);
          },
        },
      ],
    },
    {
      path: '/settings/api_clients',
      indexRoute: {
        onEnter: auth.requireAuth,
        getComponent(nextState, cb) {
          handleAppUpdate(() => import('pages/SettingsApiClientsPage'))
            .then(loadModule(cb))
            .catch(errorLoading(cb));
        },
      },
      childRoutes: [
        {
          path: '/settings/api_clients/new',
          onEnter(nextState, replace) {
            auth.requireAuth(nextState, replace);
          },
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            const importModules = handleAppUpdate(() =>
              import('pages/SettingsApiClientCreatePage')
            );

            importModules
              .then(component => {
                renderRoute(component);
              })
              .catch(errorLoading);
          },
        },
        {
          path: '/settings/api_clients/:apiClientId/edit',
          onEnter(nextState, replace) {
            auth.requireAuth(nextState, replace);
          },
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() => import('pages/SettingsApiClientEditPage'))
              .then(component => renderRoute(component))
              .catch(errorLoading);
          },
        },
      ],
    },
    {
      path: '/sign_in',
      getComponent(location, cb) {
        handleAppUpdate(() => import('pages/ChangePasswordRoute'))
          .then(loadModule(cb))
          .catch(errorLoading(cb));
      },
    },
    {
      path: '/goals/:goalId/stakeholders',
      onEnter(nextState, replace) {
        auth.requireAuth(nextState, replace);
      },
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/GoalCollaboratorsOverlay'))
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },
    {
      path: '/settings/notifications',
      onEnter: auth.requireAuth,
      getComponent(location, cb) {
        handleAppUpdate(() => import('pages/SettingsNotificationsPage'))
          .then(loadModule(cb))
          .catch(errorLoading);
      },
    },
    {
      path: '/single_sign_on',
      onEnter(nextState, replace, cb) {
        const importModules = handleAppUpdate(() =>
          import('pages/SingleSignOnRoute/sagas')
        );

        importModules.then(sagas => {
          if (this.loadedSagas) {
            cb();
            return;
          }

          this.loadedSagas = injectSagas(sagas.default);
          cb();
        });

        importModules.catch(errorLoading(cb));
      },
      onLeave() {
        if (this.loadedSagas) {
          this.loadedSagas.forEach(saga => saga.cancel());
          delete this.loadedSagas;
        }
      },
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          handleAppUpdate(() => import('pages/SingleSignOnRoute')),
          handleAppUpdate(() => import('pages/SingleSignOnRoute/reducer')),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([component, reducer]) => {
          injectReducer('singleSignOnRoute', reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading(cb));
      },
    },
    {
      path: '/settings/labels',
      onEnter: auth.requireAuth,
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/SettingsLabelsPage'))
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },
    {
      path: '/settings/invite/new',
      onEnter(nextState, replace) {
        auth.requireAuth(nextState, replace);
      },
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        const importModules = handleAppUpdate(() =>
          import('pages/SettingsInviteCreatePage')
        );

        importModules
          .then(component => {
            renderRoute(component);
          })
          .catch(errorLoading(cb));
      },
    },
    {
      path: '/settings/custom-goal-filters',
      indexRoute: {
        onEnter: auth.requireAuth,
        getComponent(nextState, cb) {
          handleAppUpdate(() => import('pages/SettingsCustomGoalFiltersPage'))
            .then(loadModule(cb))
            .catch(errorLoading(cb));
        },
      },
      childRoutes: [
        {
          path: '/settings/custom-goal-filters/new',
          onEnter: auth.requireAuth,
          getComponent(location, cb) {
            handleAppUpdate(() =>
              import('pages/SettingsCustomGoalFilterCreatePage')
            )
              .then(loadModule(cb))
              .catch(errorLoading);
          },
        },
        {
          path: '/settings/custom-goal-filters/:customGoalFilterId/edit',
          onEnter: auth.requireAuth,
          getComponent(nextState, cb) {
            handleAppUpdate(() =>
              import('pages/SettingsCustomGoalFilterEditPage')
            )
              .then(loadModule(cb))
              .catch(errorLoading(cb));
          },
        },
      ],
    },
    {
      path: '/analytics',
      indexRoute: {
        onEnter: (nextState, replace) => replace('/analytics/reporting-hub'),
      },
      childRoutes: [
        {
          path: '/analytics/reporting-hub',
          onEnter: auth.requireAuth,
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() => import('pages/AnalyticsReportingHubPage'))
              .then(component => renderRoute(component))
              .catch(errorLoading);
          },
        },
        {
          path: '/analytics/company',
          onEnter(nextState, replace) {
            auth.requireAuth(nextState, replace);
          },
          getComponent(nextState, cb) {
            const importModules = Promise.all([
              handleAppUpdate(() => import('pages/AnalyticsCompanyPage')),
            ]);

            const renderRoute = loadModule(cb);

            importModules.then(([component]) => {
              renderRoute(component);
            });

            importModules.catch(errorLoading(cb));
          },
        },
        {
          path: '/analytics/organization-summary',
          onEnter: auth.requireAuth,
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() =>
              import('pages/AnalyticsOrganizationSummaryReport')
            )
              .then(component => renderRoute(component))
              .catch(errorLoading);
          },
        },
        {
          path: '/analytics/cycle-steering-report',
          onEnter: auth.requireAuth,
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() => import('pages/AnalyticsCycleSteeringReport'))
              .then(component => renderRoute(component))
              .catch(errorLoading);
          },
        },
        {
          path: '/analytics/strategy-execution-report',
          onEnter: auth.requireAuth,
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() =>
              import('pages/AnalyticsStrategyExecutionReport')
            )
              .then(component => renderRoute(component))
              .catch(errorLoading);
          },
        },
        {
          path: '/analytics/maturity-report',
          onEnter: auth.requireAuth,
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() => import('pages/AnalyticsMaturityReport'))
              .then(component => renderRoute(component))
              .catch(errorLoading);
          },
        },
        {
          path: '/analytics/process-insights-report',
          onEnter: auth.requireAuth,
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() =>
              import('pages/AnalyticsProcessInsightsReport')
            )
              .then(component => renderRoute(component))
              .catch(errorLoading);
          },
        },
        {
          path: '/analytics/business-review-report',
          onEnter: auth.requireAuth,
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() => import('pages/AnalyticsBusinessReviewReport'))
              .then(component => renderRoute(component))
              .catch(errorLoading);
          },
        },
        {
          path: '/analytics/management-planning-summary',
          onEnter: auth.requireAuth,
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() =>
              import('pages/AnalyticsManagementPlanningSummary')
            )
              .then(component => renderRoute(component))
              .catch(errorLoading);
          },
        },
        {
          path: '/analytics/management-review-summary',
          onEnter: auth.requireAuth,
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() =>
              import('pages/AnalyticsManagementReviewSummary')
            )
              .then(component => renderRoute(component))
              .catch(errorLoading);
          },
        },
        {
          path: '/analytics/capacity-manager-report',
          onEnter: auth.requireAuth,
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() =>
              import('pages/AnalyticsCapacityManagerReport')
            )
              .then(component => renderRoute(component))
              .catch(errorLoading);
          },
        },
        {
          path: '/analytics/other-reports',
          onEnter: auth.requireAuth,
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() => import('pages/AnalyticsOtherReports'))
              .then(component => renderRoute(component))
              .catch(errorLoading);
          },
        },
      ],
    },
    {
      path: '/settings/integrations',
      indexRoute: {
        onEnter(nextState, replace, cb) {
          auth.requireAuth(nextState, replace);
          cb();
        },
        getComponent(nextState, cb) {
          const importModules = Promise.all([
            handleAppUpdate(() => import('pages/SettingsIntegrationsPage')),
          ]);

          const renderRoute = loadModule(cb);

          importModules.then(([component]) => {
            renderRoute(component);
          });

          importModules.catch(errorLoading(cb));
        },
      },
      childRoutes: [
        {
          path: '/settings/integrations/personio',
          getComponent(nextState, cb) {
            const renderRoute = loadModule(cb);

            handleAppUpdate(() =>
              import('pages/SettingsIntegrationsPersonioPage')
            )
              .then(component => renderRoute(component))
              .catch(errorLoading(cb));
          },
        },
        {
          path: '/settings/integrations/slack',
          onEnter: auth.requireAuth,
          getComponent(location, cb) {
            handleAppUpdate(() => import('pages/SettingsIntegrationsSlackPage'))
              .then(loadModule(cb))
              .catch(errorLoading);
          },
        },
        {
          path: '/settings/integrations/scim',
          onEnter: auth.requireAuth,
          getComponent(location, cb) {
            handleAppUpdate(() => import('pages/SettingsIntegrationsScimPage'))
              .then(loadModule(cb))
              .catch(errorLoading);
          },
        },
        {
          path: '/settings/integrations/saml',
          onEnter: auth.requireAuth,
          getComponent(location, cb) {
            handleAppUpdate(() => import('pages/SettingsIntegrationsSamlPage'))
              .then(loadModule(cb))
              .catch(errorLoading);
          },
        },
        {
          path: '/settings/integrations/planner',
          onEnter: auth.requireAuth,
          getComponent(location, cb) {
            handleAppUpdate(() =>
              import('pages/SettingsIntegrationsPlannerPage')
            )
              .then(loadModule(cb))
              .catch(errorLoading);
          },
        },
        {
          path: '/settings/integrations/jira',
          indexRoute: {
            onEnter: auth.requireAuth,
            getComponent(location, cb) {
              handleAppUpdate(() =>
                import('pages/SettingsIntegrationsJiraPage')
              )
                .then(loadModule(cb))
                .catch(errorLoading);
            },
          },
          childRoutes: [
            {
              path: '/settings/integrations/jira/new',
              onEnter: auth.requireAuth,
              getComponent(nextState, cb) {
                handleAppUpdate(() =>
                  import('pages/SettingsIntegrationsJiraCreatePage')
                )
                  .then(loadModule(cb))
                  .catch(errorLoading());
              },
            },
            {
              path: '/settings/integrations/jira/:jiraIntegrationId/edit',
              onEnter: auth.requireAuth,
              getComponent(nextState, cb) {
                handleAppUpdate(() =>
                  import('pages/SettingsIntegrationsJiraEditPage')
                )
                  .then(loadModule(cb))
                  .catch(errorLoading());
              },
            },
          ],
        },
        {
          path: '/settings/integrations/azure',
          indexRoute: {
            onEnter: auth.requireAuth,
            getComponent(location, cb) {
              handleAppUpdate(() =>
                import('pages/SettingsIntegrationsAzurePage')
              )
                .then(loadModule(cb))
                .catch(errorLoading);
            },
          },
          childRoutes: [
            {
              path: '/settings/integrations/azure/new',
              onEnter: auth.requireAuth,
              getComponent(nextState, cb) {
                handleAppUpdate(() =>
                  import('pages/SettingsIntegrationsAzureCreatePage')
                )
                  .then(loadModule(cb))
                  .catch(errorLoading());
              },
            },
            {
              path: '/settings/integrations/azure/:azureIntegrationId/edit',
              onEnter: auth.requireAuth,
              getComponent(nextState, cb) {
                handleAppUpdate(() =>
                  import('pages/SettingsIntegrationsAzureEditPage')
                )
                  .then(loadModule(cb))
                  .catch(errorLoading());
              },
            },
          ],
        },
        {
          path: '/settings/integrations/powerbi',
          onEnter: auth.requireAuth,
          getComponent(location, cb) {
            handleAppUpdate(() =>
              import('pages/SettingsIntegrationsPowerBIPage')
            )
              .then(loadModule(cb))
              .catch(errorLoading);
          },
        },
      ],
    },

    {
      path: '/teams/:teamId(/goal/:goalId)/contribution_request/create',
      onEnter: auth.requireAuth,
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() =>
          import('pages/TeamsContributionRequestCreatePage')
        )
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },
    {
      path: '/teams/:teamId/contribution_request/:contributionRequestId',
      onEnter: auth.requireAuth,
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/TeamsContributionRequestPage'))
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },

    {
      path: '/conversations',
      onEnter: auth.requireAuth,
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        import('pages/ConversationsPage')
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },

    {
      path: '/conversations/new',
      onEnter: auth.requireAuth,
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        import('pages/CreateConversationOverlay')
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },

    {
      path: '/conversations/:conversationId',
      onEnter: auth.requireAuth,
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/PrepareConversationsPage'))
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },

    {
      path: '/conversations/edit/:conversationId',
      onEnter: auth.requireAuth,
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/EditConversationOverlay'))
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },

    {
      path: '/note/:noteId',
      onEnter: auth.requireAuth,
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/NotesLandingPage'))
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },

    {
      path: '/strategicElements/:strategicElementId',
      onEnter: auth.requireAuth,
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() => import('pages/StrategicElementsPage'))
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },

    {
      path: '/integrations/power_bi/sign_in',
      getComponent(nextState, cb) {
        const renderRoute = loadModule(cb);

        handleAppUpdate(() =>
          import('features/powerBi/MicrosoftAuthenticationRedirectPage')
        )
          .then(component => renderRoute(component))
          .catch(errorLoading);
      },
    },
    // Hygen template lookup, do not remove
    {
      path: '*',
      getComponent(nextState, cb) {
        handleAppUpdate(() => import('pages/NotFoundPage'))
          .then(loadModule(cb))
          .catch(errorLoading(cb));
      },
    },
  ];
}
