import React, { RefObject, useRef } from 'react';

import { GoalTagType } from 'types/GoalTag';

import { Menu } from '../../../Menu';
import { useClickOutside } from '../../../utils/useClickOutside';
import { SelectTypeMenuBody } from './SelectTypeMenuBody';

type Props = {
  anchorEl: RefObject<HTMLElement>;
  open: boolean;
  onClose: () => void;
  selectedType: GoalTagType;
  handleSelectType: (newType: GoalTagType) => void;
};

// Renders a menu to change the type of a selected goal tag
export const ChangeTypeMenu = ({
  anchorEl,
  open,
  onClose,
  selectedType,
  handleSelectType,
}: Props) => {
  const menuRef = useRef(null);

  useClickOutside({
    targetRef: menuRef,
    onClickOutside: onClose,
  });

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
      <SelectTypeMenuBody
        selectedType={selectedType}
        handleSelectType={handleSelectType}
      />
    </Menu>
  );
};
