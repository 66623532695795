import React, { useContext } from 'react';
import styled from 'styled-components';

import { marginMixin, MarginProps } from '../utils/mixins';
import {
  getColor,
  getFontWeight,
  getLineHeight,
  getFontSize,
} from '../styles/themeGetters';
import { ColorKeys, FontSizeKeys } from '../styles/themes';
import { BaukastenContext } from '../BaukastenContext';
import { Icons } from '../Icons';
import { getLinkColor } from './utils';

// This is to get icon sizes based on font-size
const iconSizesfromFontSize = {
  '16': '24px',
  '14': '16px',
  '12': '16px',
};

type FontSize = Extract<FontSizeKeys, 14 | 16>;

type LinkProps = MarginProps & {
  url: string;
  children: React.ReactNode;
  disabled?: boolean;
  fontSize?: FontSize;
  type?: 'primary' | 'secondary';
  target?: '_blank' | '_self' | '_parent' | '_top' | string;
};

type StyledLinkProps = MarginProps & {
  $disabled: boolean;
  $fontSize: FontSize;
  $color: ColorKeys;
};

const StyledLink = styled.a<StyledLinkProps>`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
  font-weight: ${getFontWeight('semibold')};
  font-size: ${({ $fontSize }) => getFontSize($fontSize)};
  line-height: ${({ $fontSize }) => getLineHeight($fontSize)};
  color: ${({ $color }) => getColor($color)};
  ${marginMixin}

  &:hover {
    text-decoration: ${({ $disabled }) => ($disabled ? 'none' : 'underline')};
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'hand')};
  }

  &:focus {
    outline: 2px solid ${getColor('blue-light')};
    outline-offset: 2px;
    border-radius: 4px;
  }
`;

const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
  if (e.ctrlKey || e.shiftKey || e.metaKey) {
    // let the browser take over
  }
};

/**
Link component requires the navigateTo function defined in BaukastenContext.
This function will be used for internal navigation within the app.
i.e When using in workpath-web you need to set this function with push method from react-router
*/
const Link = ({
  url,
  children,
  disabled = false,
  fontSize = 16,
  type = 'primary',
  ...rest
}: LinkProps) => {
  const baukastenContext = useContext(BaukastenContext);

  return (
    <StyledLink
      {...rest}
      href={url}
      $disabled={disabled}
      $fontSize={fontSize}
      $color={getLinkColor(disabled, type)}
      onClick={e => {
        handleClick(e);

        if (baukastenContext.navigateTo) {
          e.preventDefault();
          baukastenContext.navigateTo(url);
        }
      }}
    >
      {children}
    </StyledLink>
  );
};

type ExternalLinkProps = LinkProps & {
  showExternalIcon?: boolean;
  iconPosition?: 'start' | 'end';
};

const ExternalLink = ({
  url,
  children,
  disabled = false,
  fontSize = 16,
  type = 'primary',
  showExternalIcon = true,
  iconPosition = 'end',
  mt = 0,
  mr = 0,
  mb = 0,
  ml = 0,
  ...rest
}: ExternalLinkProps) => (
  <StyledLink
    {...rest}
    href={url}
    $disabled={disabled}
    $fontSize={fontSize}
    $color={getLinkColor(disabled, type)}
    target="_blank"
    rel="noopener noreferrer"
    onClick={handleClick}
    mt={mt}
    mr={mr}
    mb={mb}
    ml={ml}
  >
    {iconPosition === 'start' && showExternalIcon && (
      <Icons.ExternalLink
        color={getLinkColor(disabled, type)}
        size={iconSizesfromFontSize[fontSize]}
      />
    )}
    {children}
    {iconPosition === 'end' && showExternalIcon && (
      <Icons.ExternalLink
        color={getLinkColor(disabled, type)}
        size={iconSizesfromFontSize[fontSize]}
      />
    )}
  </StyledLink>
);

export { Link, ExternalLink };
