import React from 'react';
import { FieldError } from 'react-hook-form';
import styled from 'styled-components';

import { Tooltip } from '../../../Tooltip';
import { Text } from '../../../Text';
import { Icons } from '../../../Icons';
import {
  getBaseUnit,
  getFontSize,
  getFontWeight,
  getLineHeight,
} from '../../../styles/themeGetters';
import { Box } from '../../../Box';

const Label = styled.label`
  display: flex;
  gap: ${getBaseUnit(2)};
  font-size: ${() => getFontSize(16)};
  line-height: ${() => getLineHeight(16)};
  margin-bottom: ${() => getBaseUnit(2)};
  font-weight: ${() => getFontWeight('semibold')};
`;

type FooterProps = {
  error?: FieldError;
  helpText?: string;
  maxLength?: string | number;
  value?: string;
  hasCharacterCounter?: boolean;
  condensed?: boolean;
};

type InputControlProps = {
  children?: React.ReactNode;
  label?: string;
  htmlFor?: string;
  extraHelpText?: string;
} & FooterProps;

const Footer = ({
  error,
  helpText,
  maxLength,
  value,
  condensed,
  hasCharacterCounter,
}: FooterProps) => {
  const showCondensed = !helpText && condensed && !error;

  return (
    <Box
      mt={2}
      minHeight={showCondensed ? '0px' : '24px'}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center">
        {error ? (
          <>
            <Icons.Alert size="24px" color="red" />
            <Box flex="1">
              <Text fontSize={14} ml={1} color="red" role="alert">
                {error.message}
              </Text>
            </Box>
          </>
        ) : null}

        {!error && helpText ? (
          <Text fontSize={14} color="grey-dark">
            {helpText}
          </Text>
        ) : null}
      </Box>

      {hasCharacterCounter && maxLength ? (
        <Box height="24px" display="flex" alignItems="center">
          <Text
            fontSize={14}
            color={
              Number(value?.length) > Number(maxLength) ? 'red' : 'grey-dark'
            }
            textAlign="right"
          >
            {value?.length || '0'} / {maxLength}
          </Text>
        </Box>
      ) : null}
    </Box>
  );
};

const InputControl = ({
  label,
  error,
  helpText,
  maxLength,
  value,
  children,
  hasCharacterCounter = false,
  extraHelpText,
  htmlFor,
  condensed,
}: InputControlProps) => (
  <>
    {label && (
      <Label htmlFor={htmlFor}>
        {label}
        {extraHelpText && (
          <Tooltip
            tooltipContent={extraHelpText}
            placement="right-start"
            maxWidth={getBaseUnit(58)}
          >
            <Icons.InfoSolid />
          </Tooltip>
        )}
      </Label>
    )}
    {children}
    <Footer
      condensed={condensed}
      error={error}
      helpText={helpText}
      maxLength={maxLength}
      value={value}
      hasCharacterCounter={hasCharacterCounter}
    />
  </>
);

export { InputControl };
