import { ComponentProps } from 'react';
import styled, { css } from 'styled-components';

import { Box } from '../../Box';
import { getColor, getBaseUnit } from '../../styles/themeGetters';

type HoverBoxProps = {
  applyHoverEffect: boolean;
  pt?: number;
  pb?: number;
  pr?: number;
  pl?: number;
} & ComponentProps<typeof Box>;

export const HoverBox = styled(Box)<HoverBoxProps>`
  padding: ${({ pt }) => getBaseUnit(pt ?? 2)}
    ${({ pr }) => getBaseUnit(pr ?? 4)} ${({ pb }) => getBaseUnit(pb ?? 2)}
    ${({ pl }) => getBaseUnit(pl ?? 4)};
  border-radius: 4px;

  ${props =>
    props.applyHoverEffect &&
    css`
      &:hover {
        background-color: ${getColor('grey-light-50')};
      }
    `}
`;
