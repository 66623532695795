import request, {
  requestFile,
  requestV2,
  stoplightMockRequest,
} from './lib/request';
import headers from './lib/headers';
import body from './lib/body';

export function getRequest(endpoint) {
  return request(endpoint, {
    headers: headers(),
  });
}

export function getFileRequest(endpoint) {
  return requestFile(endpoint, {
    headers: headers(),
  });
}

export function postRequest(endpoint, payload) {
  return request(endpoint, {
    method: 'POST',
    headers: headers(),
    body: body(payload),
  });
}

export function patchRequest(endpoint, payload) {
  return request(endpoint, {
    method: 'PATCH',
    headers: headers(),
    body: body(payload),
  });
}

export function deleteRequest(endpoint) {
  return request(endpoint, {
    method: 'DELETE',
    headers: headers(),
  });
}

export function getRequestV2(endpoint) {
  return requestV2(endpoint, {
    headers: headers(),
  });
}

export function postRequestV2(endpoint, payload) {
  return requestV2(endpoint, {
    method: 'POST',
    headers: headers(),
    body: body(payload),
  });
}

export function patchRequestV2(endpoint, payload) {
  return requestV2(endpoint, {
    method: 'PATCH',
    headers: headers(),
    body: body(payload),
  });
}

export function deleteRequestV2(endpoint) {
  return requestV2(endpoint, {
    method: 'DELETE',
    headers: headers(),
  });
}

// stoplightMockRequest is for calling api from stoplight mock server without adding localhost to the url
/* istanbul ignore next */
export function getRequestStoplight(
  endpoint,
  dynamic,
  prefer = 'code=200, dynamic=true'
) {
  const requestHeaders = headers();

  if (dynamic) {
    requestHeaders.append('Prefer', prefer);
  }

  return stoplightMockRequest(endpoint, {
    headers: requestHeaders,
  });
}
/* istanbul ignore next */
export function postRequestStoplight(endpoint, payload) {
  return stoplightMockRequest(endpoint, {
    method: 'POST',
    headers: headers(),
    body: body(payload),
  });
}

/* istanbul ignore next */
export function patchRequestStoplight(endpoint, payload) {
  return stoplightMockRequest(endpoint, {
    method: 'PATCH',
    headers: headers(),
    body: body(payload),
  });
}
