import React, { useContext } from 'react';
import styled from 'styled-components';

import { DataGridContext } from '../DataGridContext';

const StyledLink = styled.a`
  all: unset;

  &:hover {
    cursor: pointer;
  }

  & p:hover {
    text-decoration: underline;
  }
`;

type LinkRendererProps = {
  url?: string;
  children: React.ReactNode;
};

const LinkRenderer = (props: LinkRendererProps) => {
  const dataGridContext = useContext(DataGridContext);
  if (!props.url) {
    return <>{props.children}</>;
  }

  return (
    <StyledLink
      href={props.url}
      onClick={e => {
        if (e.ctrlKey || e.shiftKey || e.metaKey) {
          return; // let the browser take over
        }
        if (dataGridContext.navigateTo && props.url) {
          e.preventDefault();
          dataGridContext.navigateTo(props.url);
        }
      }}
    >
      {props.children}
    </StyledLink>
  );
};

export { LinkRenderer };
