import React, { PropsWithChildren } from 'react';

import AppLoadingState from 'components/AppLoadingState';

import { Awaitable } from './util';

type WaitForGlobalStateProps = PropsWithChildren<{
  awaitables: Awaitable<unknown>[];
}>;

/**
 * Helper component to simplify page logic.
 * Only if all of the passed `Awaitables` have been fetched will the
 * children render, until then, an `AppLoadingState` is shown.
 *
 * As this does not handle refetching and error handling, make sure to only use
 * this component for *global state* (eg. values derived from the user profile),
 * and not for state that is local to the page you're using this component in.
 *
 * @param awaitables
 * @param children
 * @constructor
 */
export const WaitForGlobalState = ({
  awaitables,
  children,
}: WaitForGlobalStateProps) => {
  if (awaitables.some(a => a.loading)) {
    return <AppLoadingState />;
  }
  return <>{children}</>;
};
