import React, { ReactNode } from 'react';
import { createClient, fetchExchange, errorExchange, Provider } from 'urql';

import { urlForGraphQL } from 'utils/api/lib/url';
import { fetchWithHeaders } from 'utils/graphql/client/fetchWithHeaders';

type GraphQLClientProviderProps = {
  children: ReactNode;
};

export const GraphQLClientProvider = ({
  children,
}: GraphQLClientProviderProps) => {
  const client = createClient({
    url: urlForGraphQL(),
    fetch: fetchWithHeaders,
    exchanges: [
      errorExchange({
        onError: error => {
          throw new Error(`GraphQL Error: ${error.message}`);
        },
      }),
      fetchExchange,
    ],
  });
  return <Provider value={client}>{children}</Provider>;
};

export const internals = {
  fetchWithHeaders,
};
