import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import { Toast } from 'Toast';

import ConfirmationReminderContainer from 'containers/ConfirmationReminderContainer';

import messages from './messages';
import Wrapper from './components/Wrapper';
import { removeToast } from './actions';
import { selectNotifications } from './selectors';
import { CONFIRMATION_REMINDER_TOAST } from './constants';

// eslint-disable-next-line react/prefer-stateless-function
class ToastNotificationContainer extends React.Component {
  renderToastContent = (customToastType, message) => {
    switch (customToastType) {
      case CONFIRMATION_REMINDER_TOAST:
        return <ConfirmationReminderContainer />;
      default:
        return message;
    }
  };

  render() {
    const {
      notifications,
      dispatchRemoveToast,
      intl: { formatMessage },
    } = this.props;

    return (
      <Wrapper className="toast">
        {notifications.map(notification => (
          <Toast
            key={notification.id}
            handleClose={() => dispatchRemoveToast(notification.id)}
            label={formatMessage(messages[notification.kind])}
            variant={notification.kind}
            dismissable={notification.options.dismissable}
            aria-label={`${notification.kind} toast notification`}
          >
            {this.renderToastContent(
              notification.options.customToastType,
              notification.message
            )}
          </Toast>
        ))}
      </Wrapper>
    );
  }
}

ToastNotificationContainer.propTypes = {
  notifications: PropTypes.array.isRequired,
  dispatchRemoveToast: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = createStructuredSelector({
  notifications: selectNotifications(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatchRemoveToast: id => dispatch(removeToast(id)),
  };
}

export const TranslatedToastNotificationContainer = injectIntl(
  ToastNotificationContainer
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslatedToastNotificationContainer);
