import React from 'react';
import { useIntl } from 'react-intl';

import { User } from 'types/User';

import { Avatar } from '../Avatar';
import { Icons } from '../Icons';
import messages from './messages';

/**
 * This component is a wrapper around Avatar.
 * It is used to display a user avatar.
 * This component centralizes the logic for displaying a user avatar (e.g. the status, empty state, ...).
 * @param user The user to display the avatar for.
 * @param hasPresence Whether the user has presence.
 * @param size The size of the avatar.
 * @param tooltipText The text to display in the tooltip (optional, otherwise the name will be shown).
 * @param onClick The function to call when the avatar is clicked.
 * @returns The rendered component.
 * @example
 * <UserAvatar user={user} size="l" onClick={handleClick} />
 * <UserAvatar user={user} hasPresence size="l" onClick={handleClick} />
 */

export type UserAvatarProps = {
  user?: User;
  hasPresence?: boolean;
  size: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
  tooltipText?: string;
  onClick?: () => void;
};

const UserAvatar = ({
  user,
  hasPresence,
  size,
  tooltipText,
  onClick,
}: UserAvatarProps) => {
  const { formatMessage } = useIntl();

  if (!user) {
    return (
      <Avatar
        type="person"
        size={size}
        fallbackText={formatMessage(messages.unassignedFallbackMessage)}
        onClick={onClick}
      >
        <Icons.User color="navy" />
      </Avatar>
    );
  }

  let avatarStatus: 'archived' | 'pending' | undefined;

  if (user.deactivated) {
    avatarStatus = 'archived';
  } else if (user.invited) {
    avatarStatus = 'pending';
  }

  let avatarImage: string;

  if (size === 'l' || size === 'xl' || size === 'xxl') {
    avatarImage = user.avatarUrlLarge;
  } else {
    avatarImage = user.avatarUrlMedium;
  }

  return (
    <Avatar
      type="person"
      size={size}
      fallbackText={`${user.firstName} ${user.lastName}`}
      tooltipText={tooltipText}
      image={avatarImage}
      status={avatarStatus}
      onClick={onClick}
      hasPresence={hasPresence}
    />
  );
};

export { UserAvatar };
