import {
  getRequestV2,
  postRequestV2,
  patchRequestV2,
  patchRequest,
} from 'utils/api/methods';

const getWorkspaces = () => getRequestV2('power_bi/workspaces');

const getReports = (workspaceId: string) =>
  getRequestV2(`power_bi/workspaces/${workspaceId}/reports`);

const reportEmbedToken = (workspaceId: string, reportId: string) =>
  postRequestV2(
    `power_bi/workspaces/${workspaceId}/reports/${reportId}/embed_tokens`,
    {}
  );

const executeQuery = (payload: { datasetId: string; daxExpression: string }) =>
  postRequestV2(`power_bi/queries/preview`, payload);

const saveQuery = (payload: {
  datasetId: string;
  daxExpression: string;
  keyResultId: number;
}) => postRequestV2(`power_bi/queries`, payload);

const updateQuery = (
  queryId: number,
  payload: {
    datasetId: string;
    daxExpression: string;
  }
) => patchRequestV2(`power_bi/queries/${queryId}`, payload);

const updatePowerBiSetting = (payload: { enabled: boolean }) =>
  patchRequest('settings/integrations/power_bi', payload);

const powerBi = {
  getWorkspaces,
  getReports,
  reportEmbedToken,
  executeQuery,
  saveQuery,
  updateQuery,
  updatePowerBiSetting,
};

export default powerBi;
