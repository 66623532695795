import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Box } from '../../../Box';
import { Icons } from '../../../Icons';
import { getBaseUnit, getColor } from '../../../styles/themeGetters';
import { Text } from '../../../Text';

const Wrapper = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
`;

type LabelProps = {
  $disabled?: boolean;
};

const Label = styled.label<LabelProps>`
  display: flex;
  margin-bottom: ${getBaseUnit(4)};
  cursor: ${props => (props.$disabled ? 'not-allowed' : 'initial')};
`;

type InputOptionProps = {
  label?: string;
  helpText?: string;
  children: ReactNode;
  disabled?: boolean;
};

const InputOption = ({
  label,
  helpText,
  children,
  disabled,
}: InputOptionProps) => (
  <Label $disabled={disabled}>
    <Wrapper>{children}</Wrapper>
    <Box ml={3}>
      <Text color={disabled ? 'grey-dark' : 'black'}>{label}</Text>
      {helpText && (
        <Text color="grey-dark" fontSize={14} mt={1}>
          {helpText}
        </Text>
      )}
    </Box>
  </Label>
);

const defaultStyles = css`
  cursor: pointer;
`;

const disabledStyles = css`
  background-color: ${getColor('grey-light-50')};
  cursor: not-allowed;
`;

const errorStyles = css`
  border-color: ${getColor('red')};
`;

const OuterShape = styled.span`
  box-sizing: border-box;
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid ${getColor('grey')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${getColor('white')};

  &:focus-within {
    border-color: ${getColor('blue')};
  }
`;

type ShapeProps = {
  $disabled?: boolean;
  $hasError?: boolean;
  $backgroundColor?: string;
  $isSelected?: boolean;
};

const OuterCircle = styled(OuterShape)<ShapeProps>`
  border-radius: 100%;

  ${props => (props.$disabled ? disabledStyles : defaultStyles)};
  ${props => props.$hasError && errorStyles};

  &:focus-within {
    border-color: ${getColor('blue-light')};
  }
`;

const reverseStyles = css`
  background-color: ${getColor('navy')};
  border-color: ${getColor('navy')};
`;

const reverseErrorStyles = css`
  border-color: ${getColor('red')};
`;

const OuterSquareReversed = styled(OuterShape)<ShapeProps>`
  border-radius: 4px;

  ${props => (props.$isSelected ? reverseStyles : defaultStyles)};
  ${props => props.$disabled && disabledStyles};
  ${props => props.$hasError && reverseErrorStyles};
`;

const InnerShape = styled.span`
  position: absolute;
  width: 10px;
  height: 10px;
`;

const InnerCircle = styled(InnerShape)<ShapeProps>`
  border-radius: 100%;
  background-color: ${props =>
    props.$disabled ? getColor('grey') : getColor('navy')};
`;

const Input = styled.input`
  margin: 0;
  width: 16px;
  height: 16px;
  opacity: 0;
  position: absolute;
`;

type OptionType = {
  hasError: boolean;
  disabled?: boolean;
  isSelected: boolean;
  register;
  name: string;
  validations;
  value;
};

const CheckOption = ({
  hasError,
  disabled,
  isSelected,
  register,
  validations,
  name,
  value,
}: OptionType) => (
  <OuterSquareReversed
    $disabled={disabled}
    $hasError={hasError}
    $isSelected={isSelected}
  >
    {isSelected ? <Icons.Check color="white" /> : null}
    <Input
      {...register(name, validations)}
      type="checkbox"
      value={value}
      disabled={disabled}
    />
  </OuterSquareReversed>
);

const CircleOption = ({
  name,
  register,
  validations,
  value,
  disabled,
  isSelected,
  hasError,
}: OptionType) => (
  <OuterCircle $disabled={disabled} $hasError={hasError}>
    {isSelected && <InnerCircle $disabled={disabled} />}
    <Input
      {...register(name, validations)}
      type="radio"
      value={value}
      disabled={disabled}
    />
  </OuterCircle>
);

export { InputOption, CheckOption, CircleOption };
