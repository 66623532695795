/* global GIT_REV, GIT_BRANCH */
import * as Sentry from '@sentry/browser';

import { getEnvConfig } from 'utils/getEnvConfig';

Sentry.init({
  dsn: getEnvConfig('SENTRY_URL'),
  release: GIT_REV,
  environment: getEnvConfig('SENTRY_ENVIRONMENT'),
  initialScope: {
    tags: { git_branch: GIT_BRANCH },
  },
  // https://docs.sentry.io/platforms/javascript/configuration/filtering/
  ignoreErrors: [
    // https://github.com/react-dnd/react-dnd/issues/3119
    'Cannot have two HTML5 backends at the same time.',
    // https://github.com/getsentry/sentry-javascript/issues/3440
    'Non-Error promise rejection captured',
    // Users are using your application when you deploy.
    'Loading chunk',
  ],
  // This sets the sample rate to be 0%
  replaysSessionSampleRate: 0,
  // This sets the error sample rate to 100% (for sampling sessions when an error occurs)
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.Replay({
      // Additional SDK configuration goes in here
      // IMPORTANT: We mask all text and block all media for user privacy reasons
      // please don't change these settings unless discussed with (and approved by) InfoSec
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});
