import React, { Ref, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { getColor } from '../../../styles/themeGetters';
import { Box } from '../../../Box';

const errorStyles = css`
  border-color: ${getColor('red')};
`;

const focusedStyles = css`
  border-color: ${getColor('blue')};
`;

const defaultStyles = css`
  border-color: ${getColor('grey-light-100')};

  &:focus-within {
    border-color: ${getColor('blue')};
  }
`;

const disabledStyles = css`
  color: ${getColor('grey-light-100')};
  cursor: not-allowed;
  background: #fff;
`;

type InputFieldStyledProps = {
  $hasError?: boolean;
  $isFocused?: boolean;
  disabled?: boolean;
  $paddingLeft?: string;
  $paddingRight?: string;
};

const InputFieldStyled = styled(Box)<InputFieldStyledProps>`
  display: flex;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 4px;
  background: #fff;
  padding-left: ${props => props.$paddingLeft || '12px'};
  padding-right: ${props => props.$paddingRight || '12px'};
  ${props => (props.$hasError ? errorStyles : defaultStyles)};
  ${props => (props.disabled ? disabledStyles : null)};
  ${props => props.$isFocused && focusedStyles}
`;

type InputFieldProps = {
  hasError?: boolean;
  disabled?: boolean;
  paddingLeft?: string;
  paddingRight?: string;
  children?: React.ReactNode;
  isFocused?: boolean;
};

const InnerInputField = (
  {
    hasError,
    disabled,
    paddingLeft,
    paddingRight,
    children,
    isFocused,
  }: InputFieldProps,
  ref: Ref<HTMLDivElement>
) => (
  <InputFieldStyled
    $hasError={hasError}
    disabled={disabled}
    $paddingLeft={paddingLeft}
    $paddingRight={paddingRight}
    $isFocused={isFocused}
    ref={ref}
  >
    {children}
  </InputFieldStyled>
);

const InputField = forwardRef(InnerInputField);

export { InputField };
