import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Placement,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  offset,
  flip,
  shift,
  arrow,
  autoUpdate,
  inline,
} from '@floating-ui/react-dom-interactions';

import { getColor, getBaseUnit } from '../styles/themeGetters';
import { Text } from '../Text';

type StyledTooltipProps = {
  minWidth?: string;
  maxWidth?: string;
  position: string;
  top: number | null;
  left: number | null;
};

const StyledTooltip = styled.div<StyledTooltipProps>`
  background-color: ${getColor('navy-dark')};
  border-radius: 4px;
  box-shadow: 0 2px 4px rgb(39 40 51 / 20%);
  box-sizing: border-box;
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  padding: ${getBaseUnit(2)} ${getBaseUnit(3)};
  position: ${({ position }) => position};
  top: ${({ top }) => (top ? `${top}px` : 0)};
  left: ${({ left }) => (left ? `${left}px` : 0)};
  z-index: 1;
`;

type StyledArrowProps = {
  arrowStaticSide: string | undefined;
  position: string;
  top: number | undefined;
  left: number | undefined;
};

// for the arrow styles see https://floating-ui.com/docs/tutorial#arrow-middleware
const StyledArrow = styled.div<StyledArrowProps>`
  position: ${({ position }) => position};
  background-color: ${getColor('navy-dark')};
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  top: ${({ top }) => (top ? `${top}px` : '')};
  left: ${({ left }) => (left ? `${left}px` : '')};
  right: unset;
  bottom: unset;
  ${({ arrowStaticSide }) => arrowStaticSide}: -4px;
`;

type TooltipProps = {
  children: JSX.Element;
  // to handle wrapping up content in multiple lines
  minWidth?: string;
  maxWidth?: string;
  show?: boolean;
  onShowChange?: (show: boolean) => void;
  placement?: Placement;
  tooltipContent: string | JSX.Element;
  tooltipContentTextAlign?: 'left' | 'center' | 'right';
  delay?: {
    open: number;
    close: number;
  };
  addInlineMiddleware?: boolean;
};

/**
 * The Tooltip component can be used to render a floating popover pointing to the components passed as `children`.
 *
 * By default, the tooltip will show up on hover over the children.
 *
 * For this to work, `children` must be a component that `forwardRef`s and passes all props to its outside wrapper.
 * See the stories' code for examples.
 *
 * If the reference element of the tooltip spans over multiple lines, you can use the `addInlineMiddleware` prop to enable Floating UI's `inline()` middleware. This will improve the tooltip positioning in these use cases.
 */
const Tooltip = ({
  children,
  minWidth,
  maxWidth,
  show,
  onShowChange,
  placement = 'top',
  tooltipContent,
  tooltipContentTextAlign = 'center',
  delay,
  addInlineMiddleware,
}: TooltipProps) => {
  const [internalShow, setInternalShow] = useState(false);
  const arrowRef = useRef(null);

  // `show` state from top level implementation takes precedence, otherwise defaults to internal state
  const showTooltip = typeof show !== 'undefined' ? show : internalShow;

  const handleShowTooltip = (libNextOpenState: boolean) => {
    // manage internal state with Floating UI library's next state value
    if (typeof show === 'undefined') {
      setInternalShow(libNextOpenState);
    }

    // manage state from the top level implementation
    if (onShowChange && typeof show !== 'undefined') {
      onShowChange(show);
    }
  };

  // displaces the tooltip from its core `placement` along the specified axes
  // the numbers represent the distance between the tooltip and the reference element across different axes
  // see https://floating-ui.com/docs/offset and https://floating-ui.com/docs/offset#function
  const getTooltipOffset = ({ placement }) => {
    if (
      placement === 'right-start' ||
      placement === 'right-end' ||
      placement === 'left-start' ||
      placement === 'left-end'
    ) {
      return { alignmentAxis: -10, mainAxis: 9 };
    }
    return { alignmentAxis: -35, mainAxis: 9 };
  };

  const middleware = [
    offset(getTooltipOffset),
    flip(),
    shift({ padding: 5 }),
    arrow({ element: arrowRef, padding: 8 }),
  ];

  // `inline()` middleware needs to be placed before the `flip()` middleware, but `offset()` must be first
  if (addInlineMiddleware) {
    middleware.splice(1, 0, inline());
  }

  const {
    x: tooltipX,
    y: tooltipY,
    reference,
    floating,
    strategy,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    placement: finalPlacement,
    context,
  } = useFloating({
    open: showTooltip,
    onOpenChange: handleShowTooltip,
    whileElementsMounted: autoUpdate,
    placement,
    middleware,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { delay }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
  ]);

  // see https://floating-ui.com/docs/tutorial#arrow-middleware
  const arrowStaticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[finalPlacement.split('-')[0]];

  const innerTooltipContent =
    typeof tooltipContent === 'string' ? (
      <Text
        color="white"
        fontSize={12}
        textAlign={tooltipContentTextAlign}
        minWidth={minWidth}
        maxWidth={maxWidth}
      >
        {tooltipContent}
      </Text>
    ) : (
      tooltipContent
    );
  return (
    <>
      {React.Children.map(children, c =>
        React.cloneElement(c, { ...getReferenceProps({ ref: reference }) })
      )}
      {showTooltip && tooltipContent && (
        <StyledTooltip
          {...getFloatingProps({ ref: floating })}
          minWidth={minWidth}
          maxWidth={maxWidth}
          position={strategy}
          top={tooltipY}
          left={tooltipX}
        >
          {innerTooltipContent}
          <StyledArrow
            ref={arrowRef}
            {...{
              arrowStaticSide,
              position: strategy,
              top: arrowY,
              left: arrowX,
            }}
          />
        </StyledTooltip>
      )}
    </>
  );
};

export { Tooltip };
