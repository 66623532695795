import { useState } from 'react';

const useLocalStorage = <T>(
  key: string,
  defaultValue: T
): [T, (value: T) => void] => {
  const [item, setItem] = useState(
    JSON.parse(localStorage.getItem(key)) || { value: defaultValue }
  );

  const handleSetItem = value => {
    localStorage.setItem(key, JSON.stringify({ value }));
    setItem({ value });
  };

  return [item.value, handleSetItem];
};

export { useLocalStorage };
