import { useEffect } from 'react';
import { TableInstance } from 'react-table';

import { Row } from './types';

type UseExpandedRowsByDefault = {
  tableInstance: TableInstance<Row>;
  expandedRowsByDefault: string[];
  collapseAll: boolean;
};

export const useExpandRowsByDefault = ({
  tableInstance,
  expandedRowsByDefault,
  collapseAll,
}: UseExpandedRowsByDefault) => {
  const {
    flatRows,
    toggleRowExpanded,
    isAllRowsExpanded,
    toggleAllRowsExpanded,
  } = tableInstance;

  const toggleDefaultRows = () => {
    if (collapseAll) {
      toggleAllRowsExpanded();
    } else if (isAllRowsExpanded) {
      flatRows.forEach(({ id, original: { entityType } }) => {
        if (!expandedRowsByDefault.includes(entityType)) {
          toggleRowExpanded([id], false);
        }
      });
    } else {
      toggleAllRowsExpanded();
    }
  };

  useEffect(
    () => {
      flatRows.forEach(({ id, original: rowData, isExpanded }) => {
        if (
          typeof isExpanded === 'undefined' && // isExpanded is undefined by default until the row gets expanded or collapsed.
          expandedRowsByDefault.includes(rowData.entityType)
        ) {
          toggleRowExpanded([id], true); // for some reason toggleRowExpanded expects the id to be wrapped inside an array. It wouldn't work for multiple ids.
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- [RFC 032]
    [flatRows]
  );

  return toggleDefaultRows;
};
