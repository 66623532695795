/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router';

import { colors } from 'utils/style';

import buttonSizes from './buttonSizes';

const styles = css`
  color: ${props => props.color};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  ${props => (props.button ? buttonSizes[props.buttonSize] : '')}
  ${({ isActive }) => isActive && 'text-decoration: underline;'}
`;

// eslint-disable-next-line no-unused-vars
const StyledLink = styled(({ button, buttonSize, isActive, ...rest }) => (
  <RouterLink {...rest} />
))`
  ${styles}
`;

const Anchor = styled(
  ({ button, buttonSize, isActive, activeStyle, href, ...rest }) => {
    const whiteList = /^(https?|mailto):/;
    const isSecure = whiteList.test(href);
    // eslint-disable-next-line no-unused-vars, jsx-a11y/anchor-has-content
    return isSecure ? <a href={href} {...rest} /> : <a {...rest} />;
  }
)`
  ${styles}
`;

const Link = ({ to, target, ...rest }) => {
  const props = rest;

  if (target === '_blank') {
    props.rel = 'noopener noreferrer';
    props.target = '_blank';
  }

  if (to) {
    return <StyledLink to={to} {...props} />;
  }

  return <Anchor {...props} />;
};

Link.defaultProps = {
  buttonSize: 'regular',
  color: colors.linkColor,
};

Link.propTypes = {
  color: PropTypes.string,
  reverse: PropTypes.bool,
  button: PropTypes.bool,
  buttonSize: PropTypes.oneOf(['small', 'regular']),
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      state: PropTypes.object,
    }),
  ]),
  target: PropTypes.string,
};

export default Link;
