import React from 'react';
import styled from 'styled-components';
import { UseComboboxPropGetters } from 'downshift';

import { Text } from '../../../Text';
import { Menu } from '../../../Menu';
import { MenuItemRenderer } from '../renderers/MenuItemRenderer';
import { Entity, Item, LazyItemState } from '../types';

const EmptyState = styled(Text)`
  padding: 6px 16px;
`;

type Props = {
  getItemProps: UseComboboxPropGetters<Entity>['getItemProps'];
  filteredItems: Entity[];
  selectedItems: Item[];
  lazyItemState: LazyItemState;
  isOpen: boolean;
  anchorEl: React.RefObject<HTMLElement>;
  inputValue: string;
};

const MultiselectMenu = ({
  getItemProps,
  filteredItems,
  selectedItems,
  lazyItemState,
  isOpen,
  anchorEl,
  inputValue,
}: Props) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      menuOffset={4}
      fullWidth
      // The logic behind the 376 is: 376 = 10 * 36 + 16
      // 10 => is number of items we wanna show in the menu
      // 36 => height of each item
      // 16 => padding-top and padding-bottom
      maxHeight="376px"
      // This prop can be used to control or force menu to re-calculate the positioning
      sizeEvaluatorValue={selectedItems.length}
    >
      {/*
        using flatMap here instead of map because we iterate over a nested list:
        example: initially we have:
          [item1, item2, lazyItem, item3] -> once lazyItems get loaded:
            [item1, item2, [item4, item5], item3] -> flattening the inner list into the menu structure:
              [item1, item2, item4, item5, item3]
       */}
      {filteredItems.flatMap((item, index) => {
        const isSelected = !!selectedItems.find(
          selectItem => selectItem.id === item.id
        );

        // to prevent disabled items from being selected: https://github.com/downshift-js/downshift/issues/269#issuecomment-348142348
        const liProps =
          isSelected || item.disabled ? {} : getItemProps({ item, index });

        if (item.entityType === 'lazyItem') {
          return (
            <MenuItemRenderer
              key={item.id}
              item={item}
              isSelected={false}
              liProps={getItemProps({ item, index })}
              loading={lazyItemState.status === 'loading'}
            />
          );
        }
        return (
          <MenuItemRenderer
            key={item.id}
            item={item}
            isSelected={isSelected}
            liProps={liProps}
          />
        );
      })}
      {inputValue &&
        filteredItems.length === 0 &&
        lazyItemState.status === 'success' && (
          <EmptyState>
            No results found for{' '}
            <Text fontWeight="bold" as="strong">
              {inputValue}
            </Text>
          </EmptyState>
        )}
    </Menu>
  );
};

export { MultiselectMenu };
