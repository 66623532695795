import React from 'react';

import { IconComponent } from '../../Icons/helpers';
import { InputControl } from '../components/InputControl';
import { InputField } from '../components/InputField';
import { InputFieldBody } from '../components/InputFieldBody';
import { InputFieldStartIcon } from '../components/InputFieldStartIcon';
import { InputFieldClearButton } from '../components/InputFieldClearButton';
import {
  CommonProps,
  ClearButtonFeature,
  CharacterCounterFeature,
} from '../types';

type InputTextProps = {
  startIcon?: IconComponent;
} & CommonProps &
  CharacterCounterFeature &
  ClearButtonFeature;

const InputText = (props: InputTextProps) => {
  const {
    name,
    placeholder,
    error,
    disabled,
    helpText,
    extraHelpText,
    label,
    startIcon,
    hasCharacterCounter,
    hasClearButton,
    maxLength,
    validations = {},
    formHooks: { register, watch, resetField },
    condensed,
  } = props;
  const value = watch && watch(name);
  const showClearButton = Boolean(hasClearButton && value);
  const hasLengthError = maxLength ? Number(value?.length) > maxLength : false;

  if (!validations?.maxLength) validations.maxLength = maxLength;

  return (
    <InputControl
      error={error}
      helpText={helpText}
      extraHelpText={extraHelpText}
      value={value}
      hasCharacterCounter={hasCharacterCounter}
      maxLength={maxLength}
      label={label}
      htmlFor={name}
      condensed={condensed}
    >
      <InputField
        hasError={Boolean(error) || hasLengthError}
        disabled={disabled}
        paddingLeft={startIcon ? '5px' : '11px'}
        paddingRight={showClearButton ? '5px' : '11px'}
      >
        {startIcon && (
          <InputFieldStartIcon disabled={disabled} icon={startIcon} />
        )}
        <InputFieldBody
          disabled={disabled}
          placeholder={placeholder}
          register={register}
          name={name}
          validations={validations}
        />
        {showClearButton && (
          <InputFieldClearButton
            onClick={() => resetField?.(name)}
            disabled={disabled}
            align="center"
          />
        )}
      </InputField>
    </InputControl>
  );
};

export { InputText };
