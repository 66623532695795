import React from 'react';
import styled from 'styled-components';

import {
  getFontFamily,
  getFontSize,
  getLineHeight,
} from '../../../styles/themeGetters';

const InputWrapper = styled.span`
  flex: 1;
`;

const Input = styled.input`
  font-family: ${() => getFontFamily('lato')};
  font-size: ${getFontSize(16)};
  line-height: ${getLineHeight(20)};
  border: none;
  padding: 5px 0;
  width: 100%;

  &:focus-visible {
    outline: none;
  }
  cursor: ${props => (props.disabled ? 'not-allowed' : 'initial')};
`;

type Props = {
  getComboboxProps;
  getInputProps;
  getDropdownProps;
  openMenu: () => void;
  isOpen: boolean;
  placeholder: string;
  disabled?: boolean;
};

const MultiselectFieldBody = ({
  getComboboxProps,
  getInputProps,
  getDropdownProps,
  openMenu,
  isOpen,
  placeholder,
  disabled,
}: Props) => (
  <InputWrapper {...getComboboxProps()}>
    <Input
      {...getInputProps({
        ...getDropdownProps({ preventKeyAction: isOpen }),
      })}
      placeholder={placeholder}
      onClick={!disabled ? openMenu : null}
      disabled={disabled}
      data-testid="multi-select"
    />
  </InputWrapper>
);

export { MultiselectFieldBody };
