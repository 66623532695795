import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import { TableExpandedToggleProps } from 'react-table';

import { ExpanderRenderer } from '../ExpanderRenderer';
import { Box } from '../../../Box';
import { Truncate } from '../../../Truncate';
import { IconBox, Icons } from '../../../Icons';
import { EntitySymbol } from '../../../EntitySymbol';
import { DataGridContext } from '../../DataGridContext';
import { RendererProps } from '../RendererProps';
import { FontSizeKeys } from '../../../styles/themes';
import { getColor, getFontSize } from '../../../styles/themeGetters';
import { Skeleton } from '../../../Skeleton';
import { LinkRenderer } from '../../common/LinkRenderer';
import { TD } from '../../../Table';
import {
  GoalTitleData,
  AlignedGoalTitleData,
  KeyResultTitleData,
} from '../../types';
import messages from './messages';

type TitleEntityType =
  | 'goal_group_title'
  | 'goal_title'
  | 'key_result_title'
  | 'initiative_title'
  | 'jira_initiative_title'
  | 'aligned_goal_title';

type TitleRendererProps = {
  canExpand?: boolean;
  isExpanded?: boolean;
  getToggleRowExpandedProps?: (
    props?: Partial<TableExpandedToggleProps>
  ) => TableExpandedToggleProps;
  rowDepth?: number;
  isDynamicDataGrid?: boolean;
  maxDepth?: number;
  handleRowClick?: () => void;
};

const fontSizeByEntityType = {
  goal_group_title: 16,
  goal_title: 14,
  key_result_title: 14,
  initiative_title: 14,
  jira_initiative_title: 14,
  aligned_goal_title: 14,
};

const nestedRowsPadding = {
  goal_group_title: 16,
  goal_title: 16,
  key_result_title: 64,
  initiative_title: 64,
  aligned_goal_title: 64,
};

const ExpandableChevron = css`
  &:hover {
    background-color: ${getColor('grey-light-100')};
  }
`;

type StyledChevronWrapperProps = {
  canExpand?: boolean;
};

const StyledChevronWrapper = styled(Box)<StyledChevronWrapperProps>`
  font-size: ${getFontSize(10)};
  border-radius: 4px;
  cursor: ${(props) => (props.canExpand ? 'cursor' : 'auto !important')};
  color: ${getColor('navy')};

  ${(props) => props.canExpand && ExpandableChevron};
`;

type SymbolForEntityTypeProps = {
  entityType: TitleEntityType;
  draft?: boolean;
};

const SymbolForEntityType = ({
  entityType,
  draft,
}: SymbolForEntityTypeProps) => {
  const { formatMessage } = useIntl();
  const { i18nOverrides } = useContext(DataGridContext);

  switch (entityType) {
    case 'goal_group_title':
      return <></>;
    case 'goal_title':
      return (
        <EntitySymbol
          variant="goal"
          size="l"
          tooltipContent={formatMessage(messages.goal)}
          draft={draft}
        />
      );
    case 'key_result_title':
      return (
        <EntitySymbol
          variant="kr"
          tooltipContent={formatMessage(messages.keyResult)}
          draft={draft}
        />
      );
    case 'initiative_title':
      return (
        <EntitySymbol
          variant="initiative"
          tooltipContent={formatMessage(messages.initiative, i18nOverrides)}
          draft={draft}
        />
      );
    case 'jira_initiative_title':
      return (
        <EntitySymbol
          variant="epic"
          tooltipContent={formatMessage(messages.initiative, i18nOverrides)}
          draft={draft}
        />
      );
    case 'aligned_goal_title':
      return (
        <EntitySymbol
          variant="aligned"
          tooltipContent={formatMessage(messages.alignedGoal)}
          draft={draft}
        />
      );
  }
};

export const TitleRenderer = (
  props: RendererProps<'title'> & TitleRendererProps
) => {
  const { isDynamicDataGrid, isExpanded, rowDepth, handleRowClick, maxDepth } =
    props;

  const boxVersionProps = {
    flexGap: '4px',
    pl: 4 + 12 * (rowDepth ?? 0),
  };

  if (
    props.loadingState === 'loading' ||
    // @ts-ignore PL-2222
    props.cell?.entityType === 'title_loading_cell'
  ) {
    return (
      <TD pl={8} minWidth={500}>
        {isDynamicDataGrid ? (
          <Box display="flex" alignItems="center" {...boxVersionProps}>
            <Skeleton width="25px" height="25px" />
            <Skeleton />
          </Box>
        ) : (
          <Skeleton />
        )}
      </TD>
    );
  }

  const isExpandable = (
    props.cell as GoalTitleData | AlignedGoalTitleData | KeyResultTitleData
  ).expandable;
  let tdVersionProps = {};

  if (!isDynamicDataGrid) {
    tdVersionProps = props?.getToggleRowExpandedProps?.();
  }

  return (
    <TD
      pl={isDynamicDataGrid ? 0 : nestedRowsPadding[props.cell.entityType]}
      {...tdVersionProps}
      title={undefined}
    >
      <Box
        display="flex"
        alignItems="center"
        aria-label="cell title"
        {...(isDynamicDataGrid ? { ...boxVersionProps } : {})}
      >
        {isDynamicDataGrid ? (
          <Box minWidth="24px">
            {isExpandable && (
              <ExpanderRenderer
                isExpanded={isExpanded ?? false}
                rowDepth={rowDepth ?? 1}
                maxDepth={maxDepth ?? 1}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick={handleRowClick ?? (() => {})}
              />
            )}
          </Box>
        ) : (
          <StyledChevronWrapper
            display="flex"
            alignItems="center"
            justifyContent="center"
            minWidth="24px"
            minHeight="24px"
            mr={props.cell.entityType !== 'goal_group_title' ? 4 : undefined}
            canExpand={props.canExpand}
          >
            {props.canExpand && (
              <>
                {props.isExpanded ? (
                  <IconBox aria-label="collapse row">
                    <Icons.ChevronDown color="grey-dark" />
                  </IconBox>
                ) : (
                  <IconBox aria-label="expand row">
                    <Icons.ChevronRight color="grey-dark" />
                  </IconBox>
                )}
              </>
            )}
          </StyledChevronWrapper>
        )}
        {props.cell.entityType !== 'goal_group_title' && (
          <Box
            mr={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <SymbolForEntityType
              entityType={props.cell.entityType}
              draft={props.cell.draft}
            />
          </Box>
        )}
        <LinkRenderer
          url={
            props.cell.entityType !== 'goal_group_title'
              ? props.cell.url
              : undefined
          }
        >
          <Truncate
            textProps={{
              ml: 2,
              fontSize: fontSizeByEntityType[
                props.cell.entityType
              ] as FontSizeKeys,
              fontWeight: 'semibold',
            }}
            maxWidth="100%"
            tooltipMaxWidth="280px"
            text={props.cell.value}
            maxLines={2}
          />
        </LinkRenderer>
      </Box>
    </TD>
  );
};
