import React, { useRef, useEffect, MouseEvent } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { animated, useTransition } from 'react-spring';

import { getColor } from '../styles/themeGetters';
import { useClickOutside } from '../utils/useClickOutside';

type DrawerSize = 's' | 'm';

const sizes: { [key in DrawerSize]: number } = {
  s: 380,
  m: 636,
};

const StyledDrawer = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  height: 100vh;

  background-color: ${getColor('white')};
  border-left: 1px solid ${getColor('grey-light-100')};
  box-shadow: 0 0 0 rgb(16 16 16 / 4%), 0 8px 32px rgb(32 32 32 / 8%);
`;

const StyledClosingWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const AnimatedDrawer = animated(StyledDrawer);

type AnimatedMountAndUnmountDrawerProps = {
  children: React.ReactNode;
  open: boolean;
  width: number;
};

const AnimatedMountAndUnmountDrawer = ({
  children,
  open,
  width,
}: AnimatedMountAndUnmountDrawerProps) => {
  const transitions = useTransition(open, {
    from: { translateX: width },
    enter: { translateX: 0 },
    leave: { translateX: width },
    config: {
      // https://stackoverflow.com/a/58543584
      // increasing tension and decreasing friction will result in animation speed increasing.
      // numbers are the result of a trial and error process. `clamp: true` stops the animation overshoot
      tension: 315,
      friction: 25,
      clamp: true,
    },
  });

  return transitions(
    (styles, item) =>
      item && (
        <AnimatedDrawer
          style={{
            ...styles,
            width,
          }}
          aria-label="drawer"
        >
          {children}
        </AnimatedDrawer>
      )
  );
};

type ClosingWrapperProps = {
  children: React.ReactNode;
  onClickOutside: (e: MouseEvent) => void;
  prioritizeAnchor?: boolean;
  outsideClickIgnoredNodesIds: string[];
  outsideClickIgnoredNodeClassName: string;
};

const ClosingWrapper = ({
  children,
  onClickOutside,
  prioritizeAnchor = false,
  outsideClickIgnoredNodesIds,
  outsideClickIgnoredNodeClassName,
}: ClosingWrapperProps) => {
  const ref = useRef(null);

  useClickOutside({
    targetRef: ref,
    onClickOutside,
    prioritizeAnchor,
    ignoredNodesIds: outsideClickIgnoredNodesIds,
    ignoredNodeClassName: outsideClickIgnoredNodeClassName,
    eventType: 'mousedown',
  });

  return <StyledClosingWrapper ref={ref}>{children}</StyledClosingWrapper>;
};

type DrawerProps = {
  children: React.ReactNode;
  isOpen: boolean;
  onClickOutside: (event: MouseEvent) => void;
  size?: DrawerSize;
  prioritizeAnchor?: boolean;
  root?: string;
  outsideClickIgnoredNodesIds?: string[];
  outsideClickIgnoredNodeClassName?: string;
};

const Drawer = ({
  children,
  isOpen,
  onClickOutside,
  size = 's',
  prioritizeAnchor = false,
  root = 'drawer',
  outsideClickIgnoredNodesIds = [],
  outsideClickIgnoredNodeClassName = '',
}: DrawerProps) => {
  useEffect(() => {
    // Prevents page scrolling when Drawer is open.
    document.body.style['overflow-y'] = isOpen ? 'hidden' : null;

    return () => {
      // Unsets the overflow-y style when Drawer is unmounted.
      document.body.style['overflow-y'] = null;
    };
  }, [isOpen]);

  return createPortal(
    <AnimatedMountAndUnmountDrawer open={isOpen} width={sizes[size]}>
      <ClosingWrapper
        onClickOutside={onClickOutside}
        prioritizeAnchor={prioritizeAnchor}
        outsideClickIgnoredNodesIds={[root, ...outsideClickIgnoredNodesIds]}
        outsideClickIgnoredNodeClassName={outsideClickIgnoredNodeClassName}
      >
        {children}
      </ClosingWrapper>
    </AnimatedMountAndUnmountDrawer>,
    // @ts-ignore to override stricNullCheck - we know that this element exists
    document.getElementById(root)
  );
};

export { Drawer };
