import React from 'react';

import { Kpi } from 'types/KPI';
import { Locale } from 'types/Locale';
import { assertUnreachable } from 'types/utilityTypes';

import { KpiListItem } from './components/KpiListItem';

/**
 * EntityListItem component is used to render a list item for a specific entity type.
 * It displays the entity symbol, owner avatar, title and details.
 * The component is clickable and navigates to a specific path.
 * Right now, it only supports KPI entity type.
 *
 * @param {Entity} entity - Entity object
 * @param {string} entityType - Type of entity: 'kpi' (more types to be added in the future); this is automatically inferred from the entity object
 * @param {Locale} locale - Locale object to render formatted values (only relevant for KPIs)
 * @param {function} navigateTo - Function to navigate to a specific path
 * @param {Size} size - Size of the component: `xs` | `s` | `m`
 * @param {boolean} showEntitySymbol - Show entity symbol
 * @param {boolean} showOwnerAvatar - Show owner avatar
 * @param {number} padding - Padding of the component
 * @param {function} onClick - Function to be called on click
 *
 * @example
 * <EntityListItem
 *  entity={{ id: '1', title: 'KPI 1', ... as Kpi }}
 *  locale={locale}
 *  navigateTo={navigateTo}
 *  size="s"
 *  showEntitySymbol
 *  showOwnerAvatar
 *  padding={8}
 *  onClick={onClick}
 * />
 */

export type Size = 'xs' | 's' | 'm'; // in the future: 'l', 'xl', 'xxl'

type EntityTypeKpiProps = { locale: Locale } & {
  entity: Kpi;
  entityType: 'kpi';
};
// Add further entity types here

export type EntityListItemProps = EntityTypeKpiProps & {
  navigateTo: (path: string) => void;
  size?: Size;
  showEntitySymbol?: boolean;
  showOwnerAvatar?: boolean;
  padding?: number;
  onClick?: () => void;
  variant?: 'prependOwner' | 'appendOwner';
  showActionButton?: boolean;
  isClickable?: boolean;
  onActionButtonClick?: () => void;
};

const EntityListItem = ({
  entity,
  entityType,
  navigateTo,
  size = 's',
  showEntitySymbol = true,
  showOwnerAvatar = true,
  locale,
  padding = 0,
  onClick,
}: EntityListItemProps) => {
  switch (entityType) {
    case 'kpi':
      return (
        <KpiListItem
          kpi={entity}
          navigateTo={navigateTo}
          size={size}
          showEntitySymbol={showEntitySymbol}
          showOwnerAvatar={showOwnerAvatar}
          locale={locale}
          padding={padding}
          onClick={onClick}
        />
      );
    default:
      return assertUnreachable(entityType);
  }
};

EntityListItem.defaultProps = {
  size: 's',
  showEntitySymbol: true,
  showOwnerAvatar: true,
  padding: 0,
};

export { EntityListItem };
