/**
 * List of all user feature flags, see documentation in Confluence:
 * https://workpathhq.atlassian.net/wiki/x/IwDYsAE
 *
 * Please keep this list in sync with the documentation - and vice versa!
 */
import { ElementOf } from 'types/utilityTypes';

export const allUserFeatureFlags = [
  'announcements',
  'alignedGoalsDataGrid',
  'cache_avatars',
  'conversations',
  'disable_new_conversation_grid',
  'disable_child_issue_tracking',
  'drafting_tips',
  'kr_assistant',
  'me_v2',
  'modifyBuMembers',
  'talking_points',
  'power_bi',
  'kpi_alignments',
  'custom_terminology', // Toggle on/off custom terminology for a specific account
  'saml_integration',
] as const;

export type UserFeatureFlagName = ElementOf<typeof allUserFeatureFlags>;

export type UserFeatureFlags = UserFeatureFlagName[];
