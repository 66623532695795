import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { Icons, IconBox } from '../Icons';
import {
  getBaseUnit,
  getColor,
  getFontSize,
  getLineHeight,
} from '../styles/themeGetters';
import { IconComponent } from '../Icons/helpers';
import { Text } from '../Text';
import { ColorKeys } from '../styles/themes';

export type BackgroundColor = Extract<
  ColorKeys,
  | 'yellow-light'
  | 'blue-light'
  | 'green-light'
  | 'grey-light-100'
  | 'red-light'
  | 'orange-light'
  | 'white'
  | 'navy'
>;

type WrapperProps = {
  hasSuffix: boolean;
  hasPrefix: boolean;
  hasAvatar: boolean;
  selected: boolean;
  truncated: boolean;
  disabled?: boolean;
  backgroundColor?: BackgroundColor;
};

const selectedStyles = css`
  background-color: ${getColor('navy')};
  color: ${getColor('white')};
`;

const withSuffixStyles = css`
  padding-right: 3px;
`;

const withPrefixStyles = css`
  padding-left: 3px;
`;

const withIconStyles = css`
  padding-left: 3px;

  /* for avatar */
  > div:only-of-type {
    margin-right: 4px;
  }
`;

const Wrapper = styled.button<WrapperProps>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? getColor(backgroundColor) : getColor('grey-light-100')};
  font-size: ${getFontSize(14)};
  line-height: ${getLineHeight(14)};
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid transparent;
  padding: 3px 11px;
  height: 28px;
  ${({ truncated }) =>
    truncated &&
    `overflow: hidden;
  max-width: 100%;`}

  &:hover {
    border-color: ${getColor('grey')};
  }

  &:focus {
    border-color: ${getColor('blue-light')};
  }

  &:disabled {
    color: ${getColor('grey')};
  }

  ${({ onClick }) => onClick && 'cursor: pointer;'}
  ${({ hasSuffix }) => hasSuffix && withSuffixStyles}
  ${({ hasPrefix }) => hasPrefix && withPrefixStyles}
  ${({ hasAvatar }) => hasAvatar && withIconStyles}
  ${({ selected }) => selected && selectedStyles}
`;

type Props = {
  label: string | React.ReactNode;
  disabled?: boolean;
  suffixIcon?: IconComponent;
  prefixIcon?: IconComponent;
  avatar?: React.ReactNode;
  onAction?: (e) => void;
  onClick?: () => void;
  selected?: boolean;
  truncated?: boolean;
  ariaLabel?: string;
  backgroundColor?: BackgroundColor;
  color?: ColorKeys;
};

const InnerTag: React.ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  {
    label,
    disabled,
    suffixIcon: SuffixIcon,
    prefixIcon: PrefixIcon,
    avatar,
    onAction,
    onClick,
    selected = false,
    truncated = false,
    ariaLabel,
    backgroundColor,
    color = 'black',
  },
  ref
) => {
  return (
    <Wrapper
      aria-disabled={disabled}
      disabled={disabled}
      hasSuffix={!!SuffixIcon}
      hasPrefix={!!PrefixIcon}
      hasAvatar={!!avatar}
      onClick={onClick}
      selected={selected}
      type="button"
      truncated={truncated}
      ref={ref}
      aria-label={ariaLabel}
      backgroundColor={backgroundColor}
    >
      {PrefixIcon && (
        <IconBox mr={1}>
          <PrefixIcon
            size={getBaseUnit(6)}
            color={selected ? 'white' : color}
          />
        </IconBox>
      )}
      {avatar}
      <Text
        ml={avatar ? 2 : 0}
        truncated={truncated}
        minWidth={truncated ? undefined : 'max-content'}
        color={selected ? 'white' : color}
      >
        {label}
      </Text>
      {SuffixIcon && selected && (
        <IconBox ml={1}>
          <Icons.SolidChevronUp size={getBaseUnit(6)} color="white" />
        </IconBox>
      )}
      {SuffixIcon && !selected && (
        <IconBox ml={1} onClick={onAction}>
          <SuffixIcon
            size={getBaseUnit(6)}
            color={disabled ? 'grey' : 'black'}
          />
        </IconBox>
      )}
    </Wrapper>
  );
};

const Tag = forwardRef(InnerTag);

export { Tag };
