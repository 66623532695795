import React from 'react';
import styled from 'styled-components';

import { Box } from '../Box';
import { Button } from '../Button';
import { Link } from '../Link';
import { Icons } from '../Icons';
import { Text } from '../Text';
import { getBaseUnit } from '../styles/themeGetters';
import { ColorKeys } from '../styles/themes';

type LinkOptions = {
  url: string;
  title: string;
  ariaLabel?: string;
};

type ButtonOptions = {
  onClick: () => void;
  title: string;
  ariaLabel?: string;
};

type ActionOptions =
  | { link: LinkOptions; button?: never }
  | { link?: never; button: ButtonOptions };

type CalloutProps = {
  message: string;
  onClose?: () => void;
  icon: React.ReactElement;
  backgroundColor: ColorKeys;
  actionOptions?: ActionOptions;
};

const Wrapper = styled(Box)`
  border-radius: ${getBaseUnit(2)};
  padding: ${getBaseUnit(3)};
  display: flex;
  align-items: center;
  min-height: ${getBaseUnit(15)};
  width: 100%;
`;

const ActionsWrapper = styled(Box)`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
`;

const Callout = ({
  backgroundColor,
  message,
  onClose,
  icon,
  actionOptions,
}: CalloutProps) => (
  <Wrapper backgroundColor={backgroundColor}>
    {icon}
    <Text ml={2} mr={6}>
      {message}
    </Text>
    <ActionsWrapper>
      {actionOptions?.link && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          url={actionOptions.link.url}
          aria-label={actionOptions.link.ariaLabel}
        >
          {actionOptions.link.title}
        </Link>
      )}
      {actionOptions?.button && (
        <StyledButton
          onClick={actionOptions.button.onClick}
          variant="outlined"
          size="s"
          aria-label={actionOptions.button.ariaLabel}
        >
          {actionOptions.button.title}
        </StyledButton>
      )}
      {onClose && (
        <Button
          color="primary"
          size="s"
          onClick={onClose}
          startIcon={Icons.Close}
          variant="text"
          aria-label="close callout"
        />
      )}
    </ActionsWrapper>
  </Wrapper>
);

type VariantCalloutProps = {
  message: string;
  onClose?: () => void;
  actionOptions?: ActionOptions;
};

const AlertCallout = ({
  message,
  onClose,
  actionOptions,
}: VariantCalloutProps) => (
  <Callout
    message={message}
    onClose={onClose}
    icon={
      <Box height="24px" width="24px">
        <Icons.Danger color="orange-dark" />
      </Box>
    }
    backgroundColor="orange-light"
    actionOptions={actionOptions}
  />
);

const InfoCallout = ({
  message,
  onClose,
  actionOptions,
}: VariantCalloutProps) => (
  <Callout
    message={message}
    onClose={onClose}
    icon={
      <Box height="24px" width="24px">
        <Icons.InfoSolid size="24px" color="blue-dark" />
      </Box>
    }
    backgroundColor="blue-light"
    actionOptions={actionOptions}
  />
);

const TipCallout = ({
  message,
  onClose,
  actionOptions,
}: VariantCalloutProps) => (
  <Callout
    message={message}
    onClose={onClose}
    icon={
      <Box height="24px" width="24px">
        <Icons.Lightning size="24px" color="lilac-dark" />
      </Box>
    }
    backgroundColor="lilac-light"
    actionOptions={actionOptions}
  />
);

export { AlertCallout, InfoCallout, TipCallout };
