import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
  getRequestV2,
  postRequestV2,
} from '../../methods';

const getGoal = goalId => getRequest(`goals/${goalId}`);
const createGoal = payload => postRequest('goals', payload);
const updateGoal = (goalId, payload) =>
  patchRequest(`goals/${goalId}/`, payload);
const deleteGoal = goalId => deleteRequest(`goals/${goalId}/`);
const copyGoal = ({ goalId, cycleId }) =>
  postRequest(`goals/${goalId}/copy?cycle_id=${cycleId}`, {});

const getGoalOverviewCheckin = (startDate, endDate) =>
  getRequest(
    `goals/checkin_overview?start_date=${startDate}&end_date=${endDate}`
  );

const getGoalOverviewForMe = (startDate, endDate) =>
  getRequest(
    `goals/overview?for=me&start_date=${startDate}&end_date=${endDate}`
  );
const getGoalOverviewForMePersonal = (startDate, endDate) =>
  getRequest(
    `goals/overview?for=me_personal&start_date=${startDate}&end_date=${endDate}`
  );
const getGoalOverviewForOthersPersonal = (startDate, endDate) =>
  getRequest(
    `goals/overview?for=others_personal&start_date=${startDate}&end_date=${endDate}`
  );
// TODO: Rename or remove (if replaced with another endpoint for the Goal Page)
const getGoalOverviewForTeam = (teamId, startDate, endDate) =>
  getRequest(
    `goals/overview?for=team&team_id=${teamId}&start_date=${startDate}&end_date=${endDate}`
  );

const getGoalOverviewForBusinessUnit = (businessUnitId, startDate, endDate) =>
  getRequestV2(
    `bu/${businessUnitId}/goals?start_date=${startDate}&end_date=${endDate}`
  );

const getGoalsForTeam = (teamId, cycleId) =>
  getRequestV2(`teams/${teamId}/goals?cycle_id=${cycleId}`);
// TODO: Rename or remove (if replaced with another endpoint for the Goal Page)
const getGoalOverviewForOrganization = (startDate, endDate) =>
  getRequest(
    `goals/overview?for=organization&start_date=${startDate}&end_date=${endDate}`
  );
const getGoalOverviewForOrganizationNew = cycleId =>
  getRequest(`goals/overview/organization?cycle_id=${cycleId}`);
const getGoalOverviewForOrganizationV2 = cycleId =>
  getRequestV2(`goals/overview/organization?cycle_id=${cycleId}`);

const getGoalOverviewForCustomGoalFilter = customGoalFilterId =>
  getRequestV2(`custom_goal_filters/${customGoalFilterId}/overview`);

// BU goals
const getBusinessUnitGoals = (businessUnitId, cycleId) =>
  getRequestV2(`bu/${businessUnitId}/goals?cycle_id=${cycleId}`);

const createBusinessUnitGoal = (businessUnitId, payload) =>
  postRequestV2(`bu/${businessUnitId}/goals`, payload);

// Goal Relations

const getGoalAlignments = goalId => getRequestV2(`goals/${goalId}/alignments`);

const getGoalCollaborations = goalId =>
  getRequestV2(`goals/${goalId}/collaborations`);

const searchPotentialParents = ({
  goalId = '',
  query = '',
  startDate = '',
  endDate = '',
  teamId = '',
  kind = '',
  searchDirectParentTeamOnly = false,
  businessUnitId = '',
}) => {
  let request = `goals/${goalId}/parent_relations/goal_alignment_candidate?query=${encodeURIComponent(
    query
  )}&start_date=${startDate}&end_date=${endDate}&kind=${kind}`;

  if (teamId) {
    request = `${request}&team_ids[]=${teamId}`;
  }

  if (businessUnitId) {
    request = `${request}&business_unit_id=${businessUnitId}`;
  }

  if (searchDirectParentTeamOnly) {
    request = `${request}&parent_team_goals=true`;
  }

  return getRequest(request);
};

const searchPotentialChildren = (
  goalId,
  query,
  startDate,
  endDate,
  teamId,
  kind
) =>
  teamId
    ? getRequest(
        `goals/${goalId}/child_relations/goal_alignment_candidate?query=${encodeURIComponent(
          query
        )}&start_date=${startDate}&end_date=${endDate}&kind=${kind}&team_ids[]=${teamId}`
      )
    : getRequest(
        `goals/${goalId}/child_relations/goal_alignment_candidate?query=${encodeURIComponent(
          query
        )}&start_date=${startDate}&end_date=${endDate}&kind=${kind}`
      );

const addParentGoal = (goalId, payload) =>
  postRequest(`goals/${goalId}/parent_relations/`, payload);
const removeParentGoal = (goalId, parentId) =>
  deleteRequest(`goals/${goalId}/parent_relations/${parentId}`);

const addChildGoal = (goalId, payload) =>
  postRequest(`goals/${goalId}/child_relations/`, payload);
const removeChildGoal = (goalId, childId) =>
  deleteRequest(`goals/${goalId}/child_relations/${childId}`);

// Goal Status Updates
const createStatusUpdate = (goalId, payload) =>
  postRequest(`goals/${goalId}/status_updates`, payload);

// Goal Collaborators
const addUserCollaboration = (goalId, payload) =>
  postRequest(`goals/${goalId}/user_collaborations`, payload);
const addTeamCollaboration = (goalId, payload) =>
  postRequest(`goals/${goalId}/team_collaborations`, payload);
const updateUserCollaboration = (goalId, userId, payload) =>
  patchRequest(`goals/${goalId})/user_collaborations/${userId}`, payload);
const updateTeamCollaboration = (goalId, teamId, payload) =>
  patchRequest(`goals/${goalId})/team_collaborations/${teamId}`, payload);
const deleteUserCollaboration = (goalId, userId) =>
  deleteRequest(`goals/${goalId}/user_collaborations/${userId}`);
const deleteTeamCollaboration = (goalId, teamId) =>
  deleteRequest(`goals/${goalId}/team_collaborations/${teamId}`);

// Goal Feed Entries
const getFeedEntries = goalId => getRequest(`goals/${goalId}/feed_entries`);
const createFeedEntry = (goalId, payload) =>
  postRequest(`goals/${goalId}/feed_entries`, payload);
const createFeedEntryComment = (goalId, feedEntryId, payload) =>
  postRequest(`goals/${goalId}/feed_entries/${feedEntryId}/comments`, payload);

const getGoalGraph = ({ startDate, endDate, teamId, businessUnitId }) => {
  // currently disabled, API doesn't support filtering by "businessUnitId"
  if (businessUnitId) {
    return getRequest(
      `goals/graph/?start_date=${startDate}&end_date=${endDate}&business_unit=${businessUnitId}`
    );
  }

  if (teamId) {
    return getRequest(
      `goals/graph/?start_date=${startDate}&end_date=${endDate}&team_id=${teamId}`
    );
  }

  return getRequest(`goals/graph/?start_date=${startDate}&end_date=${endDate}`);
};

// Drafting Module

const saveOrganizationDraftGoals = payload =>
  postRequestV2('/drafting_module/goals/batch_update', payload);

const generateGoalSuggestion = payload =>
  postRequestV2('goal_suggestions', payload);

const goals = {
  getGoal,
  createGoal,
  updateGoal,
  deleteGoal,
  copyGoal,

  getGoalOverviewCheckin,
  getGoalOverviewForMe,
  getGoalOverviewForMePersonal,
  getGoalOverviewForOthersPersonal,
  getGoalOverviewForTeam,
  getGoalOverviewForBusinessUnit,
  getGoalsForTeam,
  getGoalOverviewForOrganization,
  getGoalOverviewForOrganizationNew,
  getGoalOverviewForOrganizationV2,
  getGoalOverviewForCustomGoalFilter,

  getGoalAlignments,
  getGoalCollaborations,
  searchPotentialParents,
  searchPotentialChildren,

  addParentGoal,
  removeParentGoal,

  addChildGoal,
  removeChildGoal,

  createStatusUpdate,

  addUserCollaboration,
  addTeamCollaboration,
  updateUserCollaboration,
  updateTeamCollaboration,
  deleteUserCollaboration,
  deleteTeamCollaboration,

  getFeedEntries,
  createFeedEntry,
  createFeedEntryComment,

  getGoalGraph,

  saveOrganizationDraftGoals,
  getBusinessUnitGoals,
  createBusinessUnitGoal,

  generateGoalSuggestion,
};

export default goals;
