import { postRequestV2 } from 'utils/api/methods';

import { EntityLink } from './types';

const linkEntity = (payload: { entityLinks: EntityLink[] }) =>
  postRequestV2(`entity_links/bulk_upsert`, payload);

const entityLinks = {
  linkEntity,
};

export default entityLinks;
