import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Box } from '../Box';
import { IconBox, Icons } from '../Icons';
import { Tooltip } from '../Tooltip';
import { getBaseUnit, getColor } from '../styles/themeGetters';

type EntityType =
  | 'kpi'
  | 'goal'
  | 'kr'
  | 'initiative'
  | 'epic'
  | 'aligned'
  | 'businessUnit'
  | 'organization'
  | 'strategicElement';

type VariantProp = {
  icon: ReactNode;
  tooltipContent?: string;
};

type VariantProps = {
  kpi: VariantProp;
  goal: VariantProp;
  kr: VariantProp;
  initiative: VariantProp;
  epic: VariantProp;
  aligned: VariantProp;
  businessUnit: VariantProp;
  organization: VariantProp;
  strategicElement: VariantProp;
};

const SIZES = {
  s: '20px',
  m: '28px',
  l: '40px',
};

const ICON_SIZES = {
  s: '16px',
  m: '24px',
  l: '28px',
};

const BACKGROUND_COLOR = {
  kpi: getColor('blue-light'),
  goal: getColor('yellow-light'),
  kr: getColor('green-light'),
  initiative: getColor('lilac-light'),
  epic: getColor('lilac-light'),
  aligned: getColor('yellow-light'),
  businessUnit: getColor('blue-light'),
  organization: getColor('yellow-light'),
  strategicElement: getColor('pink-light'),
};

const EntityWrapper = styled(Box)<EntitySymbolProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${getBaseUnit(1)};
  height: ${({ size }) => size && SIZES[size]};
  width: ${({ size }) => size && SIZES[size]};
  background-color: ${({ variant, draft }) =>
    draft ? getColor('grey-light-100') : BACKGROUND_COLOR[variant]};
`;

type EntitySymbolProps = {
  tooltipContent?: string;
  variant: EntityType;
  size?: 's' | 'm' | 'l';
  draft?: boolean;
};

/**
 * Entity symbols are a special type of icons used exclusively to highlight and identify rows of items in the datagrid.
 *
 * It is optimally intended to be used to differentiate between datagrid rows, but can be used to generally represent the type of item entity being used in the interface.
 */

const EntitySymbol = ({
  tooltipContent,
  variant,
  size = 'm',
  draft,
}: EntitySymbolProps) => {
  const iconColor = draft ? 'grey-dark' : 'navy';

  const VARIANTS: VariantProps = {
    kpi: {
      icon: <Icons.Value color={iconColor} size={ICON_SIZES[size]} />,
      tooltipContent,
    },
    goal: {
      icon: <Icons.Goal color={iconColor} size={ICON_SIZES[size]} />,
      tooltipContent,
    },
    kr: {
      icon: <Icons.KeyResult color={iconColor} size={ICON_SIZES[size]} />,
      tooltipContent,
    },
    initiative: {
      icon: <Icons.Initiative color={iconColor} size={ICON_SIZES[size]} />,
      tooltipContent,
    },
    epic: {
      icon: <Icons.InitiativeJira color={iconColor} size={ICON_SIZES[size]} />,
      tooltipContent,
    },
    aligned: {
      icon: <Icons.AlignedGoal color={iconColor} size={ICON_SIZES[size]} />,
      tooltipContent,
    },
    businessUnit: {
      icon: <Icons.BusinessUnit color={iconColor} size={ICON_SIZES[size]} />,
      tooltipContent,
    },
    organization: {
      icon: <Icons.Organization color={iconColor} size={ICON_SIZES[size]} />,
      tooltipContent,
    },
    strategicElement: {
      icon: (
        <Icons.StrategicElement color={iconColor} size={ICON_SIZES[size]} />
      ),
      tooltipContent,
    },
  };

  const variantTooltipContent = VARIANTS[variant].tooltipContent;

  if (variantTooltipContent) {
    return (
      <Tooltip tooltipContent={variantTooltipContent}>
        <EntityWrapper size={size} variant={variant} draft={draft}>
          <IconBox>{VARIANTS[variant].icon}</IconBox>
        </EntityWrapper>
      </Tooltip>
    );
  }

  return (
    <EntityWrapper size={size} variant={variant} draft={draft}>
      <IconBox>{VARIANTS[variant].icon}</IconBox>
    </EntityWrapper>
  );
};

export { EntitySymbol };
