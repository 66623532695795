import React from 'react';
import styled from 'styled-components';

import { Avatar } from '../../../Avatar';
import { Icons } from '../../../Icons';
import { Tag } from '../../../Tag';
import { Item } from '../types';
import { Truncate } from '../../../Truncate';

const Wrapper = styled.span`
  padding: 3px 8px 3px 4px;
`;

type SelectedItemRendererProps = {
  removeSelectedItem: (item: Item) => void;
  item: Item;
};

const SelectedItemRenderer = ({
  item,
  removeSelectedItem,
}: SelectedItemRendererProps) => {
  switch (item.entityType) {
    case 'team':
      return (
        <Tag
          label={item.title}
          avatar={
            <Avatar
              fallbackText={item.avatar?.fallbackText ?? ''}
              type={item.entityType}
              size="xs"
            />
          }
          onAction={e => {
            e.stopPropagation();
            removeSelectedItem(item);
          }}
          suffixIcon={Icons.Close}
        />
      );

    case 'person':
      return (
        <Tag
          label={item.title}
          avatar={
            <Avatar
              image={item.avatar?.url}
              fallbackText={item.avatar?.fallbackText ?? ''}
              type={item.entityType}
              size="xs"
            />
          }
          onAction={e => {
            e.stopPropagation();
            removeSelectedItem(item);
          }}
          suffixIcon={Icons.Close}
        />
      );

    case 'goal':
      return (
        <Tag
          label={<Truncate maxWidth="150px" text={item.title} />}
          avatar={
            <Avatar
              image={item.owner?.url}
              fallbackText={item.owner?.fallbackText ?? ''}
              type="person"
              size="xs"
            />
          }
          onAction={e => {
            e.stopPropagation();
            removeSelectedItem(item);
          }}
          suffixIcon={Icons.Close}
        />
      );
    case 'label':
      return (
        <Tag
          label={<Truncate maxWidth="150px" text={item.title} />}
          onAction={e => {
            e.stopPropagation();
            removeSelectedItem(item);
          }}
          suffixIcon={Icons.Close}
        />
      );

    case 'lazyItem':
    case 'separator':
      return <></>;

    default: {
      // this is to make a missing Renderer a type error. see
      // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#exhaustiveness-checking
      // for how this works.
      const _exhaustiveCheck: never = item;
      return _exhaustiveCheck;
    }
  }
};

type SelectedItemProps = {
  selectedItem: Item;
  idx;
  removeSelectedItem: (item: Item) => void;
  getSelectedItemProps;
};

const SelectedItem = ({
  getSelectedItemProps,
  selectedItem,
  idx,
  removeSelectedItem,
}: SelectedItemProps) => {
  return (
    <Wrapper {...getSelectedItemProps({ selectedItem, index: idx })}>
      <SelectedItemRenderer
        item={selectedItem}
        removeSelectedItem={removeSelectedItem}
      />
    </Wrapper>
  );
};

export { SelectedItem };
